import React, {Component} from 'react';
import { Link } from 'react-router-dom'

// import Custom Componenets
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

// koneksi
import {Post, Get, Delete} from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');


const frmDef = {
    uuid: '',
    nama: '',
    tahun: new Date().getFullYear(),
    periode: '',
    detail_periode: [{isi:[{bantuan_nominal:'', bantuan_barang:''}]}],
}
class BasicTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pagination: [],
            status: {
                form:false,
                formDetail:false,
                btnForm:false,
                btnAksi:false,
                select:false
            },
            awal: "",
            sebelum: "",
            hal: [],
            setelah: "",
            akhir: "",
            dt:frmDef,
            judul: "Tambah Data Bantuan Sosial",
            judulDetail: "Tambah Data Detail Bantuan Sosial",
            // =========== Select 2 ============
            listKategori: [],
            statKategori: null,
            // =================================
            // =========== Alert ============
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            pesanAlert:'',
            successAlert: false,
            // ==============================

            // Pencarian
            dataCari: {
				q: ''
			},

            // PageDetailData
            dataBantuan: {},

            // Loading
            loading: false,
        };
    }
    
    // ================== Sweet Alert ==================
    closeAlert = () => {
        this.setState({
            show: false,
            successAlert: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    // =================================================

    // ================== Ambil data dari db untuk table ==================
    fetch = (params = {}) => 
    {    
        let link = "";
        if (params.page)
        {
            switch(params.page)
            {
                case '>>': link = "?page="+(Math.ceil(this.state.pagination.total / this.state.pagination.per_page));break;
                case '>': link = "?page="+(parseInt(this.state.pagination.current_page) + 1);break;
                case '<': link = "?page="+(parseInt(this.state.pagination.current_page) - 1);break;
                case '<<': link = "?page=1";break;
                default: link = "?page="+params.page;break;
            }
        }

        let setLink = '';
        if(link){
            setLink = `${link}&q=${this.state.dataCari.q}`
        }
        else{
            setLink = `?q=${this.state.dataCari.q}`
        }

        Get('bansos'+setLink, null, (dtkat) => {
            console.log('Data Result')
            console.log(dtkat)
            this.setState({data:dtkat.results.data, pagination:dtkat.results});
            this.pagination(dtkat.results);
        });
    }

    // Pagination
    pagination = (data) => {
        let awal = "";
        let hal = [];
        let sebelum = "";
        let setelah = "";
        let akhir = "";
        if (data.total > 0)
        {
            let start = 1;
            let end = 5;
            let n = 0;
            let p = 0;
            if (data.current_page <= 3)
            {   
                start = 1;
                end = 5;
                if (data.last_page > data.current_page)
                {
                    n = data.current_page + 1;
                }
            }
            else 
            {
                p = data.current_page - 1;
                n = data.current_page + 1;
                start = data.current_page - 2;
                end = data.current_page + 2;
            }
            if (end >= data.last_page - 2)
            {
                p = data.current_page - 1;
                if (start >= 5)
                {
                    start = data.last_page - 4;
                }
                end = data.last_page;
            }

            for (let i=start;i<=end;i++) 
            {
                let warna = "primary-custom";
                if (i === data.current_page)
                {
                    warna = "danger"
                }
                hal.push(<a href={() => false} onClick={()=>this.fetch({page:i})} className={"btn btn-"+warna+" btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
            }
            if (p > 0)
            {
                sebelum = (<a href={() => false} onClick={()=>this.fetch({page:p})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
            }
            if (n > 0)
            {
                setelah = (<a href={() => false} onClick={()=>this.fetch({page:n})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
            }
            awal = (<a href={() => false} onClick={()=>this.fetch({page:1})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
            akhir = (<a href={() => false} onClick={()=>this.fetch({page:data.last_page})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
        }
        this.setState({awal,sebelum,hal,setelah,akhir});
    }

    // Cari Data Table
	cariData = (e) => {
		if (e.key === 'Enter') {
			// console.log("Search")
			// console.log(e.target.value)
			let dataCari = {
				q: e.target.value
			};
			this.setState({ dataCari });
			setTimeout(
				function() {
					// mywindow.close();
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

    // ========================================================================

    // ================== simpan data ==================
    simpan = e => {
        e.preventDefault();
        let cek_validasi = false
        if(
            this.state.statKategori &&
            document.getElementById("tahun").value !== ''
        ){
            if(!this.state.dt.uuid && document.getElementById("periode").value !== ''){
                cek_validasi = true
            }
            else if(this.state.dt.uuid){
                cek_validasi = true
            }
        }
        if (cek_validasi)
        {
            this.setState({
                status:{
                    ...this.state.status,
                    btnForm: true,
                    loading: true,
                },
            })
            this.forceUpdate();
            let addData = {
                uuid : document.getElementById("uuid").value,
                tahun : document.getElementById("tahun").value,
                uuid_kategori : this.state.statKategori.value,
            };
            
            let psn = '';
            let resstat = 204;
            let metode = "create";
            if (addData.uuid === '')
            {
                psn = "Tambah"; 
                resstat = 201;
                addData.uuid = null;
                addData.periode = document.getElementById("periode").value;
                
            } 
            else 
            {
                psn = "Ubah"; 
                resstat = 200;
                metode = "update";
            }
            
            Post('bansos/'+ metode,addData.uuid, addData, (res) => {
                console.log('Response Simpan')
                console.log(res)
                this.setState({
                    status:{
                        ...this.state.status,
                        btnForm: false,
                        form: false,
                    },
                })
                this.forceUpdate();
                if (res.status === resstat)
                {
                    this.setState({ 
                        show: true, 
                        basicType:'success', 
                        basicTitle:'Data Bantuan Sosial', 
                        pesanAlert:'Berhasil '+psn+' Data',
                        successAlert: psn === 'Tambah'? true : false,
                        statKategori: null,
                        loading: false,
                        dataBantuan: res.data.results,
                    });
                }
                else if (res.status === 409)
                {
                    this.setState({ show: true, basicType:'danger', basicTitle:'Gagal '+psn+' Data Bantuan Sosial', pesanAlert:'Data Telah Ada' });
                }
                else
                {
                    this.setState({ show: true, basicType:'danger', basicTitle:'Data Bantuan Sosial', pesanAlert:'Gagal '+psn+' Data' });
                }

                if (psn === "Tambah"){
                    this.componentDidMount();
                }
                else if(psn === "Ubah"){
                    this.fetch({page: this.state.pagination.current_page});
                }

            });
        }
        else
        {
            this.setState({ show: true, basicType:'danger', basicTitle:'Data Bantuan Sosial', pesanAlert:"Data tidak lengkap!" });
        }
    }
    // ========================================================================
    
    // ============================== Ubah Data ===============================
    
    ubahData = (data) => {
        console.log('Data Detail')
        console.log(data)
        this.setState({
            status:{
                ...this.state.status,
                btnAksi: true,
            },
            dt:{
                uuid: data.uuid,
                tahun: data.tahun,
            },
            statKategori:{value: data.uuid_kategori, label: data.data_kategori.nama},
            judul: "Ubah Data Bantuan Sosial",
        })
        this.bukaForm();
        this.forceUpdate();
    }
    // ========================================================================
    // ============================= Hapus Data ===============================
    konfirmHapus = (id) => {
        this.setState({
            alert: (
                <SweetAlert
                    showCancel
                    confirmBtnText="Hapus"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    type="danger"
                    title="Yakin ingin hapus data ?"
                    onCancel={this.hideAlert}
                    onConfirm={()=> this.hapusData(id)}
                >
                    Proses ini tidak dapat dibatalkan!
                </SweetAlert>
            )
        });
    }

    hapusData = (id) => {
        this.hideAlert();
        let psn = "Hapus";
        Delete('bansos/delete', id, (res) => {
            if (res === 200)
            {
                this.setState({ show: true, basicType:'success', basicTitle:'Data Bantuan Sosial', pesanAlert:'Berhasil '+psn+' Data' });
            }
            else
            {
                this.setState({ show: true, basicType:'danger', basicTitle:'Data Bantuan Sosial', pesanAlert:'Gagal '+psn+' Data' });
            }
            this.fetch({page: this.state.pagination.current_page});
        })
    }
    // ========================================================================

    bukaForm = () => {
        this.setState({
            status:{
                ...this.state.status,
                form: true,
            },
        })
        this.forceUpdate();
    }

    bukaFormDetail = () => {
        this.setState({
            status:{
                ...this.state.status,
                formDetail: true,
            },
        })
        this.forceUpdate();
    }

    tutupForm = () => {
        this.setState({
            status:{
                ...this.state.status,
                form: false,
            },
            statKategori: null,
        })
        this.forceUpdate();
    }

    tutupFormDetail = () => {
        this.setState({
            status:{
                ...this.state.status,
                formDetail: false,
            },
        })
        this.forceUpdate();
    }

    tombolAksi = (cell, row) => {
        return (
            <>
                <Link to={{ pathname: "/admin/bansos/detail-bansos", state: row }}>
                    <Button  size="xs" color="info" className="btn-icon" title='Detail Data'><i className="fa fa-eye"></i></Button> &nbsp;&nbsp;
                </Link>
                <Button  size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row)} title='Edit Data' disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
                <Button  size="xs" title='Hapus Data' color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>  
            </>
        )
    }

    changeSelectValue = (e, sel) =>
    {
        if (e){
            switch(sel)
            {
                case 'kategori': 
                    this.setState({
                        statKategori: e,
                    }); 
                    break;
                default:
                    // no default
                    break;
            }
        }
        else 
        {
            switch(sel)
            {
                case 'kategori': 
                    this.setState({
                        statKategori: null,
                    }); 
                    break;
                default:
                    // no default
                    break;
            }
        }
        
        this.setState({
            status:{
                ...this.state.status,
                select: false,
            },
        })
        this.forceUpdate();
    }

    componentDidMount() {
        this.fetch({page:null,where:[]});
        Get('bansos/kategori/list', null, (data) => {
            console.log('list kategori')
            console.log(data)
            let listKategori = [];
            data.results.forEach((dt) => {
                listKategori.push({value:dt.uuid, label:dt.nama});
            });
            this.setState({listKategori});
        })
    }

    render() {
        // var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
        // var size = this.state.pagination.total;
        // var from = this.state.pagination.from;
        // var page = this.state.pagination.current;
        // var to = this.state.pagination.to;
        // var total = this.state.pagination.total;
        var data = this.state.data;
        const frmDef = {
            id: '',
            nama: '',
            tahun: new Date().getFullYear(),
            periode: '',
            // detail_periode: [{isi:[]}],
            detail_periode: [{isi:[{bantuan_nominal:'', bantuan_barang:''}]}],
        }   
        // const { SearchBar } = Search;
        const columns = [
            {
                dataField: "no",
                text: "No",
                isDummyField: true,
                csvExport: false,
                formatter: (key, obj, index) =>{          
                    let current_pagenum = this.state.pagination.current_page;
                    let total_records_per_page = this.state.pagination.per_page;
                    let row_index = (index+1);
                    let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
                    return serial_num; 
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '15px', textAlign: 'left' };
                }
            },
            {
                dataField: 'tahun',
                text: 'Tahun', 
                sort: true
            },
            {
                dataField: 'data_kategori.nama',
                text: 'Kategori Bantuan', 
                sort: true
            },
            {
                dataField: 'aksi',
                text: 'Aksi',
                isDummyField: true,
                csvExport: false,
                formatter: this.tombolAksi,
            },
        ];


        return (
            <div>
                {/* <Breadcrumb title="Data Bantuan Sosial" parent="Admin"/> */}
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    confirmBtnBsStyle="success"
                    onConfirm={this.closeAlert}
                    showConfirm={this.state.successAlert ? false : true}
                >
                    {this.state.pesanAlert}
                    {this.state.successAlert && this.state.dataBantuan? (
                        <div>
                        <br />
                        {/* <Button
                            color="success"
                            onClick={() => {
                                this.setState({
                                    judul:"Tambah Data Bantuan Sosial", 
                                    dt:frmDef, 
                                });
                                this.closeAlert();
                                this.bukaForm();
                            }}
                        >
                            Tambah Data
                        </Button>{" "} */}
                        <Button
                            // color="info"
                            className="btn btn-success"
                            onClick={() => {
                                this.closeAlert();
                                this.setState({
                                    dataBantuan:{}
                                })
                            }}
                        >
                            <Link
                                // data-testid="btn-eksekusi_surat"
                                // onClick={() => {
                                    // For testing
                                    // if (this.props.clickEksekusiSurat) {
                                    //     this.props.clickEksekusiSurat()
                                    // }
                                // }}
                                to={{
                                    pathname: process.env.PUBLIC_URL + "/admin/bansos/detail-bansos",
                                    state: this.state.dataBantuan
                                }}
                            >
                            <span style={{ color: "white" }}>OK</span>
                            </Link>
                        </Button>
                        </div>
                    ) : (
                        ""
                    )}
                </SweetAlert>

                {this.state.alert}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0">
                                <div className="card-body datatable-react">
                                    <div className="row">
                                        <div className="col-2">
                                           &nbsp;
                                        </div>
                                        <div className="col-8">
                                            <div className='row'>
                                                <div className='col-9'>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="cariTable"
                                                        placeholder="Cari Data berdasarkan kategori atau tahun"
                                                        onKeyPress={this.cariData}
                                                    />
                                                </div>
                                                <div className='col-3' style={{textAlign:'right'}}>
                                                    <Button  size="sm" color="success" className="btn-square" onClick={()=> {
                                                        this.setState({
                                                            judul:"Tambah Data Bantuan Sosial", 
                                                            dt:frmDef, 
                                                        });
                                                        this.bukaForm();}}>
                                                        Tambah Data
                                                    </Button>     
                                                </div>
                                                <div className='col-12'>
                                                    &nbsp;
                                                </div>
                                                <div className='col-12'>
                                                    <BootstrapTable
                                                        keyField="id"
                                                        data={ data }
                                                        columns={ columns }
                                                    />
                                                    <div className="pull-right text-white">
                                                        {this.state.awal}{this.state.sebelum}{this.state.hal.map(dt=> { return dt})}{this.state.setelah}{this.state.akhir}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2"> 
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <Modal open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false} styles={{ modal: { width: '60%' } }}>
                    <Form className="theme-form" onSubmit={this.simpan}>
                        <div className="modal-header">
                            <h5 className="modal-title">{this.state.judul}</h5>
                        </div>
                        <div className="modal-body">
                            <input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.dt.uuid}/>
                            <div className="col-lg-12 pl-3">
                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tahun</Label>
                                    <Col sm='9'>
                                        <input className="form-control" id="tahun" type="number" placeholder="Tahun" defaultValue={this.state.dt.tahun}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kategori Bantuan Sosial</Label>
                                    <Col sm='9'>
                                        <Select
                                            isDisabled={this.state.status.select}
                                            classNamePrefix="select"
                                            onChange={((e) => { this.changeSelectValue(e, 'kategori') })}
                                            defaultValue={this.state.statKategori}
                                            value={this.state.statKategori}
                                            name="kategori"
                                            options={this.state.listKategori}
                                            placeholder="Pilih Kategori Bantuan"
                                            isClearable
                                        />
                                    </Col>
                                </FormGroup>
                                {this.state.dt.uuid?
                                    <>
                                    </>
                                    :
                                    <>
                                        <FormGroup className="row">
                                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Periode</Label>
                                            <Col sm='9'>
                                                <input className="form-control" id="periode" type="number" placeholder="Periode" defaultValue={this.state.dt.periode}/>
                                            </Col>
                                        </FormGroup>
                                    </>
                                }
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
                            <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
                        </div>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default BasicTable;
