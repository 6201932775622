import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
// import BootstrapTable from "react-bootstrap-table-next";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  // FormGroup,
  // Label,
  // Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import HighchartsReact from "highcharts-react-official";
import drilldown from 'highcharts/modules/drilldown'

// import Select from "react-select";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get } from "../../function/Koneksi";
import { DataToken } from "../../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const frmDef = {
  uuid: "",
  judul: "",
  konten: "",
  jenis: "",
  link_gambar: "",
  id_pengguna: ""
};

class StatistikBansos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      konten: false,
      content: "",
      token: DataToken(sessionStorage.getItem("access_token")),
      foto_desa: "",
      link_gambar: "",
      formDisposisi: false,
      stpStat: {label : '', value : ''},
      dataBansos: [],
      drilldownDataBansos: [],
      kategoriBansos: [],
      selectedKategori: null,
    };
  }


  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    // let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    // if (params.page) {
    //   switch (params.page) {
    //     case ">>":
    //       link =
    //         "?page=" +
    //         Math.ceil(
    //           this.state.pagination.total / this.state.pagination.per_page
    //         );
    //       break;
    //     case ">":
    //       link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
    //       break;
    //     case "<":
    //       link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
    //       break;
    //     case "<<":
    //       link = "?page=1";
    //       break;
    //     default:
    //       link = "?page=" + params.page;
    //       break;
    //   }
    // }
    // Get("info-desa" + link, null, dtkat => {
    //   this.setState({ data: dtkat.results.data, pagination: dtkat.results });
    //   this.pagination(dtkat.results);
    // });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let addInfo = {
        nama: e.target.value
      };
      Post("info-desa", null, addInfo, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results
        });
      });
    }
  };

  // ========================================================================

  bukaForm = () => {
    this.setState({
      status:{
        form: true,
      }
    })
    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      formDisposisi: false,
      status:{
        form: false,
      }
    })
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>
        &nbsp;&nbsp;
        {/* //list */}
        {row.jenis === "info" ? (
          <Button
            size="xs"
            color="info"
            className="btn-icon btn-secondary-custom"
            onClick={e => this.detailBerita(row)}
          >
            <i className="fa fa-list"></i>
          </Button>
        ) : (
          <></>
        )}
      </>
    );
  };
  
  componentDidMount() {
    this.fetch({ page: null, where: [] });

    // Get List Kategori Bansos
    // Get("bansos/kategori", null, data => {
    //   if (data.results.data) {
    //     let kategoriBansos = []
    //     data.results.data.forEach((x) => {
    //       kategoriBansos.push({
    //         value: x.uuid,
    //         label: x.nama,
    //       })
    //     })
    //     this.setState({ kategoriBansos })
    //   }
    // })

    Get("bansos/statistik", null, data => {
      // console.log(data);
      if (data.results) {
        let dataStatistik = data.results
        let dataBansos = []
        let drilldownDataBansos = []

        dataStatistik.forEach((x, i) => {
          dataBansos.push({
            name: x.nama,
            y: parseInt(x.jumlah),
            drilldown: `${x.nama}-${i}`,
          })

          // For level 2 data
          let drilldown2 = []
          x.child.forEach((y, j) => {
            let level2Id = `${x.nama}-${i}-${j}`

            drilldown2.push({
              name: `Tahun ${y.tahun}`,
              y: parseInt(y.jumlah),
              drilldown: level2Id,
            })

            // For level 3 data
            let drilldown3 = []
            y.child.forEach((z, k) => {
              // drilldown3.push([ z.periode, parseInt(z.jumlah) ])
              drilldown3.push({
                name: `Periode ${z.periode}`,
                y: parseInt(z.jumlah),
                drilldown: '',
              })
            })
            drilldownDataBansos.push({
              id: level2Id,
              name: `Jumlah `,
              data: drilldown3,
            })
            // End For level 3 data
          })

          drilldownDataBansos.push({
            id: `${x.nama}-${i}`,
            name: `Jumlah `,
            data: drilldown2,
          })
          // End For level 2 data
        } )

        // console.log(dataBansos);
        // console.log(drilldownDataBansos);
  
        this.setState({ dataBansos, drilldownDataBansos });
        
      }
    });
  }
  
  highchartPieFunction = (data_chart, drilldown_chart, title_chart) => {
		const options = {
			chart: {
				type: 'pie',
				height: 280
			},
			credits: {
				enabled: false
			},
			accessibility: {
				announceNewData: {
					enabled: true
				},
				point: {
					valueSuffix: '%'
				}
			},
			tooltip: {
				pointFormat: 'Total: <b>{point.y} ({point.percentage:.1f}%)</b>'
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						alignTo: 'plotEdges',
						connectorPadding: 0,
						connectorShape: 'crookedLine',
						distance: 20,
						overflow: 'allow',
						style: {
							fontSize: '9px'
						}
					}
					// showInLegend: true
				}
			},
			title: {
				text: title_chart,
				margin: 3,
				style: {
					fontSize: '13px',
					fontWeight: 'bold'
				}
			},
			legend: {
				enabled: true
			},
			series: [
				{
					name: title_chart,
					colorByPoint: true,
					data: data_chart
				}
			],
			drilldown: {
				series: drilldown_chart
			}
		};
		return options;
	};

  highchartStatisticFunction = (data_chart, drilldown_chart, title_chart) => {
		const options = {
			chart: {
				type: 'column'
				// scrollablePlotArea: {
				// minWidth: 900,
				// 	scrollPositionX: 1
				// }
			},
			credits: {
				enabled: false
			},
			title: {
				text: title_chart
			},
			xAxis: {
				type: 'category',
				// title: {
				// 	text: null
				// },
				min: 0,
				// max: 6
				// scrollbar: {
				// 	enabled: true
				// },
				// tickLength: 0
			},
			legend: {
				enabled: false
			},
			series: [
				{
					name: 'Jumlah ',
					colorByPoint: true,
					data: data_chart
				}
			],
			drilldown: {
				series: drilldown_chart
			},
			scrollbar: {
				enabled: true
			}
		};
		return options;
	};

  render() {
    const Highcharts = require('highcharts/highstock')
    drilldown(Highcharts)

    return (
      <div>
        {/* <Breadcrumb title="Data Info Desa" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                {/* <div className="card-header">
                  <div className="row">
                    <div className="col-sm-3">
                      <Select
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.setState({ selectedKategori: e })
                        }}
                        defaultValue={this.state.selectedKategori}
                        value={this.state.selectedKategori}
                        name="kategori"
                        options={this.state.kategoriBansos}
                        placeholder="Kategori"
                        isClearable
                        required
                      />
                    </div>
                  </div>
                </div> */}
                <div className="card-body datatable-react">
                  {this.state.dataBansos.length > 0 ? (
                    <div className="row" style={{ justifyContent: 'center' }}>
                      <div className="col-md-10">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={this.highchartStatisticFunction(
                            this.state.dataBansos,
                            this.state.drilldownDataBansos,
                            'Kategori Bansos'
                          )}
                        />
                      </div>
                    </div>
                  ) : ''}
                  {/* <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Detail Info</h5>
            </div>
            <div className="modal-body ">
              <img
                src={this.state.link_gambar}
                alt=""
                className="center"
                style={{
                  minHeight: "50px",
                  minWidth: "50px"
                }}
              />
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default StatistikBansos;
