import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datepicker from "react-datepicker";
import { Link, Redirect } from "react-router-dom";

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, Nama_DESA, NAMA_KABUPATEN, KECAMATAN, ALAMAT_KANTOR_DESA, PROVINSI, JENIS_DUSUN } from "../function/Koneksi";
import { Tanggal, } from "../function/Format";
import { logo } from "./asset/base64img";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ModalSuratDomisili from "./modal/domisili";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  id_jenis: "",
  nomor: "",
  tanggal_surat: new Date(),

  nik: "",
  kk: "",
  nama: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  jenis_kelamin: "",
  pekerjaan: "",
  pendidikan_terakhir: "",
  kewarganegaraan: "",
  agama: "",
  alamat: "",
  status_perkawinan: "",
  tujuan_pembuatan: "",

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",
  status_ttd2: "",
  jabatan_ttd2: "",
  jabatan_pengganti_ttd2: "",
  nama_ttd2: "",
  nip_ttd2: ""
};

const frmDex = {
  uuid: '',
  id_jenis: '',
  nik_pelapor: '',
  nik: '',
  kk: '',
  nama_pemohon: '',
  tempat_lahir: '',
  tanggal_lahir: '',
  jenis_kelamin: '',
  pekerjaan: '',
  pendidikan_terakhir: '',
  kewarganegaraan: '',
  agama: '',
  alamat: '',
  status_perkawinan: '',
  tujuan_pembuatan: '',
  detail: []
}

let detail = [];
let aw = {
  nik: "",
  nama: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  jenis_kelamin: "",
  pekerjaan: ""
};
class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: [],
        dataPenduduk: "none",
        dataPenduduk2: "none"
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDex,
      loading: false,
      loadingPreview: false,
      judul: "Tambah Surat Domisili",
      shdk: "",
      detail: [],
      shdk_aw: null,
      // ============ Select 2 ==============
      frmJam: [],
      jamStat: [],
      frmPkr: [],
      pkrStat: [],
      frmAgm: [],
      agmStat: [],
      frmShdk: null,
      frmNik: [],
      nikStat: null,
      frmNik2: [],
      nik2Stat: null,
      frmNik3: [],
      nik3Stat: null,
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,
      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      tanggal_lahir: new Date(),
      waktu_meninggal: new Date(),
      waktu_meninggal2: new Date(),
      // ===================================
      // =============== acc ===============
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      //dispopsisi

      datDisposisi: [],
      riwayatDisposisi: []
    };
  }
  // ================== Terbilang ===================

  terbilang = a => {
    // pastikan kita hanya berususan dengan tipe data numeric
    let angka = parseFloat(a);
    let hasil_bagi = 0;
    let hasil_mod = 0;
    // array bilangan
    // sepuluh dan sebelas merupakan special karena awalan 'se'
    const bilangan = [
      "",
      "satu",
      "dua",
      "tiga",
      "empat",
      "lima",
      "enam",
      "tujuh",
      "delapan",
      "sembilan",
      "sepuluh",
      "sebelas"
    ];
    if (angka === 1) {
      return "setahun";
    }
    // pencocokan dimulai dari satuan angka terkecil
    else if (angka < 12) {
      // mapping angka ke index array bilangan
      return bilangan[angka] + " tahun";
    } else if (angka < 20) {
      // bilangan 'belasan'
      // misal 18 maka 18 - 10 = 8
      return bilangan[angka - 10] + " belas tahun";
    } else if (angka < 100) {
      // bilangan 'puluhan'
      // misal 27 maka 27 / 10 = 2.7 (integer => 2) 'Dua'
      // untuk mendapatkan sisa bagi gunakan modulus
      // 27 mod 10 = 7 'Tujuh'
      hasil_bagi = parseInt(angka / 10);
      hasil_mod = angka % 10;
      return bilangan[hasil_bagi] + " puluh " + bilangan[hasil_mod] + " tahun";
    } else if (angka < 200) {
      // bilangan 'seratusan' (itulah indonesia knp tidak satu ratus saja? :))
      // misal 151 maka 151 = 100 = 51 (hasil berupa 'puluhan')
      // daripada menulis ulang rutin kode puluhan maka gunakan
      // saja fungsi rekursif dengan memanggil fungsi terbilang(51)
      return "Seratus " + this.terbilang(angka - 100);
    } else if (angka < 1000) {
      // bilangan 'ratusan'
      // misal 467 maka 467 / 100 = 4,67 (integer => 4) 'Empat'
      // sisanya 467 mod 100 = 67 (berupa puluhan jadi gunakan rekursif terbilang(67))
      hasil_bagi = parseInt(angka / 100);
      hasil_mod = angka % 100;
      return (
        bilangan[hasil_bagi] + " Ratus " + this.terbilang(hasil_mod) + " tahun"
      );
    } else if (angka < 2000) {
      // bilangan 'seribuan'
      // misal 1250 maka 1250 - 1000 = 250 (ratusan)
      // gunakan rekursif terbilang(250)
      return "Seribu " + this.terbilang(angka - 100);
    } else if (angka < 1000000) {
      // bilangan 'ribuan' (sampai ratusan ribu
      hasil_bagi = parseInt(angka / 1000); // karena hasilnya bisa ratusan jadi langsung digunakan rekursif
      hasil_mod = angka % 1000;
      return (
        this.terbilang(hasil_bagi) +
        " Ribu " +
        this.terbilang(hasil_mod) +
        " tahun"
      );
    } else if (angka < 1000000000) {
      // bilangan 'jutaan' (sampai ratusan juta)
      // 'satu puluh' => SALAH
      // 'satu ratus' => SALAH
      // 'satu juta' => BENAR
      // @#$%^ WT*

      // hasil bagi bisa satuan, belasan, ratusan jadi langsung kita gunakan rekursif
      hasil_bagi = parseInt(angka / 1000000);
      hasil_mod = angka % 1000000;
      return (
        this.terbilang(hasil_bagi) +
        " Juta " +
        this.terbilang(hasil_mod) +
        " tahun"
      );
    } else if (angka < 1000000000000) {
      // bilangan 'milyaran'
      hasil_bagi = parseInt(angka / 1000000000);
      // karena batas maksimum integer untuk 32bit sistem adalah 2147483647
      // maka kita gunakan fmod agar dapat menghandle angka yang lebih besar
      hasil_mod = angka % 1000000000;
      return (
        this.terbilang(hasil_bagi) +
        " Milyar " +
        this.terbilang(hasil_mod) +
        " tahun"
      );
    } else if (angka < 1000000000000000) {
      // bilangan 'triliun'
      hasil_bagi = angka / 1000000000000;
      hasil_mod = angka % 1000000000000;
      return (
        this.terbilang(hasil_bagi) +
        " Triliun " +
        this.terbilang(hasil_mod) +
        " tahun"
      );
    } else {
      return "Wow...";
    }
  };
  // ===============================================

  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "waktu_meninggal":
        this.setState({ waktu_meninggal: date });
        break;
      case "waktu_meninggal2":
        this.setState({ waktu_meninggal2: date });
        break;
      default:
        // no default
        break;
      // case 'tanggal_lahir': this.setState({tanggal_lahir:date});break;
    }
  };

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    this.setState({ loading: true })
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get(
      "surat-keterangan/domisili/" + this.props.location.state.uuid + link,
      null,
      dtkat => {
        if(dtkat.results){
          this.setState({ data: dtkat.results.data, pagination: dtkat.results, loading: false });
          this.pagination(dtkat.results); 
          
          // For testing
          if (this.props.getListSuratDomisili) {
            this.props.getListSuratDomisili(dtkat.results.data)
          }
        }
      }
    );
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let addSuratKeteranganDomisili = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/domisili/" + this.props.location.state.uuid,
        null,
        addSuratKeteranganDomisili,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
        }
      );
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    
    // Jika nik pemohon sudah dipilih
    if (this.state.nikStat) {
      this.state.status.btnForm = true;
      this.forceUpdate();
      let tgl_lhr = this.state.dt.tanggal_lahir.split("/");
      let tanggal_lahir = tgl_lhr[2] + "-" + tgl_lhr[1] + "-" + tgl_lhr[0];
      let addSuratKeteranganDomisili = {
        uuid: document.getElementById("uuid").value,
        id_jenis: this.props.location.state.uuid,
        // nomor : document.getElementById("nomor").value,

        nik_pelapor: this.state.nikStat.value,
        nik: this.state.nikStat.value,
        kk: this.state.dt.kk,
        nama_pemohon: this.state.dt.nama,
        tempat_lahir: this.state.dt.tempat_lahir,
        tanggal_lahir: tanggal_lahir,
        jenis_kelamin: this.state.dt.jenis_kelamin,
        pekerjaan: this.state.dt.pekerjaan,
        pendidikan_terakhir: this.state.dt.pendidikan_terakhir,
        kewarganegaraan: this.state.dt.kewarganegaraan,
        agama: this.state.dt.agama,
        alamat: this.state.dt.alamat,
        status_perkawinan: this.state.dt.status_perkawinan,
        tujuan_pembuatan: document.getElementById("tujuan_pembuatan").value,
        // tujuan_pembuatan : "-",
        detail: this.state.detail
      };
        
      //console.log("Data Simpan Domisili")
      //console.log(addSuratKeteranganDomisili)

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (addSuratKeteranganDomisili.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        addSuratKeteranganDomisili.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      Post(
        "surat-keterangan/domisili/" + metode,
        addSuratKeteranganDomisili.uuid,
        addSuratKeteranganDomisili,
        res => {
          // For testing
          if (this.props.resSubmit) {
            this.props.resSubmit(res.status)
          }
          this.tutupForm();
          this.state.status.btnForm = false;
          this.state.status.form = false;
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Surat Keterangan Domisili",
              pesanAlert: "Berhasil " + psn + " Data",
              successAlert: true
            });
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Surat Keterangan Domisili",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        }
      );
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Belum Lengkap",
        pesanAlert: "Mohon pilih data NIK Pemohon"
      });
    }

  };
  // ========================================================================
  // ========================================================================
  // list disposisi

  listDisposisi = data => {
    //console.log(data);
    Get("alur-disposisi/" + data.id_jenis, null, dtkat => {
      //console.log(dtkat);
      if (dtkat) {
        //console.log(dtkat.results);

        this.setState({
          idSurat: data.uuid,
          datDisposisi: dtkat.results,
          formDisposisi: true,
          riwayatDisposisi: data.data_status_disposisi
        });
      }
    });
  };
  //disposisi
  disposisi = (label, id) => {
    //console.log(label);
    Get("disposisi/domisili/" + label + "/" + id, null, dtkat => {
      if (dtkat.code === 201) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Disposisi",
          pesanAlert: "Berhasil  Disposisi Data"
        });
        this.componentDidMount();
        this.tutupForm();
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Disposisi",
          pesanAlert: "Disposisi telah dilakukan"
        });
        this.tutupForm();
      }
    });
    //console.log(id);
  };
  // ============================== Ubah Data ===============================

  ubahData = data => {
    if (data && data.kk !== '') {
      this.setState({ loadingPreview: true })
      Get("penduduk/kk/"+data.kk, null, dtkk => {
        let frmNik3 = [];
        dtkk.results.forEach(val => {
          frmNik3.push({ value: val.id, label: val.id + ' (' + val.nama + ')' });
        });

        this.setState({
          dt: {
            ...this.state.dt,
            uuid: data.uuid,
            id_jenis: data.id_jenis,
            nik: data.nik,
            kk: data.kk,
            nama_pemohon: data.nama_pemohon,
            tempat_lahir: data.tempat_lahir,
            tanggal_lahir: data.tanggal_lahir,
            jenis_kelamin: data.jenis_kelamin,
            status_perkawinan: data.status_perkawinan,
            pendidikan_terakhir: data.pendidikan_terakhir,
            kewarganegaraan: data.kewarganegaraan,
            pekerjaan: data.pekerjaan,
            agama: data.agama,
            alamat: data.alamat,
            tujuan_pembuatan: data.tujuan_pembuatan,
            detail: data.data_detail_domisili,
            status_ttd: data.status_ttd
          },
          status: {
            ...this.state.status,
            dataPenduduk: "block",
            form: true,
          },
          nikStat: {
            value: data.nik,
            label: data.nik + ' (' + data.nama_pemohon + ')'
          },
          frmNik3,
          loadingPreview: false
        })
      })
    }

    // // this.state.status.btnAksi = true;
    // this.state.judul = "Ubah Surat Domisili";
    // this.forceUpdate();
    // // this.componentDidMount();
    // Get("surat-keterangan/domisili/find", id, data => {
    //   Get("penduduk/kk/" + data.results.kk, null, data => {
    //     let frmNik3 = [];
    //     data.results.forEach(dt => {
    //       frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
    //     });
    //     this.setState({ frmNik3 });
    //   });
    //   //console.log(data.results);
    //   this.state.dt.uuid = data.results.uuid;
    //   this.state.dt.id_jenis = data.results.id_jenis;
    //   this.state.dt.nomor = data.results.nomor;
    //   this.state.dt.tanggal_surat = data.results.tanggal_surat;
    //   this.state.tanggal_surat = new Date(data.results.tanggal_surat);

    //   this.state.dt.nik = data.results.nik;
    //   this.state.dt.kk = data.results.kk;
    //   this.state.dt.nama = data.results.nama_pemohon;
    //   this.state.dt.tanggal_lahir = new Date(
    //     data.results.tanggal_lahir
    //   ).toLocaleDateString("id-ID");
    //   this.state.dt.tempat_lahir = data.results.tempat_lahir;
    //   this.state.dt.alamat = data.results.alamat;
    //   this.state.dt.agama = data.results.agama;
    //   this.state.dt.pendidikan_terakhir = data.results.pendidikan_terakhir;
    //   this.state.dt.jenis_kelamin = data.results.jenis_kelamin;
    //   this.state.dt.pekerjaan = data.results.pekerjaan;
    //   this.state.dt.kewarganegaraan = data.results.kewarganegaraan;
    //   this.state.dt.status_perkawinan = data.results.status_perkawinan;

    //   this.state.dt.tujuan_pembuatan = data.results.tujuan_pembuatan;

    //   this.state.detail = data.results.data_detail_domisili;
    //   this.state.dt.status_ttd = data.results.status_ttd;
    //   this.state.dt.jabatan_ttd = data.results.jabatan_ttd;
    //   this.state.dt.jabatan_pengganti_ttd = data.results.jabatan_pengganti_ttd;
    //   this.state.dt.nama_ttd = data.results.nama_ttd;
    //   this.state.dt.nip_ttd = data.results.nip_ttd;

    //   this.state.nikStat = { value: data.results.nik, label: data.results.nik + ' (' + data.results.nama_pemohon + ')' };
    //   this.state.status.dataPenduduk = "block";

    //   // this.forceUpdate();
    //   this.bukaForm();
    // });
  };
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapus = i => {
    this.state.detail.splice(i, 1);
    this.forceUpdate();
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/domisili/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Keterangan Domisili",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Keterangan Domisili",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.setState({
      status:{
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.nikStat = null;
    this.state.dt.nama = "";
    this.state.dt.kk = "";
    this.state.dt.tanggal_lahir = "";
    this.state.dt.tempat_lahir = "";
    this.state.dt.agama = "";
    this.state.dt.pekerjaan = "";
    this.state.dt.pendidikan_terakhir = "";
    this.state.dt.kewarganegaraan = "";
    this.state.dt.alamat = "";
    this.state.dt.jenis_kelamin = "";
    this.state.dt.status_perkawinan = "";
    this.state.status.dataPenduduk = "none";
    this.state.nik3Stat = null;
    this.state.detail = [];
    this.state.shdk_aw = null;

    this.state.accStat = null;
    this.state.tableAcc = "none";
    this.state.formAcc = false;
    this.state.formDisposisi = false;
    this.state.status.btnForm = false;

    this.state.status.form = false;
    this.forceUpdate();
  };

  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get(
      "surat-keterangan/domisili/persetujuan/" + jawaban + "/" + id,
      null,
      res => {
        if (res.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  tombolAksi = (cell, row) => {
    // let btnApproval = false;
    // let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.forEach(dt => {
      status = dt.status;
    });
    if (status === "Diajukan") {
      // btnCetak = true;
      btnAksi = true;
      // btnApproval = true;
    } else {
      btnAksi = true;
      // btnApproval = true;
      if (status === "Ditolak") {
        // btnCetak = true;
      } else if (status === "Disetujui") {
        btnAksi = false;
        // btnCetak = true;
        // btnApproval = false;
      } else if ((status === "Menunggu Penerbitan") | (status === "Selesai")) {
        // btnAksi = false;
        // btnCetak = false;
        // btnApproval = false;
      } else {
        // btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={() => this.konfirmApprove(row.uuid)}
          disabled={btnApproval}
        >
          <i className="fa fa-check"></i>
        </Button>{" "}
        &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        {/* <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "} */}
        {/* 
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.cetakSurat(e, row)}
          disabled={btnCetak}
        >
          <i className="fa fa-print"></i>
        </Button>
        &nbsp;&nbsp; */}
        {/* //list */}
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.listDisposisi(row)}
        >
          <i className="fa fa-list"></i>
        </Button> */}
      </>
    );
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.fetch({ page: null, where: [] });
      let frmJam = [];
      let jamnya = "";
      for (let i = 1; i <= 24; i++) {
        jamnya = i;
        if (i < 10) {
          jamnya = "0" + i;
        }
        jamnya += ":00";
        frmJam.push({ value: jamnya, label: jamnya });
      }
      this.setState({ frmJam });
      Get('shdk/list', null, (data) => {
				let frmShdk = {};
				data.results.forEach((dt) => {
          frmShdk[dt.uuid] = dt.nama
				});
				this.setState({ frmShdk });
			});
      Get("penduduk", null, data => {
        let frmNik = [];
        data.results.data.forEach(dt => {
          frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
        });
        this.setState({ frmNik });

        // For testing
        if (this.props.getListNIK) {
          this.props.getListNIK(frmNik)
        }
      });
      Get("tanda-tangan/list", null, data => {
        data.results.forEach((dt, i) => {
          if (dt.aktif === 1) {
            let temp = this.state.accButton.forEach(x => 1);
            temp[i] = 0;
            this.state.accButton = temp;
            this.state.accuuid = dt.uuid;
          }
        });
        this.forceUpdate();
        this.setState({ frmTTD: data.results });
      });
    } else {
      this.setState({ redirect: true });
    }
  }

  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        let nikStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik = [];
          datanik.data.results.data.forEach(dt => {
            frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmNik, nikStat });
        });
      } else {
        // let nik3Stat = { value: e, label: e };
        // let param = {
        //   nik: e
        // };
        // Post("penduduk", null, param, datanik => {
        //   let frmNik3 = [];
        //   datanik.data.results.data.forEach(dt => {
        //     frmNik3.push({ value: dt.id, label: dt.id });
        //   });
        //   this.setState({ frmNik3, nik3Stat });
        // });
      }
    }
  };

  getPenduduk = (e, sel) => {
    this.state.status.btnForm = true;
    this.forceUpdate();
    if (e) {
      this.changeSelectValue(e, sel);
      Get("penduduk/find", e.value, data => {
        //console.log(data.results)
        // const data_rt = data.results.data_kk.data_rt;
        // const data_rw = data_rt.data_rw;
        // const data_dusun = data_rw.data_dusun;
        // const data_desa = data_dusun.data_keldes;
        // const data_kecamatan = data_desa.data_kecamatan;
        // const data_kabkota = data_kecamatan.data_kabkota;
        let alamat = '';
        if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
          alamat =
          " RT. " +
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.data_kabkota.nama; 
        } else {
          if (data.results.data_kk.id_rt === '0') {
            alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
            ", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
          } else {
            alamat =
            " RT. " + 
            data.results.data_kk.data_rt.nama +
            "/RW. " +
            data.results.data_kk.data_rt.data_rw.nama +
            ", " + JENIS_DUSUN + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.nama +
            ", " + localStorage.getItem('JENIS_DESA') + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
            ", Kec. " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.data_kabkota.nama; 
          }
        }

        if (sel === "nik2") {
          this.state.tanggal_lahir2 = data.results.tanggal_lahir;
          this.state.dt.nama2 = data.results.nama;
          this.state.dt.tanggal_lahir2 = new Date(
            data.results.tanggal_lahir
          ).toLocaleDateString("id-ID");
          this.state.dt.tempat_lahir2 = data.results.tempat_lahir;
          this.state.dt.jenis_kelamin2 = data.results.jk;
          this.state.dt.status_perkawinan2 = data.results.status_perkawinan;
          this.state.dt.agama2 = data.results.data_agama.nama;
          this.state.dt.pekerjaan2 = data.results.data_pekerjaan.nama;
          this.state.dt.kewarganegaraan2 = "Indonesia";
          this.state.dt.alamat2 = alamat;
          this.state.status.dataPenduduk2 = "block";
        } else {
          if (sel === "nik3") {
            aw.nik = e.value;
            aw.nama = data.results.nama;
            aw.jenis_kelamin = data.results.jenis_kelamin;
            aw.tanggal_lahir = data.results.tanggal_lahir;
            aw.tempat_lahir = data.results.tempat_lahir;
            aw.pekerjaan = data.results.data_pekerjaan.nama;
          } else {
            Get("catatan-penduduk/nik", data.results.id, dpddk => {
              let pctt = 0;
              let catatan = "";
              if (dpddk.results.data) {
                dpddk.results.data.forEach(dtp => {
                  if (dtp.status === 0 && dtp.penalti === 1) {
                    pctt = 1;
                    catatan = dtp.catatan;
                  }
                });
              }
              if (pctt === 0) {
                Get("penduduk/kk/" + data.results.id_kk, null, data => {
                  //console.log("Data Keluarga DOmisili")
                  //console.log(data)
                  if(data.results){
                    let frmNik3 = [];
                    data.results.forEach(dt => {
                      frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
                    });
                    this.setState({ frmNik3 });
                    
                    // For testing
                    if (this.props.getListNIK3) {
                      this.props.getListNIK3(frmNik3)
                    }
                  }
                });
                this.state.tanggal_lahir = data.results.tanggal_lahir;
                this.state.dt.kk = data.results.id_kk;
                this.state.dt.nama = data.results.nama;
                this.state.dt.tanggal_lahir = new Date(
                  data.results.tanggal_lahir
                ).toLocaleDateString("id-ID");
                this.state.dt.tempat_lahir = data.results.tempat_lahir;
                this.state.dt.jenis_kelamin = data.results.jk;
                this.state.dt.status_perkawinan =
                  data.results.status_perkawinan;
                this.state.dt.agama = data.results.data_agama.nama;
                this.state.dt.pekerjaan = data.results.data_pekerjaan.nama;
                this.state.dt.pendidikan_terakhir =
                  data.results.data_pendidikan_terakhir.nama;
                this.state.dt.kewarganegaraan = "Indonesia";
                this.state.dt.alamat = alamat;
                this.state.status.dataPenduduk = "block";
                this.state.status.btnForm = false;
                this.forceUpdate();
              } else {
                this.state.nikStat = null;
                // this.state.frmNik = [];
                this.state.dt.nama = "";
                this.state.dt.kk = "";
                this.state.dt.tanggal_lahir = "";
                this.state.dt.tempat_lahir = "";
                this.state.dt.agama = "";
                this.state.dt.pekerjaan = "";
                this.state.dt.pendidikan_terakhir = "";
                this.state.dt.kewarganegaraan = "";
                this.state.dt.alamat = "";
                this.state.dt.jenis_kelamin = "";
                this.state.dt.status_perkawinan = "";
                this.state.status.dataPenduduk = "none";
                this.state.status.btnForm = true;
                this.forceUpdate();
                this.setState({
                  show: true,
                  basicType: "danger",
                  basicTitle: "Peringatan Catatan Warga",
                  pesanAlert:
                    "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                    catatan
                });
              }

              // For testing
              if (this.props.selectOnChange) {
                this.props.selectOnChange(this.state.dt)
              }
              if (this.props.selectOnChange3) {
                this.props.selectOnChange3(this.state.dt)
              }
              
            });
          }
        }
        this.forceUpdate();
      });
    } else {
      this.state.nikStat = null;
      this.state.dt.nama = "";
      this.state.dt.kk = "";
      this.state.dt.tanggal_lahir = "";
      this.state.dt.tempat_lahir = "";
      this.state.dt.agama = "";
      this.state.dt.pekerjaan = "";
      this.state.dt.pendidikan_terakhir = "";
      this.state.dt.kewarganegaraan = "";
      this.state.dt.alamat = "";
      this.state.dt.jenis_kelamin = "";
      this.state.dt.status_perkawinan = "";
      this.state.status.dataPenduduk = "none";
      this.state.status.btnForm = true
      this.forceUpdate();
    }
  };

  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  changeInputValue = (e, sel) => {
    switch (sel) {
      case "shdk":
        aw.shdk = e.target.value;
        this.forceUpdate();
        break;
      default:
        // no default
        break;
    }
  };

  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jam":
          this.state.dt.jam = e.value;
          this.setState({ jamStat: e });
          break;
        case "pekerjaan":
          this.state.dt.pekerjaan = e.label;
          this.setState({ pkrStat: e });
          break;
        case "agama":
          this.state.dt.agama = e.label;
          this.setState({ agmStat: e });
          break;
        case "nik":
          this.state.dt.nik = e.value;
          this.setState({ nikStat: e });
          break;
        case "nik2":
          this.state.dt.nik2 = e.value;
          this.setState({ nik2Stat: e });
          break;
        case "nik3":
          this.state.dt.nik3 = e.value;
          this.setState({ nik3Stat: e });
          break;
        case 'shdk_aw':
          this.state.shdk_aw = e.value;
          this.setState({ shdk_aw: e });
          
          // For testing
          if (this.props.selectOnChangeShdk) {
            this.props.selectOnChangeShdk(e.value)
          }
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "jam":
          this.state.dt.jam = 0;
          this.setState({ jamStat: e });
          break;
        case "pekerjaan":
          this.state.dt.pekerjaan = "";
          this.setState({ pkrStat: e });
          break;
        case "agama":
          this.state.dt.agama = "";
          this.setState({ agmStat: e });
          break;
        case "nik":
          this.state.dt.nik = 0;
          this.setState({ nikStat: e });
          break;
        case "nik2":
          this.state.dt.nik2 = 0;
          this.setState({ nik2Stat: e });
          break;
        case "nik3":
          this.state.dt.nik3 = 0;
          this.setState({ nik3Stat: e });
          break;
        case 'shdk_aw':
          // this.state.shdk_aw = null;
          this.setState({ shdk_aw: e });
          break;
        default:
          // no default
          break;
      }
    }

    this.forceUpdate();
  };

  tambahAw = e => {
    if (this.state.shdk_aw && this.state.nik3Stat) {
      detail = this.state.detail;
      Get("penduduk/find", this.state.nik3Stat.value, data => {
        if (data.results) {
          detail.push({
            nik: this.state.nik3Stat.value,
            nama: data.results.nama,
            jk: data.results.jk,
            tanggal_lahir: data.results.tanggal_lahir,
            tempat_lahir: data.results.tempat_lahir,
            pekerjaan: data.results.data_pekerjaan.nama,
            pendidikan: data.results.data_pendidikan_terakhir.nama,
            shdk: this.state.shdk_aw.value,
            keterangan: ""
          });
          this.setState({ detail, nik3Stat : null, shdk_aw: null });
          document.getElementById("shdk").value = '';

          // For testing
          if (this.props.getDataKeluarga) {
            this.props.getDataKeluarga(detail)
          }
        }
      });
    } else {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Surat Keterangan Domisili",
        pesanAlert: "Pilih NIK dan hubungan keluarga terlebih dahulu"
      });
    }
  };

  //change select
  changeAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.state.accStat = e;
          break;
        default:
          // no default
          break;
      }
      if (e.value === "Disetujui") {
        this.state.tableAcc = "";
        this.state.btnFormAcc = true;
        if (this.state.accButton.length > 0) {
          this.state.btnFormAcc = false;
        }
      } else {
        this.state.tableAcc = "none";
        this.state.btnFormAcc = false;
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.state.accStat = e;
          break;
        default:
          // no default
          break;
      }
      this.state.tableAcc = "none";
      this.state.btnFormAcc = true;
    }
    this.state.status.select = false;
    this.forceUpdate();
  };

  simpanAcc = e => {
    e.preventDefault();
    this.state.btnFormAcc = true;

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),
      uuid: this.state.accuuid
    };
    Post(
      "surat-keterangan/domisili/persetujuan/Selesai/" + this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            btnFormAcc: false,
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
    this.tutupForm();
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.forEach(x => 1);
    temp[id] = 0;
    this.state.accButton = temp;
    this.state.accuuid = uuid;
    if (this.state.accStat) {
      this.state.btnFormAcc = false;
    }
    this.forceUpdate();
  };
  render() {
    
    //console.log("Data Render")
    //console.log("Data Domisili")
    //console.log("====================")

    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;

    const frmDef = {
      uuid: "",
      id_jenis: "",
      nomor: "",
      tanggal_surat: new Date(),

      nik: "",
      kk: "",
      nama: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      jenis_kelamin: "",
      pekerjaan: "",
      pendidikan_terakhir: "",
      kewarganegaraan: "",
      agama: "",
      alamat: "",
      status_perkawinan: "",
      tujuan_pembuatan: "",

      status_ttd: "",
      jabatan_ttd: "",
      jabatan_pengganti_ttd: "",
      nama_ttd: "",
      nip_ttd: ""
    };
    let no = 0;

    const domisili = this.state.detail.map((dt, i) => {
      no++;
      let jk = "L";
      if (dt.jk === "Perempuan") {
        jk = "P";
      }
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.nama}</td>
          <td>{jk}</td>
          <td>
            {dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
          </td>
          <td>{dt.pekerjaan}</td>
          <td>{dt.pendidikan}</td>
          <td>{dt.shdk}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.hapus(i)}
              color="danger"
              className="btn-icon"
            >
              <i className="fa fa-trash"></i>
            </Button>
          </td>
        </tr>
      );
    });

    // const { SearchBar } = Search;
    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          headerAlign: 'center',
				  align: 'center',
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.pagination.current_page;
              let total_records_per_page = this.state.pagination.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
        dataField: "tanggal_surat",
        headerAlign: 'center',
				align: 'center',
        text: "Tanggal",
        formatter: (cell, obj) => {
          let plainTime = '-'
					if (obj.created_at) {
						let date = new Date(obj.created_at)
						date = date.toLocaleString('id-ID')
						
						const datetime = date.split(' ')
						
	
						date = datetime[0]
						let time = datetime[1]
	
						const splitDate = date.split('/')
						time = time.replaceAll('.', ':')
	
						console.log(date)
						const list_bulan = [
							'Januari',
							'Februari',
							'Maret',
							'April',
							'Mei',
							'Juni',
							'Juli',
							'Agustus',
							'September',
							'Oktober',
							'November',
							'Desember'
						];

						plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}  ${time}`;
					}

					return plainTime
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        headerAlign: 'center',
				align: 'center',
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor === "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "nik",
        headerAlign: 'center',
				align: 'center',
        text: "NIK",
        sort: true
      },
      {
        dataField: "nama_pemohon",
        headerAlign: 'center',
				align: 'center',
        text: "Nama",
        sort: true
      },
      {
        dataField: "status",
        headerAlign: 'center',
				align: 'center',
        text: "Status",
        isDummyField: true,
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.forEach(dt => {
            status = dt.status;
          });
          return status;
        },
        sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-primary-custom";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a
    //         href="javascript:void()"
    //         onClick={handleClick}
    //         className={classname}
    //       >
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };
    no = 0;
    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] === 0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] === 0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <div data-testid="page-surat keterangan domisili">
        {this.renderRedirect()}
        {/* <Breadcrumb title="Data Surat Keterangan Domisili" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  this.bukaForm();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                // color="info"
                className="btn btn-secondary-custom"
                // onClick={() => {
                //   this.closeAlert();
                // }}
              >
                <Link
                  data-testid="btn-eksekusi_surat"
                  onClick={() => {
                    // For testing
                    if (this.props.clickEksekusiSurat) {
                      this.props.clickEksekusiSurat()
                    }
                  }}
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Keterangan Domisili</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        data-testid="tblCreateSuratDomisili"
                        size="sm"
                        color="success"
                        // className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Surat Keterangan Domisili",
                            dt: null,
                            nikStat: null,
                            detail: []
                          });
                          this.setState({
                            status:{
                              ...this.state.status,
                              dataPenduduk: "none",
                            },
                          })
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  {this.state.loading ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img 
                        alt="loading"
                        src={require("../assets/images/loading2.gif")} 
                        style={{
                          borderRadius: "10px",
                          width: '331px'
                        }}/> 
                      </div>
                    </div>
                  ) : (
                    <>
                      <BootstrapTable keyField="id" data={data} columns={columns} />
                      <div className="pull-right text-white">
                        {this.state.awal}
                        {this.state.sebelum}
                        {this.state.hal.map(dt => {
                          return dt;
                        })}
                        {this.state.setelah}
                        {this.state.akhir}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        {/* Loading preview */}
        <SweetAlert
					show={this.state.loadingPreview}
					showCancel = {false}
					showConfirm={false}
				>
					<div align="center">
						<h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
						<br/>
						<img 
							alt="loading"
							src={require("../assets/images/loading-preview.gif")} 
							style={{
								// width: "100%",
								borderRadius: "10px",
								width: '150px'
							}}
						/> 
						<br/><br/>
					</div>
				</SweetAlert>

        <ModalSuratDomisili
          data={this.state.dt}
          isOpen={this.state.status.form}
          propsData={this.props}
          optionData={{
            frmNik: this.state.frmNik,
            frmNik3: this.state.frmNik3,
            frmShdk: this.state.frmShdk,
            nikStat: this.state.nikStat,
            status: this.state.status,
          }}
          setAlert={(e) => {
            this.setState(e)
          }}
          setStatusRefresh={(e) => {
            if (e) {
              this.fetch({ page: null, where: [] });
            }
          }}
          setIsOpen={(e) => {
            if (!e) {
              this.setState({ dt: null })
            }
            this.setState({
              status: {
                ...this.state.status,
                form: e
              }
            })
          }}
        />
        
        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              {/* <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              /> */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  />
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary-custom">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Jabatan</th>
                    <th>Level</th>
                    <th>TTD</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.datDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{dt.data_jabatan.nama}</td>
                        <td>{dt.level}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => {
                              this.disposisi(dt.uuid, this.state.idSurat);
                            }}
                            disabled={this.state.status.btnForm}
                          >
                            Disposisi
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.riwayatDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status === 0
                            ? "Menunggu Disposisi"
                            : dt.status === 1
                            ? "Telah di disposisi"
                            : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>

      </div>
    );
  }
}

export default BasicTable;
