import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
// import Datepicker from "react-datepicker";
import Lightbox from "rhino-react-image-lightbox-rotate";

import "react-image-lightbox/style.css";
import Modal from "react-responsive-modal";
import classnames from "classnames";
import {
  Button,
  Form,
  FormGroup,
  Label,
  // Input,
  // FormText,
  // ButtonGroup,
  // CustomInput,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { PostStatus, Post, Get, Delete,} from "../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import DatePicker from "reactdatepicker";

//json file
// var data = require('../assets/json/company');
const frmDef = {
  uuid: "",
  username: "",
  password: "",
  nik: 0,
  id_role: "",
  status: null,
  no_hp: null,
  email: null
};

class Verifikasi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataMenungguVerifikasi: [],
      dataVerifikasiDitolak: [],
      dataSudahAktif: [],
      active_tab_icon: "1",
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        formVerifikasi: false,
        statGet: false
      },
      detailData: null,
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDef,
			loading: false,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: [],
      frmKbkt: [],
      kbktStat: [],
      frmKec: [],
      kecStat: [],
      frmKel: [],
      kelStat: [],
      pendStat: [],
      frmPen: [],
      jpStat: [],
      frmJp: [],
      formPenduduk: "none",
      dataVerifikasi: [],
      photoIndex: 0,
      isOpen: false,
      idVerifikasi: null,
      dataCari: {
        q: ""
      }
    };
  }

  // ================== DatePicker ==================
  gantiTglMulai = date => {
    this.setState({
      dt:{
        ...this.state.dt,
        tanggal_mulai: date,
      }
    })
    this.forceUpdate();
  };
  gantiTglSelesai = date => {
    this.setState({
      dt:{
        ...this.state.dt,
        tanggal_selesai: date,
      }
    })
    this.forceUpdate();
  };
  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      switch (tab) {
        case "1":
          this.fetch({ page: null, where: [], tab: "0" });
          break;

        case "2":
          this.fetch({ page: null, where: [], tab: "1" });
          break;

        case "3":
          this.fetch({ page: null, where: [], tab: "2" });
          break;

        case "4":
          this.fetch({ page: null, where: [], tab: "3" });
          break;

        default:
          this.fetch({ page: null, where: [], tab: "0" });
          break;
      }

      this.setState({
        active_tab_icon: tab
      });
    }
  }

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    this.setState({ loading: true })
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          switch (params.tab) {
            case "0":
              link =
                "&page=" +
                Math.ceil(
                  this.state.pagination.total / this.state.pagination.per_page
                );
              break;

            case "1":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationMenungguVerifikasi.total /
                  this.state.paginationMenungguVerifikasi.per_page
                );
              break;

            case "2":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationVerifikasiDitolak.total /
                  this.state.paginationVerifikasiDitolak.per_page
                );
              break;

            case "3":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationSudahAktif.total /
                  this.state.paginationSudahAktif.per_page
                );
              break;
            
            default:
              // no default
              break;
          }
          break;
        case ">":
          switch (params.tab) {
            case "0":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) + 1);
              break;

            case "1":
              link =
                "&page=" +
                (parseInt(this.state.paginationMenungguVerifikasi.current_page) +
                  1);
              break;

            case "2":
              link =
                "&page=" +
                (parseInt(this.state.paginationVerifikasiDitolak.current_page) + 1);
              break;

            case "3":
              link =
                "&page=" +
                (parseInt(this.state.paginationSudahAktif.current_page) + 1);
              break;
              
            default:
              // no default
              break;

          }
          break;
        case "<":
          switch (params.tab) {
            case "0":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) - 1);
              break;

            case "1":
              link =
                "&page=" +
                (parseInt(this.state.paginationMenungguVerifikasi.current_page) -
                  1);
              break;

            case "2":
              link =
                "&page=" +
                (parseInt(this.state.paginationVerifikasiDitolak.current_page) - 1);
              break;

            case "3":
              link =
                "&page=" +
                (parseInt(this.state.paginationSudahAktif.current_page) - 1);
              break;
           
            default:
              // no default
              break;
          }
          break;
        case "<<":
          link = "&page=1";
          break;
        default:
          link = "&page=" + params.page;
          break;
      }
    }

    Get("pengguna?status=" + params.tab + link, null, dtkat => {
      //console.log(dtkat);
      switch (params.tab) {
        case "0":
          this.setState({
            data: dtkat.results.data,
            pagination: dtkat.results
          });
          break;

        case "1":
          this.setState({
            dataMenungguVerifikasi: dtkat.results.data,
            paginationMenungguVerifikasi: dtkat.results
          });
          break;

        case "2":
          this.setState({
            dataVerifikasiDitolak: dtkat.results.data,
            paginationVerifikasiDitolak: dtkat.results
          });
          break;

        case "3":
          this.setState({
            dataSudahAktif: dtkat.results.data,
            paginationSudahAktif: dtkat.results
          });
          break;
        default:
          this.setState({
            data: dtkat.results.data,
            pagination: dtkat.results
          });
          break;
      }
      this.setState({ loading: false })
      this.pagination(dtkat.results, params.tab);
    });
  };

  // Pagination
  pagination = (data, tab) => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            key={i}
            onClick={() => this.fetch({ page: i, tab: tab })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }

      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table

  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let dataCari = {
        q: e.target.value
      };
      this.setState({ dataCari });
      setTimeout(
        function() {
          // mywindow.close();
          this.fetch();
        }.bind(this),
        500
      );
      // Post('kartu-keluarga',null, dataKK, (data) => {
      //     this.setState({data:data.data.results.data, pagination:data.data.results});
      // });
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    //console.log(e);
    if (this.state.dt.id_role !== "") {
      this.setState({
        status:{
          ...this.state.status,
          btnForm: true,
        }
      })
      this.forceUpdate();

      let add = {
        uuid: this.state.dt.uuid,
        username: document.getElementById("username").value,
        nik: this.state.dt.nik,
        status: this.state.dt.status,
        id_role: this.state.dt.id_role,
        no_hp: document.getElementById("nohp").value,
        email: document.getElementById("email").value
      };
      if (document.getElementById("password").value !== "") {
        add = {
          uuid: this.state.dt.uuid,
          username: document.getElementById("username").value,
          password: document.getElementById("password").value,
          nik: this.state.dt.nik,
          status: this.state.dt.status,
          id_role: this.state.dt.id_role,
          no_hp: document.getElementById("nohp").value,
          email: document.getElementById("email").value
        };
      }

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (add.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        add.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      //console.log(add);
      PostStatus("pengguna/" + metode, add.uuid, add, res => {
        this.setState({
          status:{
            ...this.state.status,
            btnForm: false,
            form: false,
          }
        })
        this.forceUpdate();
        //console.log(res);
        if (res === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Pengguna",
            pesanAlert: "Berhasil " + psn + " Data"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Pengguna",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount(this.state.active_tab_icon);
      });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Pengguna",
        pesanAlert: "Data tidak boleh kosong "
      });
    }
  };
  // ========================================================================

  // ============================== Ubah Data ===============================

  ubahData = id => {
    this.setState({
      status:{
        ...this.state.status,
        btnAksi: true,
      }
    })
    this.forceUpdate();
    Get("pengguna/find", id, data => {
      //console.log(data);
      this.setState({
        dt:{
          ...this.state.dt,
          uuid: data.results.uuid,
          username: data.results.username,
          password: data.results.password,
          nik: data.results.nik,
          status: data.results.status,
          id_role: data.results.id_role,
          no_hp: data.results.no_hp,
          email: data.results.email,
        }
      })
      if (data.results.data_role.nama_role !== "Super Admin") {
        this.setState({
          formPenduduk: "",
          pendStat: {
            value: data.results.data_penduduk.id,
            label: data.results.data_penduduk.nama
          }
        });
      } else {
        this.setState({ formPenduduk: "none" });
      }
      this.setState({
        statusAktifStat: {
          value: data.results.status,
          label: data.results.status === 0 ? "Belum Aktif" : "Sudah Aktif"
        },
        statusPegawaiStat: {
          value: data.results.status_pegawai,
          label: data.results.status_pegawai
        },
        roleStat: {
          value: data.results.data_role.uuid,
          label: data.results.data_role.nama_role
        }
      });
      this.setState({
        status:{
          ...this.state.status,
          btnAksi: false,
        }
      })
      this.forceUpdate();
      this.bukaForm();
    });
  };
  verifikasiBerkas = id => {
    // //console.log(id);
    this.setState({
      status: {
        ...this.state.status,
        formVerifikasi: true,
        statGet: true
      },
      judul: "Verifikasi Penduduk",
      statUbah: false
    });
    Get("pengguna/find", id, data => {
      this.setState({
        status: {
          ...this.state.status,
          statGet: false
        },
        idVerifikasi: id,
        dataVerifikasi: data.results.data_verkas
      });
    });
  };
  verifikasi = (jenis, id) => {
    let bodyFormData;
    if (jenis === "terima") {
      bodyFormData = {
        status_pengajuan: "Diterima"
      };
    } else if (jenis === "tolak") {
      bodyFormData = {
        status_pengajuan: "Ditolak"
      };
    }

    // Verifikasi pengguna
    Get("pengguna/verifikasi/" + jenis + "/" + id, null, dtkat => {
      if(jenis==='tolak'){
        if (dtkat.code === 200) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Verifikasi  Pengguna",
            pesanAlert: "Berhasil Tolak Verifikasi Pengguna"
          });
          this.tutupForm();
          this.componentDidMount(this.state.active_tab_icon);
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Verifikasi Pengguna",
            pesanAlert: "Gagal Tolak Verifikasi Pengguna"
          });
          this.componentDidMount(this.state.active_tab_icon);
        }
      }
      else{
        if (dtkat.results) {
          // Jika ada perubahan data, update data terbaru
          if (this.state.detailData) {
            Post(
              "penduduk/perubahan-data/verifikasi",
              this.state.detailData.uuid,
              bodyFormData,
              data => {
                if (data.status === 200) {
                  this.setState({
                    show: true,
                    basicType: "success",
                    basicTitle: "Verifikasi  Pengguna",
                    pesanAlert: "Berhasil " + jenis + " Verifikasi Pengguna"
                  });
                  this.tutupForm();
                  this.componentDidMount(this.state.active_tab_icon);
                } else {
                  this.setState({
                    show: true,
                    basicType: "danger",
                    basicTitle: "Verifikasi Pengguna",
                    pesanAlert: "Gagal " + jenis + " Verifikasi Pengguna"
                  });
                  this.componentDidMount(this.state.active_tab_icon);
                }
                // this.componentDidMount(this.state.active_tab_icon);
              }
            );
          } else { // Jika tidak, langsung kembalikan response berhasil verifikasi
            // if (dtkat.results.code === 200) {
            if (dtkat.code === 200) {
              this.setState({
                show: true,
                basicType: "success",
                basicTitle: "Verifikasi  Pengguna",
                pesanAlert: "Berhasil " + jenis + " Verifikasi Pengguna"
              });
              this.tutupForm();
              this.componentDidMount(this.state.active_tab_icon);
            } else {
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Verifikasi Pengguna",
                pesanAlert: "Gagal " + jenis + " Verifikasi Pengguna"
              });
              this.componentDidMount(this.state.active_tab_icon);
            }
            // this.componentDidMount(this.state.active_tab_icon);
          }
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Verifikasi Pengguna",
            pesanAlert: "Gagal " + jenis + " Verifikasi Pengguna"
          });
          this.componentDidMount(this.state.active_tab_icon);
        }
      }
    });
  };

  ubahPilihan = (table, data) => {
    //console.log("data");

    //console.log(data);
    // Get(table+'/find', id, (data) => {
    switch (table) {
      // case 'provinsi': this.setState({prvStat:{value:data.id, label:data.nama}}); break;
      case "kecamatan":
        this.getChild(
          {
            value: data.data_kabkota.data_provinsi.id,
            label: data.data_kabkota.data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );
        this.getChild(
          { value: data.data_kabkota.id, label: data.data_kabkota.nama },
          "kab-kota",
          "kab",
          "kecamatan"
        );
        this.setState({
          prvStat: {
            value: data.data_kabkota.data_provinsi.id,
            label: data.data_kabkota.data_provinsi.nama
          },
          kbktStat: {
            value: data.data_kabkota.id,
            label: data.data_kabkota.nama
          },
          kecStat: { value: data.id, label: data.nama }
        });
        break;
      case "keldes":
        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );

        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          "kab-kota",
          "kab",
          "kecamatan"
        );

        this.getChild(
          { value: data.data_kecamatan.id, label: data.data_kecamatan.nama },
          "kecamatan",
          "kec",
          "keldes"
        );

        this.setState({
          prvStat: {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          kbktStat: {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          kecStat: {
            value: data.data_kecamatan.id,
            label: data.data_kecamatan.nama
          },
          kelStat: { value: data.id, label: data.nama }
        });
        //console.log(data);
        this.bukaForm();
        break;
      case "rt":
        this.getChild(
          {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );

        this.getChild(
          {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .nama
          },
          "kab-kota",
          "kab",
          "kecamatan"
        );

        this.getChild(
          {
            value: data.data_rw.data_dusun.data_keldes.data_kecamatan.id,
            label: data.data_rw.data_dusun.data_keldes.data_kecamatan.nama
          },
          "kecamatan",
          "kec",
          "keldes"
        );
        this.getChild(
          {
            value: data.data_rw.data_dusun.data_keldes.id,
            label: data.data_rw.data_dusun.data_keldes.nama
          },
          "keldes",
          "kel",
          "dusun"
        );

        this.getChild(
          {
            value: data.data_rw.data_dusun.id,
            label: data.data_rw.data_dusun.nama
          },
          "dusun",
          "dus",
          "rw"
        );

        this.getChild(
          { value: data.data_rw.id, label: data.data_rw.nama },
          "rw",
          "rw",
          "rt"
        );

        this.setState({
          prvStat: {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.nama
          },
          kbktStat: {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .nama
          },
          kecStat: {
            value: data.data_rw.data_dusun.data_keldes.data_kecamatan.id,
            label: data.data_rw.data_dusun.data_keldes.data_kecamatan.nama
          },
          kelStat: {
            value: data.data_rw.data_dusun.data_keldes.id,
            label: data.data_rw.data_dusun.data_keldes.nama
          },

          dusStat: {
            value: data.data_rw.data_dusun.id,
            label: data.data_rw.data_dusun.nama
          },
          rwStat: { value: data.data_rw.id, label: data.data_rw.nama },
          rtStat: { value: data.id, label: data.nama }
        });
        this.bukaForm();
        break;
      default:
        // no default
        break;
    }
    // });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("pengguna/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Pengguna",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Pengguna",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount(this.state.active_tab_icon);
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.setState({
      status:{
        ...this.state.status,
        form: true,
      }
    })
    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      status:{
        ...this.state.status,
        form: false,
        formVerifikasi: false,
      }
    })
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    return (
      <>
        {row.status === 1 ? (
          <Button
            size="xs"
            color="success"
            className="btn-icon"
            onClick={() => {
              //console.log(row);
              this.setState({ detailData: row.data_perubahan });
              this.verifikasiBerkas(row.uuid);
            }}
            disabled={this.state.status.btnAksi}
            title="Verifikasi Berkas"
          >
            <i className="fa fa-check"></i>
          </Button>
        ) : (
          <></>
        )}{" "}
        {/* <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button> */}
      </>
    );
  };

  getChild = (e, self, parent, child) => {
    this.setState({
      status:{
        ...this.state.status,
        select: true,
      }
    })
    this.forceUpdate();
    this.changeSelectValue(e, self);
    if (e) {
      Get(child + "/" + parent + "/" + e.value + "/list", null, data => {
        let frm = [];
        data.results.forEach(dt => {
          frm.push({ value: dt.id, label: dt.nama });
        });
        switch (child) {
          case "kab-kota":
            this.setState({ frmKbkt: frm, frmKec: [] });
            break;
          case "kecamatan":
            this.setState({ frmKec: frm });
            break;
          case "keldes":
            this.setState({ frmKel: frm });
            break;
          case "dusun":
            this.setState({ frmDus: frm });
            break;
          default:
            // no default
            break;
        }
        this.setState({
          status:{
            ...this.state.status,
            select: false,
          },
        })
        this.forceUpdate();
      });
    }
  };
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "provinsi":
          this.setState({ prvStat: e });
          this.setState({ kbktStat: [] });
          this.setState({ kecStat: [] });
          break;
        case "kab-kota":
          //console.log(e);
          this.setState({ kbktStat: e });
          this.setState({ kecStat: [] });
          break;
        case "kecamatan":
          this.setState({ kecStat: e });
          this.setState({ kelStat: [] });

          break;
        case "keldes":
          this.setState({ kelStat: e });
          this.setState({ dusStat: [] });

          break;

        case "jabatan":
          this.setState({ 
            jpStat: e, 
            dt:{
              ...this.state.dt,
              id_jabatan_perangkat: e.value,
            },
          });
          break;
        case "penduduk":
          this.setState({ 
            pendStat: e, 
            dt:{
              ...this.state.dt,
              nik: e.value,
            },
          });
          break;
        case "status_kepegawaian":
          this.setState({ 
            statusPegawaiStat: e, 
            dt:{
              ...this.state.dt,
              status_pegawai: e.value,
            },
          });
          break;
        case "status_aktif":
          this.setState({ 
            statusAktifStat: e, 
            dt:{
              ...this.state.dt,
              status: e.value,
            },
          });
          break;

        case "role":
          this.setState({
            dt:{
              ...this.state.dt,
              id_role: e.value,
            },
          })
          //console.log(e);
          if (e.label !== "Super Admin") {
            this.setState({ roleStat: e, formPenduduk: "" });
          } else {
            this.setState({ roleStat: e, formPenduduk: "none" });
          }
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "provinsi":
          this.setState({ prvStat: [] });
          this.setState({ kbktStat: [], frmKbkt: [] });
          this.setState({ kecStat: [], frmKec: [] });
          this.setState({
            dt:{
              ...this.state.dt,
              id_rt: 0,
            },
            kelStat: [],
            frmKel: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });
          break;
        case "kab-kota":
          this.setState({ kbktStat: [] });
          this.setState({ kecStat: [], frmKec: [] });
          this.setState({
            dt:{
              ...this.state.dt,
              id_rt: 0,
            },
            kelStat: [],
            frmKel: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });

          break;
        case "kecamatan":
          this.setState({ kecStat: [] });
          this.setState({
            dt:{
              ...this.state.dt,
              id_rt: 0,
            },
            kelStat: [],
            frmKel: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });

          break;
        case "keldes":
          this.setState({
            dt:{
              ...this.state.dt,
              id_rt: 0,
            },
            kelStat: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });
          break;
        case "jabatan":
          this.setState({ 
            jpStat: [], 
            dt:{
              ...this.state.dt,
              id_jabatan_perangkat: "",
            }
          });
          break;
        case "penduduk":
          this.setState({ 
            pendStat: [], 
            dt:{
              ...this.state.dt,
              nik: 0,
            }
          });
          break;
        case "status_kepegawaian":
          this.setState({ 
            statusPegawaiStat: [], 
            dt:{
              ...this.state.dt,
              status_pegawai: "",
            }
          });
          break;
        case "status_aktif":
          this.setState({ 
            statusAktifStat: [], 
            dt:{
              ...this.state.dt,
              status: 0,
            }
          });
          break;
        case "role":
          this.setState({ 
            roleStat: [], 
            dt:{
              ...this.state.dt,
              id_role: "",
            }
          });
          break;
        default:
          // no default
          break;
      }
    }
    this.setState({
      status:{
        ...this.state.status,
        select: false,
      }
    })
    this.forceUpdate();
  };
  getJabatanPerangkat = (e, self, parent, child) => {
    this.setState({
      status:{
        ...this.state.status,
        select: true,
      }
    })
    this.forceUpdate();
    this.changeSelectValue(e, self);
    if (e) {
      Get("penduduk/" + parent + "/" + e.value + "/list", null, data => {
        let frm = [];
        //console.log(data);
        data.results.forEach(dt => {
          //console.log(dt);
          frm.push({ value: dt.id, label: `${dt.nama}` });
        });

        this.setState({ 
          frmPend: frm, 
          status:{
            ...this.state.status,
            select: false,
          }
        });
        this.forceUpdate();
      });
    }
  };
  //nik

  //change nik
  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend = [];
          datanik.data.results.data.forEach(dt => {
            frmPend.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmPend });
        });
      } else {
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend2 = [];
          datanik.data.results.data.forEach(dt => {
            frmPend2.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmPend2 });
        });
      }
    }
  };

  componentDidMount(tab = "1") {
    let tabActive = tab;

    if (typeof this.props.location.tabProps !== "undefined") {
      this.setState({
        active_tab_icon: this.props.location.tabProps,
      })
      tabActive = this.props.location.tabProps.toString();
    }

    //console.log(tabActive);
    //console.log(this.state.active_tab_icon);
    //console.log(this.props.location.tabProps);

    switch (tabActive) {
      case "1":
        this.fetch({ page: null, where: [], tab: "0" });
        break;

      case "2":
        this.fetch({ page: null, where: [], tab: "1" });
        break;

      case "3":
        this.fetch({ page: null, where: [], tab: "2" });
        break;

      case "4":
        this.fetch({ page: null, where: [], tab: "3" });
        break;

      default:
        this.fetch({ page: null, where: [], tab: "0" });
        break;
    }
    this.forceUpdate();
    Get("penduduk", null, data => {
      let frm = [];
      data.results.data.forEach(dt => {
        frm.push({ value: dt.id, label: `${dt.id}` });
      });

      this.setState({ frmPend: frm });
    });
    Get("provinsi/list", null, data => {
      let frmPrv = [];
      data.results.forEach(dt => {
        frmPrv.push({ value: dt.id, label: dt.nama });
      });
      this.setState({ frmPrv });
    });
    Get("role/list", null, data => {
      let frmRole = [];
      data.results.forEach(dt => {
        if (dt.nama_role !== "Super Admin") {
          frmRole.push({ value: dt.uuid, label: dt.nama_role });
        }
      });
      let status = [
        { value: 0, label: "Belum Aktif" },
        { value: 1, label: "Sudah Aktif" }
      ];
      this.setState({ frmRole, frmStatusAktif: status });
    });
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;

    switch (this.state.active_tab_icon) {
      case "1":
        data = this.state.data;
        break;

      case "2":
        data = this.state.dataMenungguVerifikasi;
        break;

      case "3":
        data = this.state.dataVerifikasiDitolak;
        break;

      case "4":
        data = this.state.dataSudahAktif;
        break;
      default:
        data = this.state.data;
        break;
    }

    //console.log(data);

    // var data = require('../assets/json/company');

    // const frmDef = {
    //   uuid: "",
    //   username: "",
    //   password: "",
    //   nik: "",
    //   id_role: "",
    //   status: null,
    //   no_hp: null,
    //   email: null
    // };
    // const { SearchBar } = Search;
    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          headerAlign: 'center',
				  align: 'center',
          formatter: (key, obj, index) =>{ 
              let current_pagenum = '';
              let total_records_per_page = '';
              let row_index = '';
              let serial_num = '';

              if(this.state.active_tab_icon ==='1'){
                current_pagenum = this.state.pagination.current_page;
                total_records_per_page = this.state.pagination.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              else if(this.state.active_tab_icon ==='2'){
                current_pagenum = this.state.paginationMenungguVerifikasi.current_page;
                total_records_per_page = this.state.paginationMenungguVerifikasi.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              else if(this.state.active_tab_icon ==='3'){
                current_pagenum = this.state.paginationVerifikasiDitolak.current_page;
                total_records_per_page = this.state.paginationVerifikasiDitolak.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              else if(this.state.active_tab_icon ==='4'){
                current_pagenum = this.state.paginationSudahAktif.current_page;
                total_records_per_page = this.state.paginationSudahAktif.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }  
              
              return serial_num; 
          }
      },
      {
        dataField: "username",
        headerAlign: 'center',
				align: 'center',
        text: "Username",
        sort: true
      },
      {
        dataField: "nama",
        headerAlign: 'center',
				align: 'center',
        text: "Nama Lengkap",
        formatter: (cell, obj) => {
          let role = obj.data_penduduk === null ? "" : obj.data_penduduk.nama;
          return role;
        },
        isDummyField: true

        // sort: true
      },
      {
        dataField: "email",
        headerAlign: 'center',
				align: 'center',
        text: "Email"

        // sort: true
      },

      {
        dataField: "no_hp",
        headerAlign: 'center',
				align: 'center',
        text: "No Hp"

        // sort: true
      },

      {
        dataField: "role",
        headerAlign: 'center',
				align: 'center',
        text: "Role",
        formatter: (cell, obj) => {
          let role = obj.data_role.nama_role;
          return role;
        }
        // sort: true
      },
      {
        dataField: "wilayah",
        headerAlign: 'center',
				align: 'center',
        text: "Wilayah",
        formatter: (cell, obj) => {
          let daerah = obj && obj.data_penduduk && obj.data_penduduk.data_keldes ? 
            obj.data_penduduk.data_keldes.nama +
            ", " +
            obj.data_penduduk.data_keldes.data_kecamatan.nama +
            ", " +
            obj.data_penduduk.data_keldes.data_kecamatan.data_kabkota.nama +
            ", " +
            obj.data_penduduk.data_keldes.data_kecamatan.data_kabkota
            .data_provinsi.nama : ''
          return daerah;
        }
        // sort: true
      },

      {
        dataField: "status",
        headerAlign: 'center',
				align: 'center',
        text: "Status",
        formatter: (cell, obj) => {
          let status =
            obj.status === 0
              ? "Belum Verifikasi"
              : obj.status === 1
              ? "Menunggu Verifikasi"
              : obj.status === 2
              ? "Verifikasi ditolak"
              : "Sudah Aktif";

          return status;
        },
        dataSort: true
        // sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama_role",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-success";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href={() => false} onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };

    let bootstrapTable = (
      <div>
        {this.state.loading ? (
          <div className="row">
            <div className="col-sm-12" align="center">
              <img
                alt="loading"
                src={require("../assets/images/loading2.gif")}
                style={{
                  borderRadius: "10px",
                  width: '331px'
                }} />
            </div>
          </div>
        ) : data.length !== 0 ? (
          <>
            <BootstrapTable keyField="uuid" data={data} columns={columns} />
            <div className="pull-right text-white">
              {this.state.awal}
              {this.state.sebelum}
              {this.state.hal.map(dt => {
                return dt;
              })}
              {this.state.setelah}
              {this.state.akhir}
            </div>
          </>
        ) : (
          <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
        )}
      </div>
    );

    return (
      <div>
        {/* <Breadcrumb title="Verifikasi Pengguna" parent="Admin" /> */}

        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>
        {this.state.alert}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Pengguna</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span>< span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      {/* <Button
                        size="sm"
                        color="success"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Pengguna",
                            dt: frmDef,
                            pfrmPrv: [],
                            prvStat: [],
                            frmKbkt: [],
                            kbktStat: [],
                            frmKec: [],
                            kecStat: [],
                            frmKel: [],
                            kelStat: [],
                            pendStat: [],
                            frmPen: [],
                            jpStat: [],
                            frmJp: [],
                            statusAktifStat: [],
                            statusPegawaiStat: [],
                            roleStat: [],
                            statUbah: true
                          });
                          //console.log(this.state.statUbah);
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <Nav tabs className="tabs-color">
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "1"
                        })}
                        onClick={() => {
                          this.toggle_icon("1");
                        }}
                      >
                        <i className="icofont icofont-ui-home" />
                        Belum Verifikasi
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "2"
                        })}
                        onClick={() => {
                          this.toggle_icon("2");
                        }}
                      >
                        <i className="icofont icofont-man-in-glasses" />
                        Menunggu Verifikasi
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "3"
                        })}
                        onClick={() => {
                          this.toggle_icon("3");
                        }}
                      >
                        <i className="icofont icofont-man-in-glasses" />
                        Verifikasi Ditolak
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "4"
                        })}
                        onClick={() => {
                          this.toggle_icon("4");
                        }}
                      >
                        <i className="icofont icofont-man-in-glasses" />
                        Sudah Aktif
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.active_tab_icon}>
                    <TabPane tabId="1">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            {this.state.active_tab_icon === "1"
                              ? bootstrapTable
                              : ""}
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            {this.state.active_tab_icon === "2"
                              ? bootstrapTable
                              : ""}
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            {this.state.active_tab_icon === "3"
                              ? bootstrapTable
                              : ""}
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            {this.state.active_tab_icon === "4"
                              ? bootstrapTable
                              : ""}
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          size="lg"
          open={this.state.status.formVerifikasi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form">
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt.uuid}
              />

              {this.state.detailData !== null ? (
                <>
                  <input
                    className="form-control"
                    id="oldnik"
                    type="hidden"
                    placeholder="Penduduk"
                    defaultValue={this.state.detailData.id}
                  />
                  <input
                    className="form-control"
                    id="oldkk"
                    type="hidden"
                    placeholder="Penduduk"
                    defaultValue={this.state.detailData.id_kk}
                  />
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Nama Penduduk
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nama"
                        type="text"
                        placeholder="nama"
                        readOnly
                        defaultValue={this.state.detailData.nama}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      NIK
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.nik}
                      />
                    </Col>
                  </FormGroup>
                  {/* <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      No KK{" "}
                    </Label>
                    <Col sm="9">
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={e => {
                          this.changeSelectValue(e, "kk");
                        }}
                        defaultValue={this.state.kkStat}
                        value={this.state.kkStat}
                        name="No KK"
                        options={this.state.frmKk}
                        placeholder="Pilih KK"
                        onInputChange={e => this.handleInputChangeNIK(e, "kk")}
                        isClearable
                      />
                    </Col>
                  </FormGroup> */}
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      SHDK
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.data_shdk.nama}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Tempat Lahir
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.tempat_lahir}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Tanggal Lahir
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.tanggal_lahir}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Jenis Kelamin
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.jk}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Agama
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.data_agama.nama}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Nama Ayah
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.nama_ayah_kandung}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Nama Ibu
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.nama_ibu_kandung}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Alamat
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.alamat}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Pekerjaan
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.data_pekerjaan.nama}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Pendidikan Terakhir
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={
                          this.state.detailData.data_pendidikan_terakhir.nama
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Status Perkawinan
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        readOnly
                        type="text"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.status_perkawinan}
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : (
                ""
              )}

              {this.state.status.statGet === false ? (
                this.state.dataVerifikasi.length === 0 ? (
                  <Label
                    className="col-sm-12 col-form-label text-center"
                    htmlFor="inputEmail3"
                  >
                    Berkas Kosong
                  </Label>
                ) : (
                  <>
                    <div className="my-gallery card-body row">
                      {this.state.dataVerifikasi.map((pj, index) => {
                        return (
                          <figure className="col-xl-4 col-md-4 col-6">
                            <img
                              src={pj.link}
                              alt="Gallery"
                              className="img-thumbnail"
                              onClick={() =>
                                this.setState({
                                  photoIndex: index,
                                  isOpen: true
                                })
                              }
                            />
                            <Label
                              className="col-sm-12 col-form-label text-center"
                              htmlFor="inputEmail3"
                            >
                              {pj.jenis}
                            </Label>
                          </figure>
                        );
                      })}
                    </div>

                    {isOpen && (
                      <Lightbox
                        mainSrc={this.state.dataVerifikasi[photoIndex].link}
                        nextSrc={
                          this.state.dataVerifikasi[
                            (photoIndex + 1) % this.state.dataVerifikasi.length
                          ]
                        }
                        prevSrc={
                          this.state.dataVerifikasi[
                            (photoIndex +
                              this.state.dataVerifikasi.length -
                              1) %
                              this.state.dataVerifikasi.length
                          ]
                        }
                        imageTitle={
                          0 +
                          1 +
                          "/" +
                          this.state.dataVerifikasi[photoIndex].link.length
                        }
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex:
                              (photoIndex +
                                this.state.dataVerifikasi.length -
                                1) %
                              this.state.dataVerifikasi.length
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex:
                              (photoIndex + 1) %
                              this.state.dataVerifikasi.length
                          })
                        }
                      />
                    )}
                  </>
                )
              ) : (
                <>
                  <Label
                    className="col-sm-12 col-form-label text-center"
                    htmlFor="inputEmail3"
                  >
                    Menunggu data
                  </Label>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>{" "}
              {this.state.dataVerifikasi.length > 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      this.verifikasi("tolak", this.state.idVerifikasi);
                    }}
                    disabled={this.state.status.btnForm}
                  >
                    Tolak Berkas
                  </button>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      this.verifikasi("terima", this.state.idVerifikasi);
                    }}
                    disabled={this.state.status.btnForm}
                  >
                    Terima Berkas
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </Form>
        </Modal>
        <Modal
          size="lg"
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt.uuid}
              />
              {/* <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Provinsi
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, "provinsi", "prov", "kab-kota");
                    }}
                    defaultValue={this.state.prvStat}
                    value={this.state.prvStat}
                    name="provinsi"
                    options={this.state.frmPrv}
                    placeholder="Pilih Provinsi"
                    isClearable
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kabupaten / Kota
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, "kab-kota", "kab", "kecamatan");
                    }}
                    defaultValue={this.state.kbktStat}
                    value={this.state.kbktStat}
                    name="kabkota"
                    options={this.state.frmKbkt}
                    placeholder="Pilih Kabupaten/Kota"
                    isClearable
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kecamatan
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getChild(e, "kecamatan", "kec", "keldes");
                    }}
                    defaultValue={this.state.kecStat}
                    value={this.state.kecStat}
                    name="kecamatan"
                    options={this.state.frmKec}
                    placeholder="Pilih Kecamatan"
                    isClearable
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kelurahan / {JENIS_DESA}
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.getJabatanPerangkat(
                        e,
                        "keldes",
                        "kel",
                        "jabatan-perangkat"
                      );
                    }}
                    defaultValue={this.state.kelStat}
                    value={this.state.kelStat}
                    name="keldes"
                    options={this.state.frmKel}
                    placeholder="Pilih Kelurahan/Desa"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup>
               */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Role
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "role");
                    }}
                    defaultValue={this.state.roleStat}
                    value={this.state.roleStat}
                    name="role"
                    options={this.state.frmRole}
                    placeholder="Pilih Role"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup
                className="row"
                style={{ display: this.state.formPenduduk }}
              >
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Penduduk
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "penduduk");
                    }}
                    defaultValue={this.state.pendStat}
                    value={this.state.pendStat}
                    onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                    name="dusun"
                    options={this.state.frmPend}
                    placeholder="Pilih Penduduk"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Username
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="username"
                    type="text"
                    placeholder="masukan username"
                    required
                    defaultValue={this.state.dt.username}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Email
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="email"
                    type="email"
                    placeholder="masukan email"
                    required
                    defaultValue={this.state.dt.email}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Password{" "}
                  {this.state.statUbah === false
                    ? "( Silahkan di isi jika ubah password )"
                    : ""}
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="password"
                    type="password"
                    placeholder="masukan Password"
                    required={this.state.statUbah}
                    defaultValue={this.state.dt.password}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  No Hp
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nohp"
                    type="number"
                    placeholder="masukan Nomor Hp"
                    required
                    defaultValue={this.state.dt.no_hp}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Status
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "status_aktif");
                    }}
                    defaultValue={this.state.statusAktifStat}
                    value={this.state.statusAktifStat}
                    name="status"
                    options={this.state.frmStatusAktif}
                    placeholder="Pilih Status"
                    isClearable
                    required
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default Verifikasi;
