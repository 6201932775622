import React, { Component } from 'react';

// import Custom Componenets
// import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, JENIS_DESA } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "react-datetime/css/react-datetime.css";

import Datetime from "react-datetime";

//json file
// var data = require('../assets/json/company');

const frmDef = {
	uuid: '',
	tanggal: new Date(),
	keterangan: '',
};
class HariLibur extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: frmDef,
			judul: 'Hari Libur',
			// =================================
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',

			isKelurahan: false
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = '';
		// let where = [];
		// if (params.where)
		// {
		//     where = params.where;
		// }
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '?page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '?page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '?page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '?page=1';
					break;
				default:
					link = '?page=' + params.page;
					break;
			}
		}
		Get('absensi/master-hari-libur' + link, null, (dtkat) => {
			if (dtkat.results) {
				this.setState({ data: dtkat.results.data, pagination: dtkat.results });
				this.pagination(dtkat.results);
			}
		});
	};

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		//console.log(data);
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'primary-custom';
				if (i === data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						href={() => false}
						onClick={() => this.fetch({ page: i })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						href={() => false}
						onClick={() => this.fetch({ page: p })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						href={() => false}
						onClick={() => this.fetch({ page: n })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					href={() => false}
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					href={() => false}
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};
	// Cari Data Table
	cariData = (e) => {
		if (e.key === 'Enter') {
			let addTandaTangan = {
				nama: e.target.value
			};
			Post('tanda-tangan', null, addTandaTangan, (data) => {
				this.setState({ data: data.data.results.data, pagination: data.data.results });
			});
		}
	};

	// ========================================================================

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		console.log(this.state.dt);
		console.log(document.getElementById('tanggal'));
		console.log(document.getElementById('keterangan').value);

		const formData = new FormData(document.getElementById('form-data'))
		console.log(formData);

		let bodyForm = {
			uuid: document.getElementById('uuid').value,
			keterangan: document.getElementById('keterangan').value,
			tanggal: this.state.dt.tanggal,
		};

		let psn = '';
		let resstat = 204;
		let metode = 'create';
		if (bodyForm.uuid === '') {
			psn = 'Tambah';
			resstat = 201;
			bodyForm.uuid = null
			// delete bodyForm.uuid
		} else {
			psn = 'Ubah';
			resstat = 200;
			metode = 'update';
		}

		Post('absensi/master-hari-libur/' + metode, bodyForm.uuid, bodyForm, (res) => {
			this.setState({
				status: {
					...this.state.status,
					btnForm: false,
					form: false
				}
			});
			
			if (res.status === resstat) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Hari Libur',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
				this.componentDidMount();
				document.getElementById('form-data').reset()
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Hari Libur',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
		});
		
	};
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (id) => {
		this.setState({
			status: {
				...this.state.status,
				btnAksi: true
			},
			dt: {
				uuid: id.uuid,
				tanggal: new Date(id.tanggal),
				keterangan: id.keterangan,
			},
			judul: 'Ubah Hari Libur'
		});
		this.bukaForm();
	};
	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';
		Delete('absensi/master-hari-libur/delete', id, (res) => {
			if (res === 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Hari Libur',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
				this.componentDidMount();
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Hari Libur',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			// this.componentDidMount();
		});
	};
	// ========================================================================

	formPengganti = (e) => {
		e.preventDefault();
		if (this.state.frmPengganti === 'none') {
			this.setState({ frmPengganti: 'inherit' });
		} else {
			this.setState({ frmPengganti: 'none' });
		}
	};
	bukaForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: true
			}
		});
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: false
			}
		});
		this.forceUpdate();
	};

	tombolAksi = (cell, row) => {
		return (
			<div>
				<Button
					size="xs"
					color="success"
					className="btn-icon"
					onClick={() => this.ubahData(row)}
					disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-pencil" />
				</Button>{' '}
				&nbsp;&nbsp;
				<Button
					size="xs"
					color="danger"
					className="btn-icon"
					onClick={() => this.konfirmHapus(row.uuid)}
					disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-trash" />
				</Button>
			</div>
		);
	};

	componentDidMount() {
		this.fetch({ page: null, where: [] });

		if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			this.setState({ isKelurahan: true });
		}
	}

	render() {
		var data = this.state.data;
		
		const columns = [
			{
				dataField: 'no',
				text: 'No',
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: (key, obj, index) => {
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = index + 1;
					let serial_num = total_records_per_page * (current_pagenum - 1) + row_index;
					return serial_num;
				}
			},
			{
				dataField: 'tanggal',
				text: 'Tanggal',
				sort: true,
				headerAlign: 'center',
				align: 'center',
				formatter: (cell, obj) => {
          let plainTime = '-'
					if (obj.tanggal) {
						let date = new Date(obj.tanggal)
						date = date.toLocaleString('id-ID')
						
						const datetime = date.split(' ')
	
						date = datetime[0]
						// let time = datetime[1]
	
						const splitDate = date.split('/')
						// time = time.replaceAll('.', ':')
	
						const list_bulan = [
							'Januari',
							'Februari',
							'Maret',
							'April',
							'Mei',
							'Juni',
							'Juli',
							'Agustus',
							'September',
							'Oktober',
							'November',
							'Desember'
						];

						plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}`;
					}

					return plainTime
        },
			},
			{
				dataField: 'keterangan',
				text: 'Nama',
				sort: true,
				headerAlign: 'center',
			},
			// {
			// 	dataField: 'created_at',
			// 	text: 'Diinputkan pada tanggal',
			// 	sort: true,
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	formatter: (cell, obj) => {
      //     let plainTime = '-'
			// 		if (obj.created_at) {
			// 			let date = new Date(obj.created_at)
			// 			date = date.toLocaleString('id-ID')
						
			// 			const datetime = date.split(' ')
						
	
			// 			date = datetime[0]
			// 			let time = datetime[1]
	
			// 			const splitDate = date.split('/')
			// 			time = time.replaceAll('.', ':')
	
			// 			const list_bulan = [
			// 				'Januari',
			// 				'Februari',
			// 				'Maret',
			// 				'April',
			// 				'Mei',
			// 				'Juni',
			// 				'Juli',
			// 				'Agustus',
			// 				'September',
			// 				'Oktober',
			// 				'November',
			// 				'Desember'
			// 			];

			// 			plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}  ${time}`;
			// 		}

			// 		return plainTime
      //   },
			// },
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: this.tombolAksi
			}
		];

		return (
			<div>
				{/* <Breadcrumb title="Data Hari Libur" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
					style={{ maxWidth: '478px' }}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6">
											<h5>Data Hari Libur</h5>
										</div>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({
														judul: 'Tambah Hari Libur',
														dt: frmDef,
													});
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button>
										</div>
									</div>
								</div>
								<div className="card-body datatable-react">
									<BootstrapTable keyField="id" data={data} columns={columns} />
									<div className="pull-right text-white">
										{this.state.awal}
										{this.state.sebelum}
										{this.state.hal.map((dt) => {
											return dt;
										})}
										{this.state.setelah}
										{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '50%' } }}
				>
					<Form id="form-data" className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input
								className="form-control"
								id="uuid"
								type="hidden"
								defaultValue={this.state.dt.uuid}
							/>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tanggal
								</Label>
								<Col sm="9">
									<Datetime
										inputProps={{ placeholder: 'Pilih Tanggal', required: true }}
										inputId="tanggal"
										name="tanggal"
										locale="id-ID"
										timeFormat={false}
										dateFormat="YYYY/MM/DD"
										// input={true}
										closeOnTab={true}
										closeOnSelect={true}
										// initialValue={new Date()}
										initialValue={new Date()}
										value={this.state.dt.tanggal}
										onChange={e => {
											if (typeof e.isValid !== 'undefined' && e._isValid) {
												this.setState({ dt : {
													...this.state.dt,
													tanggal : e.format('YYYY/MM/DD')
												} })
											} else {
												this.setState({
													show: true,
													basicType: 'warning',
													basicTitle: 'Perhatian',
													pesanAlert: 'Isi data tanggal dengan benar',
													dt: {
														...this.state.dt, 
														tanggal: new Date()
													}
												});
											}
											// console.log(e);
											// console.log(e._isValid);
											console.log(e.format('YYYY/MM/DD'));
										}}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Keterangan
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="keterangan"
										type="text"
										placeholder="Contoh : Hari Raya Idul Fitri"
										defaultValue={this.state.dt.keterangan}
										required
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default HariLibur;
