import React, { Component } from 'react';
import { Form, FormGroup, Label, Col, Button } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Get, Delete, Post, ID_DESA, JENIS_DUSUN } from '../function/Koneksi';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import Datetime from 'react-datetime';
// import Keyboard from 'react-simple-keyboard';
// import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { Tanggal } from '../function/Format';

// import ReactDOM from 'react-dom'; // you used 'react-dom' as 'ReactDOM'
// import { FixedScaleAxis } from 'chartist';
import { Link, Redirect } from 'react-router-dom';
import dateFormat from 'dateformat';
// import KeyboardedInput from 'react-touch-screen-keyboard';
// import 'react-touch-screen-keyboard/lib/Keyboard.css'; // if you just want css
// import 'react-touch-screen-keyboard/lib/Keyboard.scss';

const id_keldes = ID_DESA;

const frmDef = {
	id: '',
	id_rt: '',
	alamat: '',
	id_keldes: id_keldes,
	idbaru: '',
	tanggal_lahirnik: new Date()
};


class LoginMasyarakat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isValidToken: true,
			layoutName: 'default',
			inputName: 'input1',
			inputName1: 'kk',
			input: {},
			inputPattern: /^\d+$/,
			display: 'none',
			focus: false,
			hasFocus: false,

			style: {},
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			data: [],
			data_res: [],
			detail: [],
			status: {
				view: false,
				form: false,
				btnForm: false,
				btnAksi: false,
				keyboard: false
			},
			datakk: {
				id: '',
				nama: '',
				tempat_lahir: '',
				nama_ibu_kandung: '',
				nama_ayah_kandung: ''
			},
			frmPrv: [],
			prvStat: null,
			frmKbkt: [],
			kbktStat: null,
			frmKec: [],
			kecStat: null,
			frmKel: [],
			kldsStat: null,
			frmKlds: [],
			skhStat: null,
			kelStat: null,
			dusStat: null,
			frmDus: [],
			frmShdk: [],
			shdkStat: null,
			frmAgm: [],
			agmStat: null,
			frmPtr: [],
			ptrStat: null,
			frmPkr: [],
			pkrStat: null,
			frmKk: [],
			kkStat: null,
			frmStp: [],
			stpStat: null,
			frmJk: [],
			jkStat: null,
			frmSpk: [],
			spkStat: null,
			agmkkStat: null,
			shdkkkStat: null,
			ptrkkStat: null,
			pkrkkStat: null,
			stpkkStat: null,
			jkkkStat: null,
			spkkkStat: null,
			status_kk: false,
			status_nik: false,
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			shdk: [],
			detailshdk: [],
			batasKK: '',
			penduduk_kk_baru: [],
			data_keluarga: [],
			diskk: false,
			tampilkk: 'inherit',
			wilayah: 'inherit',
			addForm: false,
			addMode: 'provinsi',
			addID: '',
			addNama: '',
			frmAnggota: 'none',
			dt: frmDef,
			pekerjaan: [],
			hubungan: [],

			// ============ Tanggal ==============
			startDate: new Date(),
			startDatekk: new Date(),
			tanggal_lahirnik: new Date(),
			// ===================================
			data_penduduk: '',
			statUbah: false,
			pecahKK: false,
			dataKK: {
				id: ''
			},
			tanggal_lahir: new Date(),
			tanggal_lahirkk: new Date(),
			//
			isDown: false,
			startY: null,
			scrollUp: null,

			// COMBOBOX WILAYAH
			id_dusun: '',
			dusunData: [],

			id_rw: '',
			rwData: [],

			id_rt: '',
			rtData: []
		};
		this.showKeyboard = this.showKeyboard.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
		this.simpan = this.simpan.bind(this);
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================
	handleSubmit = (event) => {
		event.preventDefault();

		if (navigator.onLine) {
			this.setState({
				status_kk: false,
				status_nik: false,
			})
			this.forceUpdate();

			let noKk = document.getElementById('no_kk').value;
			let nik = document.getElementById('nik').value;

			// //console.log('no kk : ', noKk);
			// //console.log('nik : ', nik);

			let resstat = 200;

			Get('penduduk/kios-k/cek/' + nik + '/' + noKk, null, (res) => {
				// //console.log('hasil :', res);
				// //console.log('bolean kk :', res.filter.kk);
				// //console.log('bolean nik :', res.filter.nik);

				this.setState({
					data_res: {
						data: res,
						role: 'login'
					}
				});

				if (res.code === resstat) {
					this.props.history.push({
						pathname: '/home-masyarakat',
						state: this.state.data_res
					});
				} else if (res.filter.kk === false) {
					this.setState({
						status_kk: true,
					})
					this.forceUpdate();
				} else if (res.filter.nik === false) {
					// this.state.noKk = noKk;
					this.setState({
						status_nik: true,
					})
					this.forceUpdate();
				}
			});
		} else {
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Login',
				pesanAlert: 'Jaringan internet tidak aktif...'
			});
		}
	};

	logOut(e) {
		e.preventDefault();
		sessionStorage.removeItem('access_token');
		this.setState({ isValidToken: false });
	}

	componentDidMount() {
		Get('keldes/find/' + ID_DESA, null, (data) => {
			// //console.log("DATA DESA")
			// //console.log(data)

			if (data.results === null) {
				this.logOut.bind(this);
			} else {
				this.setState({
					data: {
						id_provnya: data.results.data_kecamatan.data_kabkota.data_provinsi.id,
						id_kabnya: data.results.data_kecamatan.data_kabkota.id,
						id_kecnya: data.results.data_kecamatan.id,
						id_desnya: data.results.id,
						nama_desanya: data.results.nama
					}
				});
			}
		});

		if (localStorage.getItem('JENIS_DESA').toLowerCase() !== 'kelurahan') {
			Get('dusun/kel/' + ID_DESA, null, (data) => {
				// //console.log("DATA dUSUN")
				if (data.results === null) {
					this.logOut.bind(this);
				} else {
					//console.log(data);
					this.setState({
						dusunData: data.results.data
					});
				}
			});
			Get('dusun/kel/' + ID_DESA + '/list', null, (data) => {
				let frmDus = [];
				if (data.results === null) {
					this.logOut.bind(this);
				} else {
					data.results.forEach((dt) => {
						frmDus.push({ value: dt.id, label: dt.nama });
					});
					this.setState({ frmDus });
				}
			});
		}

		Get('shdk/list', null, (data) => {
			let frmShdk = [];
			if (data.results === null) {
				this.logOut.bind(this);
			} else {
				data.results.forEach((dt) => {
					if (dt.uuid !== 'fbbc9dda-f721-49ac-a611-430269d83cc2') {
						frmShdk.push({ value: dt.uuid, label: dt.nama });
					}
				});
				this.setState({ frmShdk });
			}
		});
		Get('agama/list', null, (data) => {
			let frmAgm = [];
			if (data.results === null) {
				this.logOut.bind(this);
			} else {
				data.results.forEach((dt) => {
					frmAgm.push({ value: dt.uuid, label: dt.nama });
				});
				this.setState({ frmAgm });
			}
		});
		Get('jenjang-pendidikan/list', null, (data) => {
			let frmPtr = [];
			if (data.results === null) {
				this.logOut.bind(this);
			} else {
				data.results.forEach((dt) => {
					frmPtr.push({ value: dt.uuid, label: dt.nama });
				});
				this.setState({ frmPtr });
			}
		});
		Get('pekerjaan/list', null, (data) => {
			let frmPkr = [];
			if (data.results === null) {
				this.logOut.bind(this);
			} else {
				data.results.forEach((dt) => {
					frmPkr.push({ value: dt.uuid, label: dt.nama });
				});
				this.setState({ frmPkr });
			}
		});
		Get('select-enum/penduduk/status_penduduk', null, (data) => {
			let frmStp = [];
			if (data.results === null) {
				this.logOut.bind(this);
			} else {
				data.forEach((dt) => {
					frmStp.push({ value: dt, label: dt });
				});
				this.setState({ frmStp });
			}
		});
		Get('select-enum/penduduk/jk', null, (data) => {
			let frmJk = [];
			if (data.results === null) {
				this.logOut.bind(this);
			} else {
				data.forEach((dt) => {
					frmJk.push({ value: dt, label: dt });
				});
				this.setState({ frmJk });
			}
		});
		Get('select-enum/penduduk/status_perkawinan', null, (data) => {
			let frmSpk = [];
			if (data.results === null) {
				this.logOut.bind(this);
			} else {
				data.forEach((dt) => {
					frmSpk.push({ value: dt, label: dt });
				});
				this.setState({ frmSpk });
			}
		});

		this.setState({
			frmSbk: [ { value: 0, label: 'Belum Verifikasi' }, { value: 1, label: 'Sudah Verifikasi' } ],
			statusberkas: [ 'Belum Verifikasi', 'Sudah Verifikasi' ],
			frmSkh: [ { value: 1, label: 'Hidup' }, { value: 0, label: 'Meninggal' } ]
		});

		setTimeout(
			function() {
				this.setState({ style: { display: 'none' } });
			}.bind(this),
			1000
		);
	}

	onOpenModal = (key) => {
		//console.log(key);
		// this.setState({ [key]: true });
		this.setState({
			[key]: true,
			regis: true,
			status_kk: 'false'
		});
	};

	onCloseModal = (key) => {
		this.setState({ [key]: false });
		this.setState({
			display: 'none',
			regis: 'false'
		});
	};

	handleClick = (event) => {
		alert(event);
	};

	formAnggota = (e) => {
		e.preventDefault();
		// if (this.state.id_rt)
		// {
		this.setState({
			regis: false,
			anggota: true,
			status:{
				...this.state.status,
				anggota: true,
			}
		});
		this.forceUpdate();
		// }
		// else
		// {
		//   this.setState({
		// 	show: true,
		// 	basicType: "info",
		// 	basicTitle: "Anggota Keluarga",
		// 	pesanAlert: "Data Kelurahan/" + JENIS_DESA + " harus dipilih",
		//   });
		// }
	};

	tutupAnggota = (e) => {
		this.setState({
			// input:{},
			shdkStat: null,
			agmStat: null,
			pkrStat: null,
			ptrStat: null,
			spkStat: null,
			stpStat: null,
			tanggal_lahir: '',
			status:{
				...this.state.status,
				anggota: false,
			}
		})
		// this.state.input['nik_keluarga'] = '';
		// this.state.input['nama_keluarga'] = '';
		// this.state.input['tempat_lahir_keluarga'] = '';
		// this.state.input['nama_ibu_kandung_keluarga'] = '';
		// this.state.input['nama_ayah_kandung_keluarga'] = '';
		this.setState({
			anggota: false
		});
		this.forceUpdate();
	};

	tambahAnggota = (e) => {
		e.preventDefault();

		let nik = document.getElementById('nik_keluarga').value;

		if (nik.toString().length !== 16) {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Tambah Anggota Keluarga',
				pesanAlert: 'NIK anggota keluarga tidak terdiri dari 16 angka'
			});
		} else if (
			this.state.shdkStat &&
			this.state.agmStat &&
			this.state.pkrStat &&
			this.state.ptrStat &&
			this.state.spkStat &&
			this.state.stpStat &&
			this.state.jkStat
		) {
			// //console.log('data nik', document.getElementById('nik_keluarga').value);
			let detail = this.state.detail;
			let hubungan = this.state.hubungan;
			let pekerjaan = this.state.pekerjaan;
			pekerjaan[this.state.pkrStat.value] = this.state.pkrStat.label;
			hubungan[this.state.shdkStat.value] = this.state.shdkStat.label;
			detail.push({
				id: document.getElementById('nik_keluarga').value,
				id_kk: document.getElementById('no_kk_kepala').value,
				id_keldes: ID_DESA,
				nama: document.getElementById('nama_keluarga').value,
				alamat: document.getElementById('alamat_kepala').value,
				tempat_lahir: document.getElementById('tempat_lahir_keluarga').value,
				tanggal_lahir: this.state.tanggal_lahir,
				jk: this.state.dt.jk,
				shdk: this.state.dt.shdk,
				agama: this.state.dt.agama,
				status_perkawinan: this.state.dt.status_perkawinan,
				pendidikan_terakhir: this.state.dt.pendidikan_terakhir,
				pekerjaan: this.state.dt.pekerjaan,
				nama_ibu_kandung: document.getElementById('nama_ibu_kandung_keluarga').value,
				nama_ayah_kandung: document.getElementById('nama_ayah_kandung_keluarga').value,
				status_penduduk: this.state.dt.status_penduduk
			});

			this.setState({
				detail,
				pekerjaan,
				hubungan,
				jkStat: null,
				shdkStat: null,
				agmStat: null,
				spkStat: null,
				stpStat: null,
				pkrStat: null,
				ptrStat: null,
				tanggal_lahir: new Date(),
				status: { anggota: false },
				// input:[
				// 	{'nik_keluarga':''},
				// 	{'nama_keluarga':''},
				// 	{'tempat_lahir_keluarga':''},
				// 	{'nama_ibu_kandung_keluarga':''},
				// 	{'nama_ayah_kandung_keluarga':''},
				// ],
				// input:{['nama_keluarga'] = '';
				// input:{['tempat_lahir_keluarga'] = '';
				// input:{['nama_ibu_kandung_keluarga'] = '';
				// input:{['nama_ayah_kandung_keluarga'] = '';
			});
			document.getElementById('nik_keluarga').value = '';
			document.getElementById('nama_keluarga').value = '';
			document.getElementById('tempat_lahir_keluarga').value = '';
			document.getElementById('nama_ibu_kandung_keluarga').value = '';
			document.getElementById('nama_ayah_kandung_keluarga').value = '';
			// this.setState({
			// 	input:{},
			// })
			this.forceUpdate();
		} else {
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Anggota Keluarga',
				pesanAlert:
					'Data belum lengkap. Pastikan data SHDK, Agama, Pendidikan, Pekerjaan, Status Perkawinan, Status Penduduk telah dipilih'
			});
		}
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';
		Delete('kartu-keluarga/delete', id, (res) => {
			if (res === 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Kartu Keluarga',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Kartu Keluarga',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount();
		});
	};

	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapus = (i) => {
		this.state.detail.splice(i, 1);
		this.forceUpdate();
	};

	getChild = (e, self, parent, child) => {
		this.setState({
			status:{
				...this.state.status,
				select: true,
			}
		})
		this.forceUpdate();
		this.changeSelectValue(e, self);
		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.forEach((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota':
						this.setState({ frmKbkt: frm, frmKec: [] });
						break;
					case 'kecamatan':
						this.setState({ frmKec: frm });
						break;
					case 'keldes':
						this.setState({ frmKel: frm });
						break;
					case 'dusun':
						this.setState({ frmDus: frm });
						break;

					case 'rw':
						this.setState({ frmRw: frm });
						break;

					case 'rt':
						this.setState({ frmRt: frm });
						break;
					default:
						// no default
						break;
				}
				this.setState({
					status:{
						...this.state.status,
						select: false,
					}
				})
				this.forceUpdate();
			});
		}
	};

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: e });
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null });
					break;
				case 'kab-kota':
					this.setState({ kbktStat: e });
					this.setState({ kecStat: null });
					break;
				case 'kecamatan':
					this.setState({ kecStat: e });
					this.setState({ kelStat: null });

					break;
				case 'keldes':
					this.setState({ kelStat: e });
					this.setState({ dusStat: null });

					break;

				case 'dusun':
					this.setState({ dusStat: e });
					this.setState({ rwStat: null });

					break;

				case 'rw':
					this.setState({ rwStat: e });
					this.setState({ rtStat: null });

					break;

				case 'rt':
					this.setState({ 
						rtStat: e, 
						dt:{
							...this.state.dt,
							id_rt: e.value,
						}
					});
					break;
				case 'ukuran_kertas':
					this.setState({ 
						ukkStat: e, 
						dt:{
							...this.state.dt,
							id_ukuran_kertas: e.value,
						}
					});
					break;

				case 'shdk':
					this.setState({ 
						shdkStat: e, 
						dt:{
							...this.state.dt,
							shdk: e.value,
						}
					});
					break;
				case 'agama':
					this.setState({ 
						agmStat: e, 
						dt:{
							...this.state.dt,
							agama: e.value,
						}
					});
					break;
				case 'pendidikan-terakhir':
					this.setState({ 
						ptrStat: e, 
						dt:{
							...this.state.dt,
							pendidikan_terakhir: e.value,
						}
					});
					break;
				case 'pekerjaan':
					this.setState({ 
						pkrStat: e, 
						dt:{
							...this.state.dt,
							pekerjaan: e.value,
						}
					});
					break;
				case 'kk':
					this.setState({ 
						kkStat: e, 
						dt:{
							...this.state.dt,
							id_kk: e.value,
						}
					});
					break;
				case 'status-penduduk':
					this.setState({ 
						stpStat: e, 
						status_penduduk: e.value,
					});
					break;
				case 'jk':
					this.setState({ 
						jkStat: e, 
						dt:{
							...this.state.dt,
							jk: e.value,
						}
					});
					break;
				case 'status-perkawinan':
					this.setState({ 
						spkStat: e, 
						dt:{
							...this.state.dt,
							status_perkawinan: e.value,
						}
					});
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'SHDKKK':
					this.setState({ shdkkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;
				case 'status-kehidupan':
					this.setState({ skhStat: e });
					break;	
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case 'provinsi':
					this.setState({
						kbktStat: null, 
						frmKbkt: [],
						prvStat: null,
						kecStat: null, 
						frmKec: [],
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null,
						dt:{
							...this.state.dt,
							id_rt: 0,
						}
					});
					break;
				case 'kab-kota':
					this.setState({
						kbktStat: null,
						kecStat: null, 
						frmKec: [],
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null,
						dt:{
							...this.state.dt,
							id_rt: 0,
						}
					});

					break;
				case 'kecamatan':
					this.setState({
						kecStat: null, 
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null,
						dt:{
							...this.state.dt,
							id_rt: 0,
						}
					});

					break;
				case 'keldes':
					this.setState({
						kelStat: null,
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null,
						dt:{
							...this.state.dt,
							id_rt: 0,
						}
					});
					break;
				case 'dusun':
					this.setState({ 
						dusStat: null, 
						frmrw: [], 
						rwStat: null, 
						frmRt: [], 
						rtStat: null, 
						dt:{
							...this.state.dt,
							id_rt: 0,
						}
					});
					break;
				case 'rw':
					this.setState({ 
						rwStat: null, 
						frmRt: [], 
						rtStat: null, 
						dt:{
							...this.state.dt,
							id_rt: 0,
						}
					});
					break;
				case 'rt':
					this.setState({ 
						rtStat: null, 
						dt:{
							...this.state.dt,
							id_rt: 0,
						}
					});
					break;
				case 'shdk':
					this.setState({ 
						shdkStat: null, 
						dt:{
							...this.state.dt,
							shdk: 0,
						}
					});
					break;
				case 'agama':
					this.setState({ 
						agmStat: null, 
						dt:{
							...this.state.dt,
							agama: 0,
						}
					});
					break;
				case 'pendidikan-terakhir':
					this.setState({ 
						ptrStat: null, 
						dt:{
							...this.state.dt,
							pendidikan_terakhir: 0,
						}
					});
					break;
				case 'pekerjaan':
					this.setState({ 
						pkrStat: null, 
						dt:{
							...this.state.dt,
							pekerjaan: 0,
						}
					});
					break;
				case 'kk':
					this.setState({ 
						kkStat: null, 
						dt:{
							...this.state.dt,
							id_kk: 0,
						}
					});
					break;
				case 'status-penduduk':
					this.setState({ 
						stpStat: e, 
						dt:{
							...this.state.dt,
							status_penduduk: 0,
						}
					});
					break;
				case 'jk':
					this.setState({ 
						jkStat: e, 
						dt:{
							...this.state.dt,
							jk: 0,
						}
					});
					break;
				case 'status-perkawinan':
					this.setState({ 
						spkStat: e, 
						dt:{
							...this.state.dt,
							status_perkawinan: '',
						}
					});
					break;
				case 'jenis-berkas':
					this.setState({ jbkStat: e });
					break;
				case 'status-berkas':
					this.setState({ sbkStat: e });
					break;
				case 'agamakk':
					this.setState({ agmkkStat: e });
					break;
				case 'SHDKKK':
					this.setState({ shdkkkStat: e });
					break;
				case 'pendidikan-terakhirkk':
					this.setState({ ptrkkStat: e });
					break;
				case 'pekerjaankk':
					this.setState({ pkrkkStat: e });
					break;
				case 'status-pendudukkk':
					this.setState({ stpkkStat: e });
					break;
				case 'jkkk':
					this.setState({ jkkkStat: e });
					break;
				case 'status-perkawinankk':
					this.setState({ spkkkStat: e });
					break;
				case 'status-kehidupan':
					this.setState({ skhStat: e });
					break;
				default:
					// no default
					break;
			}
		}
		this.setState({
			status:{
				...this.state.status,
				select: false,
			}
		})
		this.forceUpdate();
	};

	ambilPenduduk = (e, nik, kk, idx) => {
		e.preventDefault();
		let data_penduduk = '';
		let penduduk_kk_baru = this.state.penduduk_kk_baru;
		let dt = this.state.data_keluarga[idx];
		data_penduduk = this.state.data_penduduk + ';' + nik + '-' + kk;
		penduduk_kk_baru.push(dt);
		this.setState({
			datakk:{
				...this.state.datakk,
				id: dt.id,
				nama: dt.nama,
				tempat_lahir: dt.tempat_lahir,
				nama_ibu_kandung: dt.nama_ibu_kandung,
				nama_ayah_kandung: dt.nama_ayah_kandung,
			},
			agmkkStat: { value: dt.data_agama.uuid, label: dt.data_agama.nama },
			pkrkkStat: { value: dt.data_pekerjaan.uuid, label: dt.data_pekerjaan.nama },
			ptrkkStat: { value: dt.data_pendidikan_terakhir.uuid, label: dt.data_pendidikan_terakhir.nama },
			jkkkStat: { value: dt.jk, label: dt.jk },
			startDatekk: new Date(dt.tanggal_lahir),
			spkkkStat: { value: dt.status_perkawinan, label: dt.status_perkawinan },
			stpkkStat: { value: dt.status_penduduk, label: dt.status_penduduk },
		})
		// this.state.shdkkkStat = {value:dt.data_agama.uuid, label:dt.data_agama.nama};
		this.state.data_keluarga.splice(idx, 1);
		this.forceUpdate();
		if (Object.keys(this.state.data_keluarga).length < 2) {
			this.setState({ batasKK: ' disabled' });
		} else {
			this.setState({ batasKK: '' });
		}
		this.setState({ data_penduduk, penduduk_kk_baru });
	};

	tutupForm = () => {
		this.setState({
			status:{
				...this.state.status,
				form: false,
			},
			agmkkStat: null,
			pkrkkStat: null,
			ptrkkStat: null,
			spkkkStat: null,
			stpkkStat: null,
			jkkkStat: null,
		})
		this.forceUpdate();
	};

	bukaForm = () => {
		this.setState({
			// [key]: true,
			regis: true,
			status:{
				form: true,
			}
		});
		this.forceUpdate();
	};

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}

		this.forceUpdate();
	};

	showKeyboard1 = () => {
		this.setState({
			display: 'none',
			regis: 'false'
		});
		this.forceUpdate();
	};

	showKeyboard2 = () => {
		this.setState({
			display: 'none',
			regis: 'false'
		});
		this.forceUpdate();
	};

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld === 'nik') {
				let nikStat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmNik = [];
					datanik.data.results.data.forEach((dt) => {
						frmNik.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmNik, nikStat });
				});
			} else {
				if (fld === 'kk') {
					let kkStat = { value: e, label: e };
					let param = {
						id: e
					};
					Post('kartu-keluarga', null, param, (datakk) => {
						let frmKk = [];
						datakk.data.results.data.forEach((dt) => {
							frmKk.push({ value: dt.id, label: dt.id });
						});
						this.setState({ frmKk, kkStat });
					});
				}
			}
		}
	};

	simpan = (e, mode) => {
		e.preventDefault();
		let noKk = document.getElementById('no_kk_kepala').value;
		let nik = document.getElementById('nik_kepala').value;

		if (noKk.toString().length !== 16) {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Registrasi Kartu Keluarga',
				pesanAlert: 'Nomor KK tidak terdiri dari 16 angka'
			});
		} else if (nik.toString().length !== 16) {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Registrasi Kartu Keluarga',
				pesanAlert: 'NIK Kepala Keluarga tidak terdiri dari 16 angka'
			});
		} else if (
			this.state.agmkkStat &&
			this.state.pkrkkStat &&
			this.state.ptrkkStat &&
			this.state.spkkkStat &&
			this.state.stpkkStat &&
			this.state.rtStat &&
			this.state.rwStat &&
			this.state.jkkkStat
		) {
			let kepala = {
				id: document.getElementById('nik_kepala').value,
				id_kk: document.getElementById('no_kk_kepala').value,
				id_keldes: ID_DESA,
				nama: document.getElementById('nama_kepala').value,
				alamat: document.getElementById('alamat_kepala').value,
				tempat_lahir: document.getElementById('tempat_lahir_kepala').value,
				// tanggal_lahir: this.state.tanggal_lahirkk,
				tanggal_lahir: dateFormat(this.state.tanggal_lahirkk, 'yyyy-mm-dd'),
				jk: this.state.jkkkStat.value,
				shdk: this.state.shdkkkStat.value,
				agama: this.state.agmkkStat.value,
				status_perkawinan: this.state.spkkkStat.value,
				pendidikan_terakhir: this.state.ptrkkStat.value,
				pekerjaan: this.state.pkrkkStat.value,
				nama_ibu_kandung: document.getElementById('nama_ibu_kandung_kepala').value,
				nama_ayah_kandung: document.getElementById('nama_ayah_kandung_kepala').value,
				status_penduduk: this.state.stpkkStat.value
			};

			this.setState({
				status:{
					...this.state.status,
					btnForm: true,
				},
			})
			this.state.detail.push(kepala);
			this.forceUpdate();

			let add = {
				id: document.getElementById('no_kk_kepala').value,
				// id_rt: this.state.dt.id_rt,
				id_rt: this.state.rtStat.value,
				id_desa: ID_DESA,
				alamat: document.getElementById('alamat_kepala').value,
				detail: this.state.detail
			};

			let psn = '';
			let resstat = 201;
			let metode = 'create';

			if (this.state.detail) {
				metode = 'create/with-detail';
				if (this.state.pecahKK) {
					metode = 'create';
					resstat = 201;
				}
			} else {
				delete add.detail;
			}
			//   if (id === 0) {
			// 	psn = "Tambah";
			// 	resstat = 201;
			// 	id = null;
			//   } else {
			// 	psn = "Ubah";
			// 	resstat = 200;
			// 	metode = "update";
			// 	delete add.detail;
			//   }

			//console.log(metode);
			//console.log(add);

			Post('temp/kartu-keluarga/' + metode, null, add, (resp) => {
				console.log("Data Sementara Tambah KK")
				this.setState({
					data_res: {
						respon: resp.status,
						data: { nik: resp.data.results.id, no_kk: resp.data.results.id_kk },
						role: 'tambah'
					}
				});

				if (this.state.data_res.respon === resstat) {
					this.props.history.push({
						pathname: '/home-masyarakat',
						state: this.state.data_res
					});
					this.tutupForm();
					this.onCloseModal('Basic');
					this.setState({
						status_kk: false,
						status_nik: false,
					})
				} else {
					this.setState({
						status:{
							...this.state.status,
							btnForm: false,
						},
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Kartu Keluarga',
						pesanAlert: 'Gagal ' + psn + ' Data'
					});
					this.forceUpdate();
					this.componentDidMount();
				}
			});
		} else {
			//   let luar = "";
			//   if (this.state.wilayah === "inherit")
			//   {
			// 	luar = "RT, ";
			//   }
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Data Kartu Keluarga',
				pesanAlert:
					'Data belum lengkap. Mohon Pastikan data Agama, Pendidikan Terakhir, Pekerjaan, Status Perkawinan, Status Penduduk dan Jenis Kelamin sudah dipilih'
			});
		}
	};

	gantiTgl = (e, kk) => {
		if (kk === 'kk') {
			if (typeof e._isValid !== 'undefined') {
				this.setState({
					tanggal_lahirkk: new Date(e),
				})
				this.forceUpdate();
			}
		} else if (kk === 'tanggal_lahir') {
			if (typeof e._isValid !== 'undefined') {
				this.setState({
					tanggal_lahir: new Date(e),
				})
				this.forceUpdate();
			}
		} else if (kk === 'tgl_lahir_nik') {
			if (typeof e._isValid !== 'undefined') {
				this.setState({
					tanggal_lahirnik: new Date(e),
				})
				this.forceUpdate();
			}
		}
	};

	simpanNik = (e) => {
		e.preventDefault();

		const nik = document.getElementById('idbaru').value;
		const noKk = document.getElementById('nikNoKK').value;

		if (nik.toString().length !== 16) {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Registrasi NIK',
				pesanAlert: 'NIK tidak terdiri dari 16 angka'
			});
		} else if (noKk.toString().length !== 16) {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Registrasi NIK',
				pesanAlert: 'Nomor KK tidak terdiri dari 16 angka'
			});
		} else if (
			this.state.shdkStat &&
			this.state.agmStat &&
			this.state.pkrStat &&
			this.state.ptrStat &&
			this.state.spkStat &&
			this.state.jkStat
		) {
			this.setState({
				status:{
					...this.state.status,
					btnForm: true,
				}
			})
			this.forceUpdate();

			let addPenduduk = {
				id: document.getElementById('idbaru').value,
				// id_kk: this.state.dt.id_kk,
				id_kk: document.getElementById('nikNoKK').value,
				id_keldes: ID_DESA,
				nama: document.getElementById('nama').value,
				alamat: document.getElementById('alamat').value,
				tempat_lahir: document.getElementById('tempat_lahir').value,
				tanggal_lahir: dateFormat(this.state.tanggal_lahirnik, 'yyyy-mm-dd'),
				jk: this.state.jkStat.value,
				shdk: this.state.shdkStat.value,
				agama: this.state.agmStat.value,
				status_perkawinan: this.state.spkStat.value,
				// status_kehidupan: this.state.skhStat.value,
				pendidikan_terakhir: this.state.ptrStat.value,
				pekerjaan: this.state.pkrStat.value,
				nama_ibu_kandung: document.getElementById('nama_ibu_kandung').value,
				nama_ayah_kandung: document.getElementById('nama_ayah_kandung').value
				// status_penduduk: this.state.stpStat.value,
			};
			let psn = 'Tambah';
			let resstat = 201;
			let metode = 'create';

			Post('temp/penduduk/' + metode, null, addPenduduk, (res) => {
				//console.log(res);
				this.setState({
					status:{
						...this.state.status,
						btnForm: false,
					}
				})

				if (res.data.code === resstat) {
					this.setState({
						data_res: {
							respon: res.status,
							data: { nik: res.data.results.id, no_kk: res.data.results.id_kk },
							role: 'tambah'
						}
					});

					this.setState({
						status:{
							...this.state.status,
							form: false,
						},
						status_kk: false,
						status_nik: false,
					})
					this.props.history.push({
						pathname: '/home-masyarakat',
						state: this.state.data_res
					});
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Penduduk',
						pesanAlert: 'Berhasil ' + psn + ' Data'
					});
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Penduduk',
						pesanAlert: 'Gagal ' + psn + ' Data'
					});
				}
				this.forceUpdate();
				this.componentDidMount();
			});
		} else {
			// let luar = "";
			// if (this.state.wilayah === "inherit") {
			// 		luar = "Kelurahan/Desa, ";
			// }
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Registrasi NIK',
				pesanAlert:
					'Data belum lengkap. Mohon Pastikan data SHDK, Agama, Pendidikan Terakhir, Pekerjaan, Status Perkawinan, Status Penduduk dan Jenis Kelamin sudah dipilih'
			});
		}
	};

	maxLengthCheck = (object) => {
		if (object.target.value.length > object.target.maxLength) {
			object.target.value = object.target.value.slice(0, object.target.maxLength);
		}
	};

	onChange = (input) => {
		this.setState({ input });
		//console.log('Input changed', input);
	};

	onKeyPress = (button) => {
		//console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		//console.log(inputVal);

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				}
			}
		);
	};
	onChangeInput1 = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				this.keyboard.setInput(inputVal);
			}
		);
	};

	onInputChanged = (data) => {
		this.setState({ input: data });
	};

	onInputSubmitted = (data) => {
		//console.log('Input submitted:', data);
	};

	onChangeAll = (inputObj) => {
		this.setState({
			input: inputObj
		});

		//console.log('Input changed', inputObj);
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				//console.log('Active input', inputName);
			}
		);
	};

	setActiveInput1 = (inputName1) => {
		this.setState(
			{
				inputName1: inputName1
			},
			() => {
				//console.log('Active input', inputName1);
			}
		);
	};

	// =================== Combo box Wilayah ===================
	ChangeKeldes = (e) => {
		if (e) {
			this.setState({ id_dusun: e.target.value });

			if (e.target.value === '') {
				this.setState({
					dusunData: [],
					id_dusun: ''
				});
			} else {
				let idnya = e.target.value;
				Get('rw/kel/' + e.target.value, null, (data) => {
					// //console.log("Data RW")
					// //console.log(data)
					this.setState({
						rwData: data.result,
						id_dusun: idnya
					});
				});
			}
		} else {
			this.setState({ id_dusun: '' });
		}
	};

	ChangeRw = (e) => {
		if (e) {
			this.setState({ id_rw: e.target.value });

			if (e.target.value === '') {
				this.setState({
					rwData: [],
					id_rw: ''
				});
			} else {
				let idnya = e.target.value;
				Get('rw/kel/' + e.target.value, null, (data) => {
					// //console.log("Data RW")
					// //console.log(data)
					this.setState({
						rwData: data.result,
						id_rw: idnya
					});
				});
			}
		} else {
			this.setState({ id_rw: '' });
		}
	};
	// =================== End Combo box Wilayah ===================

	render() {
		let style = this.state.style;
		// const background = require('../assets/images/auth-layer.png');
		// const { redirect } = this.state;
		const { Basic, isValidToken } = this.state;

		if (!isValidToken) {
			return <Redirect to="/" push={true} />;
		}

		const penduduk_kk = this.state.data_keluarga.map((dt, i) => {
			return (
				<tr key={dt.id}>
					<td style={{ padding: '5px 5px 5px 5px' }}>
						<a
							href={() => false}
							className={'btn btn-success btn-xs' + this.state.batasKK}
							onClick={(e) => this.ambilPenduduk(e, dt.id, dt.id_kk, i)}
						>
							Pilih
						</a>
					</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>{dt.id}</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>{dt.nama}</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>
						{dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
					</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>{dt.data_shdk.nama}</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>
						<select name="shdk_baru" onChange={(e) => this.gantiShdkBaru(e, dt)} className="form-control">
							<option value="">Pilih SHDK Baru</option>
							<option value="fbbc9dda-f721-49ac-a611-430269d83cc2">Kepala Keluarga</option>
							{this.state.frmShdk.map((dtsh) => <option value={dtsh.value}>{dtsh.label}</option>)}
						</select>
					</td>
				</tr>
			);
		});
		const penduduk_kk_baru = this.state.penduduk_kk_baru.map((dt, i) => {
			return (
				<tr key={dt.id}>
					<td style={{ padding: '5px 5px 5px 5px' }}>
						<a
							href={() => false}
							className="btn btn-danger btn-xs"
							onClick={(e) => this.hapusPenduduk(e, dt.id, dt.id_kk, i)}
						>
							Batal
						</a>
					</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>{dt.id}</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>{dt.nama}</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>
						{dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
					</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>{dt.data_shdk.nama}</td>
					<td style={{ padding: '5px 5px 5px 5px' }}>
						<select name="shdk_baru" onChange={(e) => this.gantiShdkBaru(e, dt)} className="form-control">
							<option value="">Pilih SHDK Baru</option>
							<option value="fbbc9dda-f721-49ac-a611-430269d83cc2">Kepala Keluarga</option>
							{this.state.frmShdk.map((dtsh) => <option value={dtsh.value}>{dtsh.label}</option>)}
						</select>
					</td>
				</tr>
			);
		});

		let pilihPenduduk = '';
		if (this.state.pecahKK) {
			pilihPenduduk = (
				<FormGroup className="row">
					<Label className="col-sm-12 col-form-label" htmlFor="inputEmail3">
						<b>Pilih Penduduk</b>
					</Label>
					<Label className="col-sm-12 col-form-label" htmlFor="inputEmail3">
						KK Lama
					</Label>
					<Col sm="12">
						<table style={{ width: '100%' }} border="1" cellPadding="0" cellSpacing="0">
							<thead>
								<tr>
									<th style={{ padding: '5px 5px 5px 5px' }}>Pilih</th>
									<th style={{ padding: '5px 5px 5px 5px' }}>NIK</th>
									<th style={{ padding: '5px 5px 5px 5px' }}>Nama</th>
									<th style={{ padding: '5px 5px 5px 5px' }}>TTL</th>
									<th style={{ padding: '5px 5px 5px 5px' }}>SHDK</th>
									<th style={{ padding: '5px 5px 5px 5px' }}>SHDK Baru</th>
								</tr>
							</thead>
							<tbody>{penduduk_kk}</tbody>
						</table>
					</Col>
					<Label className="col-sm-12 col-form-label" htmlFor="inputEmail3">
						KK Baru
					</Label>
					<Col sm="12">
						<table style={{ width: '100%' }} border="1" cellPadding="0" cellSpacing="0">
							<thead>
								<tr>
									<th style={{ padding: '5px 5px 5px 5px' }}>Batal</th>
									<th style={{ padding: '5px 5px 5px 5px' }}>NIK</th>
									<th style={{ padding: '5px 5px 5px 5px' }}>Nama</th>
									<th style={{ padding: '5px 5px 5px 5px' }}>TTL</th>
									<th style={{ padding: '5px 5px 5px 5px' }}>SHDK</th>
									<th style={{ padding: '5px 5px 5px 5px' }}>SHDK Baru</th>
								</tr>
							</thead>
							<tbody>{penduduk_kk_baru}</tbody>
						</table>
					</Col>
				</FormGroup>
			);
		}

		const input_anggota = this.state.detail.map((dt, i) => {
			return (
				<tr>
					<td>{i + 1}</td>
					<td>{dt.id}</td>
					<td>{dt.nama}</td>
					<td>
						{dt.tempat_lahir}, {Tanggal(new Date(dt.tanggal_lahir))}
					</td>
					<td>{this.state.pekerjaan[dt.pekerjaan]}</td>
					<td>{this.state.hubungan[dt.shdk]}</td>
					<td>
						<Button size="xs" onClick={() => this.hapus(i)} color="danger" className="btn-icon">
							<i className="fa fa-trash" />
						</Button>
					</td>
				</tr>
			);
		});

		// const frmDef = {
		// 	id: '',
		// 	id_rt: 0,
		// 	alamat: ''
		// };

		let logoAplikasi = (
			<img
				src={require('../assets/images/desata.png')}
				alt=""
				style={{ maxHeight: '100px' }}
			/>
    )

    switch (localStorage.getItem('JENIS_APLIKASI').toLowerCase()) {
		case 'halaman kampung':
			logoAplikasi = (
						<img
							src={require('../assets/images/hk_icon.png')}
							alt=""
							style={{ maxHeight: '100px' }}
						/>
			)
			break;
		case 'amanat nagari':
			logoAplikasi = (
						<img
							src={require('../assets/images/amanat_nagari_logo.png')}
							alt=""
							style={{ maxHeight: '100px' }}
						/>
			)
        	break;
		default:
			logoAplikasi = (
				<img
					src={require('../assets/images/desata.png')}
					alt=""
					style={{ maxHeight: '100px' }}
				/>
			)
			break;
    }

		return (
			<div>
				<div className="loader-wrapper" style={style}>
					<div className="loader bg-white">
						<div className="line" />
						<div className="line" />
						<div className="line" />
						<div className="line" />
						{/* <h4>
							Have a great day at work today <span>&#x263A;</span>
						</h4> */}
					</div>
				</div>

				<div className="page-wrapper">
					<div className="container-fluid ">
						{/*login page start*/}
						<div className="authentication-main " style={{ background: 'none' }}>
							<div className="row ">
								<div className="col-md-6 p-0">
									<div
										id="bg-login-digidesa-new"
										className="auth-innerright"
										style={{
										// backgroundImage: 'url(' + background + ')',
										// background: 'none',
										display: "flex",
										flexDirection: "row",
										justifyContent: "center"
										//   background: "#ffffff"
										}}
									>
										{/* <div className="text-center">
											{logoAplikasi}
										</div> */}
										<div className="text-center">
											<h3
												style={{
												fontWeight: "bold",
												color: "#ffffff",
												textShadow: "1px 0px 5px #000000"
												}}
											>
												<div>Kios Pelayanan</div>
												<div>Masyarakat</div>
											</h3>
										</div>
									</div>
								</div>
								<div
									className="col-md-6 p-0 "
									onMouseDown={(e) => {
										let slider = document.querySelector('.root-scrollable');
										//console.log('ApA NI');
										//console.log(e);
										this.setState({
											isDown: true,
											startY: e.pageY - slider.offsetTop,
											scrollUp: slider.scrollTop,
										})
										this.forceUpdate();
										//console.log(this.state.startY);
										// this.state.scrollUp = this.scrollUp;
									}}
									onMouseLeave={() => {
										this.setState({ isDown: false });
									}}
									onMouseUp={() => {
										this.setState({ isDown: false });
									}}
									onMouseMove={(e) => {
										let slider = document.querySelector('.root-scrollable');

										if (!this.state.isDown) {
											return;
										}

										e.preventDefault();
										const y = e.pageY - slider.offsetTop;
										const startY = this.state.startY;
										const walk = y - startY;
										slider.scrollTop = this.state.scrollUp - walk;
										//console.log({ y, startY });
									}}
								>
									<div
										// id="bg-login-digidesa"
										className="auth-innerright"
										style={{
										// backgroundColor: '#E7FED5',
										display: "flex",
										flexDirection: "row",
										justifyContent: "center",
										backgroundColor: "#ffffff"
										// background: 'none'
										}}
									>
										<div className="authentication-box">
											<div className="text-center">
												<h3
												style={{
													fontWeight: "bold",
													color: "#ffffff"
												}}
												>
												{logoAplikasi}
												{/* {localStorage.getItem("JENIS_APLIKASI")} */}
												</h3>
											</div>
											<div
												className="pl-4 pr-4 mb-0"
												style={
												{
													//   borderRadius: "40px",
													//   backgroundColor: "#ffffffc7",
													//   boxShadow:
													//     "0 4px 8px 0 #0000003d, 0 6px 20px 0 #0000003d"
													// backgroundImage: 'url(' + background + ')'
													// backgroundImage: "url('../images/kiosk/cv.png')",
												}
												}
											>
												<Form className="theme-form" onSubmit={this.handleSubmit}>
													<div className="form-group inputss">
														<label
															className="col-form-label pt-0"
															style={{ fontWeight: '600' }}
														>
															Nomor KK
														</label>
														{/* <i class="fa fa-user icon"></i> */}
														<input
															onFocus={() => this.setActiveInput('input1')}
															value={this.state.input['input1'] || ''}
															onChange={(e) => this.onChangeInput(e)}
															required
															id="no_kk"
															type="number"
															maxLength="16"
															autoComplete="off"
															onInput={this.maxLengthCheck}
															onClick={() => this.showKeyboard()}
															autoFocus
															className="form-control form-control-lg"
															placeholder="Masukkan Nomor Kartu Keluarga"
															style={{
																borderRadius: "7px",
																border: "1px solid #98a6ad"
															}}
														/>

														{this.state.status_kk === true ? (
															<div>
																<span style={{ color: '#D43737' }}>
																	Nomor KK anda belum terdaftar, Silahkan cek kembali, atau hubungi admin desa.
																</span>
																{/* <br />
																<span
																	style={{ color: '#0085FF', cursor: 'pointer' }}
																	onClick={() => this.onOpenModal(`Basic`)}
																>
																	Registrasi disini
																</span> */}
															</div>
														) : (
															<div />
														)}
													</div>
													<div className="form-group inputss">
														<label
															className="col-form-label pt-0"
															style={{ fontWeight: '600' }}
														>
															NIK
														</label>
														<input
															onFocus={() => this.setActiveInput('input2')}
															value={this.state.input['input2'] || ''}
															onChange={(e) => this.onChangeInput(e)}
															required
															id="nik"
															type="number"
															maxLength="16"
															autoComplete="off"
															onInput={this.maxLengthCheck}
															onClick={() => this.showKeyboard()}
															className="form-control form-control-lg"
															placeholder="Masukkan Nomor Induk Kependudukan"
															style={{
																borderRadius: "7px",
																border: "1px solid #98a6ad"
															}}
														/>
														<br />

														{this.state.status_nik === true ? (
															<div>
																<span style={{ color: '#D43737' }}>
																	NIK anda belum terdaftar, Silahkan cek kembali, atau hubungi admin desa.
																</span>
																{/* <br />
																<span
																	style={{ color: '#0085FF', cursor: 'pointer' }}
																	onClick={() => {
																		this.setState({
																			judul: 'Registrasi NIK',
																			dt: frmDef,
																			prvStat: null,
																			kbktStat: null,
																			kecStat: null,
																			kldsStat: null,
																			shdkStat: null,
																			ptrStat: null,
																			pkrStat: null,
																			agmStat: null,
																			kkStat: null,
																			spkStat: null,
																			stpStat: null,
																			wilayah: 'inherit',
																			status_nik: 'false'
																		});
																		this.bukaForm();
																	}}
																>
																	Registrasi disini
																</span> */}
															</div>
														) : (
															<div />
														)}
													</div>
													<div className="text-right" />
													<div className="text-right mt-4">
														<Link to="/beranda">
															<button
																type="button"
																className="btn btn-danger"
																style={{ fontWeight: '600', marginLeft: 5 }}
																onClick={() => {
																	// this.setState({
																	// 	status_kk: false,
																	// 	status_nik: false,
																	// 	display: 'none'
																	// });
																}}
															>
																Keluar
															</button>
														</Link> 
														&nbsp;&nbsp;&nbsp;
														<button
															type="submit"
															className="btn btn-success"
															style={{ fontWeight: '600' }}
															onClick={() => {
																this.setState({
																	status_kk: false,
																	status_nik: false,
																	display: 'none'
																});
															}}
														>
															Lanjutkan
														</button>
													</div>
												</Form>
											</div>
											<div className="text-center mt-4">
												{/* <h6>Belum punya akun? <Link to="#" ><u className="text-success">Daftar disini</u></Link></h6> */}
												{/* <h6>Belum punya akun? <Link to="/register" ><u className="text-success">Daftar disini</u></Link></h6> */}
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* keyboard embed */}

							{/* {this.state.display === '' ? (
								<div className="row" style={{ marginTop: '200px' }}>
									<div
										className="container-fluid"
										style={{ zIndex: 3, position: 'fixed', bottom: 0 }}
									>
										<div className="row">
											<Keyboard
												keyboardRef={(r) => (this.keyboard = r)}
												inputName={this.state.inputName}
												layoutName={this.state.layoutName}
												onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
												onKeyPress={(button) => this.onKeyPress(button)}
												inputPattern={this.state.inputPattern}
											/>
										</div>
									</div>
								</div>
							) : (
								<div />
							)} */}

							{/* end keyboard embed */}
						</div>
					</div>
				</div>
				{/*page-wrapper Ends*/}

				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={(e) => this.simpan(e, 0)}>
						<div
							// style={{ marginBottom: this.state.display === '' ? '230px' : '0px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								// //console.log(e);
								this.setState({
									isDown: true,
									startY: e.pageY - slider.offsetTop,
									scrollUp: slider.scrollTop,
								})
								this.forceUpdate();
								// //console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								// //console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title" align="center">
									Registrasi Kartu Keluarga
								</h5>
							</div>

							<div className="modal-body">
								<p>
									Data KK adalah induk dari data penduduk. Data ini digunakan sebagai penghubung untuk
									pengurusan surat. Penduduk dalam 1 KK bisa saling mengajukan pelayanan surat dan
									perizinan.
								</p>
								{pilihPenduduk}

								<div style={{ display: this.state.wilayah }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											{localStorage.getItem('JENIS_DESA')}
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												type="text"
												defaultValue=""
												required
												value={this.state.data.nama_desanya}
												readOnly
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											{JENIS_DUSUN}
										</Label>
										<Col sm="9">
											<Select
												isDisabled={this.state.status.select}
												classNamePrefix="select"
												onChange={(e) => {
													this.getChild(e, 'dusun', 'dus', 'rw');
												}}
												defaultValue={this.state.dusStat}
												value={this.state.dusStat}
												name="dusun"
												options={this.state.frmDus}
												placeholder={'Pilih ' + JENIS_DUSUN}
												isClearable
												required
											/>
											{/* <select
												className="form-control slct"
												name="id_dusun"
												value={this.state.id_dusun.value}
												onChange={this.ChangeKeldes}
											>
												<option value="">Pilih {JENIS_DUSUN}</option>
												{this.state.dusunData.map((e, key) => {
													return <option key={key} value={e.id}>{e.nama}</option>;
												})}
											</select> */}
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											RW
										</Label>
										<Col sm="9">
											<Select
												isDisabled={this.state.status.select}
												classNamePrefix="select"
												onChange={(e) => {
													this.getChild(e, 'rw', 'rw', 'rt');
												}}
												defaultValue={this.state.rwStat}
												value={this.state.rwStat}
												name="rw"
												options={this.state.frmRw}
												placeholder="Pilih RW"
												isClearable
												required
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											RT
										</Label>
										<Col sm="9">
											<Select
												isDisabled={this.state.status.select}
												classNamePrefix="select"
												onChange={(e) => {
													this.changeSelectValue(e, 'rt');
												}}
												defaultValue={this.state.rtStat}
												value={this.state.rtStat}
												name="rt"
												options={this.state.frmRt}
												placeholder="Pilih RT"
												isClearable
												required
											/>
										</Col>
									</FormGroup>
								</div>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nomor KK
									</Label>
									<Col sm="9">
										<input
											onFocus={() => this.setActiveInput('no_kk_kepala')}
											value={this.state.input['no_kk_kepala'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="no_kk_kepala"
											type="number"
											maxLength="16"
											onInput={this.maxLengthCheck}
											placeholder="Masukkan Nomor KK"
											required
											onClick={() => this.showKeyboard1()}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Alamat
									</Label>
									<Col sm="9">
										<input
											onClick={() => this.showKeyboard1()}
											onFocus={() => this.setActiveInput('alamat_kepala')}
											value={this.state.input['alamat_kepala'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="alamat_kepala"
											type="text"
											placeholder="Masukkan Alamat"
											required
											defaultValue=""
										/>
									</Col>
								</FormGroup>

								<div style={{ display: this.state.tampilkk }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											NIK
										</Label>
										<Col sm="9">
											<input
												onClick={() => this.showKeyboard1()}
												onFocus={() => this.setActiveInput('nik_kepala')}
												value={this.state.input['nik_kepala'] || ''}
												onChange={(e) => this.onChangeInput(e)}
												className="form-control"
												id="nik_kepala"
												type="number"
												defaultValue=""
												maxLength="16"
												onInput={this.maxLengthCheck}
												placeholder="NIK Kepala Keluarga"
												required
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											SHDK
										</Label>
										<Col sm="9">
											<Select
												isDisabled={this.state.status.select}
												classNamePrefix="select"
												onChange={(e) => {
													this.changeSelectValue(e, 'SHDKKK');
												}}
												defaultValue={this.state.shdkkkStat}
												value={this.state.shdkkkStat}
												name="SHDKKK"
												options={this.state.frmShdk}
												placeholder="Pilih SHDK"
												isClearable
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Agama
										</Label>
										<Col sm="9">
											<Select
												isDisabled={this.state.status.select}
												classNamePrefix="select"
												onChange={(e) => {
													this.changeSelectValue(e, 'agamakk');
												}}
												defaultValue={this.state.agmkkStat}
												value={this.state.agmkkStat}
												name="AgamaKK"
												options={this.state.frmAgm}
												placeholder="Pilih Agama"
												isClearable
												// isDisabled={this.state.diskk}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Pendidikan Terakhir
										</Label>
										<Col sm="9">
											<Select
												isDisabled={this.state.status.select}
												classNamePrefix="select"
												onChange={(e) => {
													this.changeSelectValue(e, 'pendidikan-terakhirkk');
												}}
												defaultValue={this.state.ptrkkStat}
												value={this.state.ptrkkStat}
												name="PendidikanKK"
												options={this.state.frmPtr}
												placeholder="Pilih Pendidikan Terakhir"
												isClearable
												// isDisabled={this.state.diskk}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Pekerjaan
										</Label>
										<Col sm="9">
											<Select
												isDisabled={this.state.status.select}
												classNamePrefix="select"
												onChange={(e) => {
													this.changeSelectValue(e, 'pekerjaankk');
												}}
												defaultValue={this.state.pkrkkStat}
												value={this.state.pkrkkStat}
												name="PekerjaanKK"
												options={this.state.frmPkr}
												placeholder="Pilih Pekerjaan"
												isClearable
												// isDisabled={this.state.diskk}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Nama Lengkap
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="nama_kepala"
												type="text"
												placeholder="Nama Lengkap"
												defaultValue=""
												required
												onFocus={() => this.setActiveInput('nama_kepala')}
												value={this.state.input['nama_kepala'] || ''}
												onChange={(e) => this.onChangeInput(e)}
												onClick={() => this.showKeyboard1()}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											JK
										</Label>
										<Col sm="9">
											<Select
												isDisabled={this.state.status.select}
												classNamePrefix="select"
												onChange={(e) => {
													this.changeSelectValue(e, 'jkkk');
												}}
												// defaultValue={this.state.jkkkStat}
												// value={this.state.jkkkStat}
												name="Jenis Kelamin"
												options={this.state.frmJk}
												placeholder="Pilih Jenis Kelamin"
												isClearable
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tempat Lahir
										</Label>
										<Col sm="9">
											<input
												onFocus={() => this.setActiveInput('tempat_lahir_kepala')}
												value={this.state.input['tempat_lahir_kepala'] || ''}
												onChange={(e) => this.onChangeInput(e)}
												className="form-control"
												id="tempat_lahir_kepala"
												type="text"
												placeholder="Tempat Lahir"
												onClick={() => this.showKeyboard1()}
												defaultValue=""
												required
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tanggal Lahir
										</Label>
										<Col sm="9">
											<Datetime
												locale="id-ID"
												input={true}
												timeFormat={false}
												dateFormat="DD/MM/yyyy"
												closeOnTab={true}
												closeOnSelect={true}
												initialValue={this.state.tanggal_lahirkk}
												value={this.state.tanggal_lahirkk}
												onChange={(e) => {
													this.gantiTgl(e, 'kk');
												}}
												inputProps={{
													required: true,
													placeholder: 'Tanggal Lahir'
												}}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Status Perkawinan
										</Label>
										<Col sm="9">
											<Select
												isDisabled={this.state.status.select}
												classNamePrefix="select"
												onChange={(e) => {
													this.changeSelectValue(e, 'status-perkawinankk');
												}}
												defaultValue={this.state.spkkkStat}
												value={this.state.spkkkStat}
												name="Status Perkawinan"
												options={this.state.frmSpk}
												placeholder="Pilih Status Perkawinan"
												isClearable
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Status Penduduk
										</Label>
										<Col sm="9">
											<Select
												isDisabled={this.state.status.select}
												classNamePrefix="select"
												onChange={(e) => {
													this.changeSelectValue(e, 'status-pendudukkk');
												}}
												defaultValue={this.state.stpkkStat}
												value={this.state.stpkkStat}
												name="Status Penduduk"
												options={this.state.frmStp}
												placeholder="Pilih Status Penduduk"
												isClearable
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Nama Ibu Kandung
										</Label>
										<Col sm="9">
											<input
												onFocus={() => this.setActiveInput('nama_ibu_kandung_kepala')}
												value={this.state.input['nama_ibu_kandung_kepala'] || ''}
												onChange={(e) => this.onChangeInput(e)}
												className="form-control"
												id="nama_ibu_kandung_kepala"
												type="text"
												placeholder="Nama Ibu Kandung"
												defaultValue=""
												required
												onClick={() => this.showKeyboard1()}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Nama Ayah Kandung
										</Label>
										<Col sm="9">
											<input
												onFocus={() => this.setActiveInput('nama_ayah_kandung_kepala')}
												value={this.state.input['nama_ayah_kandung_kepala'] || ''}
												onChange={(e) => this.onChangeInput(e)}
												className="form-control"
												id="nama_ayah_kandung_kepala"
												type="text"
												placeholder="Nama Ayah Kandung"
												required
												defaultValue=""
												onClick={() => this.showKeyboard1()}
											/>
										</Col>
									</FormGroup>

									<div className="row">
										<div className="col-lg-12">
											<font color="white">
												<a href={() => false} className="btn btn-info" onClick={this.formAnggota}>
													Tambah Anggota Keluarga
												</a>
											</font>
										</div>
									</div>
									<br />

									<FormGroup className="row">
										<Col sm="12">
											<table
												className="table table-bordered table-stripes table-hovered"
												style={{ fontSize: '12px' }}
												width="100%"
											>
												<thead>
													<tr>
														<th>No</th>
														<th>NIK</th>
														<th>Nama</th>
														<th>Tempat/Tanggal Lahir</th>
														<th>Pekerjaan</th>
														<th>Hubungan Keluarga</th>
														<th>
															<i className="fa fa-trash" />
														</th>
													</tr>
												</thead>
												<tbody>{input_anggota}</tbody>
											</table>
										</Col>
									</FormGroup>
								</div>
							</div>
						</div>

						<div
							className="modal-footer"
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginBottom: '200px'
							}}
						>
							<button type="submit" className="btn btn-success">
								Simpan
							</button>
						</div>

						{/* {this.state.regis ? (
							<div style={{ position: 'fixed', left: 0, bottom: 0, width: '100%', zIndex: 4 }}>
								<Keyboard
									style={{ marginTop: '40px', zIndex: 3 }}
									keyboardRef={(r) => (this.keyboard = r)}
									inputName={this.state.inputName}
									layoutName={this.state.layoutName}
									onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
									onKeyPress={(button) => this.onKeyPress(button)}
								/>
							</div>
						) : (
							<div />
						)} */}
					</Form>
				</Modal>

				{/* end modal registrasi kk */}

				{/* modal tambah anggota keluarga */}

				<Modal
					classNames={{ overlay: 'card-scrollable2' }}
					open={this.state.status.anggota}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={this.tutupAnggota}
					styles={{ modal: { width: '70%' } }}
				>
					<Form onSubmit={this.tambahAnggota}>
						<div
							// style={{ marginBottom: this.state.displayy === '' ? '230px' : '0px' }}
							onMouseDown={(e) => {
								let sliderr = document.querySelector('.card-scrollable2');
								// //console.log(e);
								this.setState({
									isDown: true,
									startYy: e.pageY - sliderr.offsetTop,
									scrollUpp: sliderr.scrollTop,
								})

								this.forceUpdate();
								// //console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let sliderr = document.querySelector('.card-scrollable2');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const yy = e.pageY - sliderr.offsetTop;
								const startYy = this.state.startYy;
								const walkk = yy - startYy;
								sliderr.scrollTop = this.state.scrollUpp - walkk;
								// //console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title">Anggota Keluarga</h5>
							</div>

							<div className="modal-body">
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										NIK
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik_keluarga"
											type="number"
											placeholder="NIK"
											required
											maxLength="16"
											onInput={this.maxLengthCheck}
											onFocus={() => this.setActiveInput('nik_keluarga')}
											value={this.state.input['nik_keluarga'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											autoFocus
											defaultValue=""
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										SHDK
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'shdk');
											}}
											defaultValue={this.state.shdkStat}
											value={this.state.shdkStat}
											name="SHDK"
											options={this.state.frmShdk}
											placeholder="Pilih SHDK"
											isClearable
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Agama
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'agama');
											}}
											defaultValue={this.state.agmStat}
											value={this.state.agmStat}
											name="Agama"
											options={this.state.frmAgm}
											placeholder="Pilih Agama"
											isClearable
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Pendidikan Terakhir
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'pendidikan-terakhir');
											}}
											defaultValue={this.state.ptrStat}
											value={this.state.ptrStat}
											name="Pendidikan"
											options={this.state.frmPtr}
											placeholder="Pilih Pendidikan Terakhir"
											isClearable
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Pekerjaan
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'pekerjaan');
											}}
											defaultValue={this.state.pkrStat}
											value={this.state.pkrStat}
											name="Pekerjaan"
											options={this.state.frmPkr}
											placeholder="Pilih Pekerjaan"
											isClearable
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nama Lengkap
									</Label>
									<Col sm="9">
										<input
											onFocus={() => this.setActiveInput('nama_keluarga')}
											value={this.state.input['nama_keluarga'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="nama_keluarga"
											type="text"
											placeholder="Nama Lengkap"
											defaultValue=""
											required
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										JK
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'jk');
											}}
											defaultValue={this.state.jkStat}
											value={this.state.jkStat}
											name="Jenis Kelamin"
											options={this.state.frmJk}
											placeholder="Pilih Jenis Kelamin"
											isClearable
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
											onFocus={() => this.setActiveInput('tempat_lahir_keluarga')}
											value={this.state.input['tempat_lahir_keluarga'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="tempat_lahir_keluarga"
											type="text"
											placeholder="Tempat Lahir"
											defaultValue=""
											required
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tanggal Lahir
									</Label>
									<Col sm="9">
										<Datetime
											locale="id-ID"
											input={true}
											timeFormat={false}
											closeOnTab={true}
											closeOnSelect={true}
											dateFormat="DD/MM/yyyy"
											initialValue={this.state.tanggal_lahir}
											value={this.state.tanggal_lahir}
											onChange={(e) => {
												this.gantiTgl(e, 'tanggal_lahir');
											}}
											inputProps={{
												required: true,
												placeholder: 'Tanggal Lahir'
											}}
										/>
										{/* <input className="form-control" id="tanggal_lahir" type="date" placeholder="Tempat Lahir" defaultValue={this.state.dt.tanggal_lahir} required /> */}
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Status Perkawinan
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'status-perkawinan');
											}}
											defaultValue={this.state.spkStat}
											value={this.state.spkStat}
											name="Status Perkawinan"
											options={this.state.frmSpk}
											placeholder="Pilih Status Perkawinan"
											isClearable
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Status Penduduk
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'status-penduduk');
											}}
											defaultValue={this.state.stpStat}
											value={this.state.stpStat}
											name="Status Penduduk"
											options={this.state.frmStp}
											placeholder="Pilih Status Penduduk"
											isClearable
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nama Ibu Kandung
									</Label>
									<Col sm="9">
										<input
											onFocus={() => this.setActiveInput('nama_ibu_kandung_keluarga')}
											value={this.state.input['nama_ibu_kandung_keluarga'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="nama_ibu_kandung_keluarga"
											type="text"
											placeholder="Nama Ibu Kandung"
											defaultValue=""
											required
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nama Ayah Kandung
									</Label>
									<Col sm="9">
										<input
											onFocus={() => this.setActiveInput('nama_ayah_kandung_keluarga')}
											value={this.state.input['nama_ayah_kandung_keluarga'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="nama_ayah_kandung_keluarga"
											type="text"
											placeholder="Nama Ayah Kandung"
											defaultValue=""
											required
										/>
									</Col>
								</FormGroup>
							</div>

							<div className="modal-footer" style={{ marginBottom: '200px' }}>
								<button
									type="button"
									className="btn btn-warning"
									// disabled={this.state.status.btnForm}
									onClick={this.tutupAnggota}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-success">
									Masukkan Anggota
								</button>
							</div>
						</div>
					</Form>

					{/* {this.state.anggota ? (
						<div style={{ position: 'fixed', left: 0, bottom: 0, width: '100%', zIndex: 4 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
						<div />
					)} */}
				</Modal>

				{/* end modal tambah anggota keluarga */}

				{/* MODAL REGISTRASI NIK PENDUDUK */}

				<Modal
					classNames={{ overlay: 'card-scrollable2' }}
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<Form className="theme-form" onSubmit={this.simpanNik}>
						<div
							// style={{ marginBottom: this.state.displayy === '' ? '230px' : '0px' }}
							onMouseDown={(e) => {
								let sliderr = document.querySelector('.card-scrollable2');
								// //console.log(e);
								this.setState({
									isDown: true,
									startYy: e.pageY - sliderr.offsetTop,
									scrollUpp: sliderr.scrollTop,
								})

								this.forceUpdate();
								// //console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let sliderr = document.querySelector('.card-scrollable2');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const yy = e.pageY - sliderr.offsetTop;
								const startYy = this.state.startYy;
								const walkk = yy - startYy;
								sliderr.scrollTop = this.state.scrollUpp - walkk;
								// //console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title">{this.state.judul}</h5>
							</div>
							<div className="modal-body">
								<p>
									Alamat pada data penduduk bisa jadi berbeda dengan alamat pada KK jika penduduk ybs
									sedang berdomisili di luar kota dalam rangka belajar atau bekerja untuk sementara
									waktu.
								</p>
								<input
									className="form-control"
									id="id"
									type="hidden"
									placeholder="Penduduk"
									defaultValue={this.state.dt.id}
								/>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										NIK
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="idbaru"
											type="number"
											placeholder="NIK"
											defaultValue={this.state.dt.idbaru}
											required
											maxLength="16"
											onInput={this.maxLengthCheck}
											onFocus={() => this.setActiveInput('nik_regis')}
											value={this.state.input['nik_regis'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											onClick={() => this.showKeyboard2()}
											autoFocus
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										No KK
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nikNoKK"
											type="number"
											placeholder="No. KK"
											defaultValue={this.state.kkStat}
											maxLength="16"
											onInput={this.maxLengthCheck}
											onFocus={() => this.setActiveInput('nokk_regis')}
											value={this.state.input['nokk_regis'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											onClick={() => this.showKeyboard2()}
											required
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										SHDK
									</Label>
									<Col sm="9">
										<Select
											required
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'shdk');
											}}
											defaultValue={this.state.shdkStat}
											value={this.state.shdkStat}
											name="SHDK"
											options={this.state.frmShdk}
											placeholder="Pilih SHDK"
											isClearable
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Agama
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'agama');
											}}
											defaultValue={this.state.agmStat}
											value={this.state.agmStat}
											name="Agama"
											options={this.state.frmAgm}
											placeholder="Pilih Agama"
											isClearable
											required
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Pendidikan Terakhir
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'pendidikan-terakhir');
											}}
											defaultValue={this.state.ptrStat}
											value={this.state.ptrStat}
											name="Pendidikan"
											options={this.state.frmPtr}
											placeholder="Pilih Pendidikan Terakhir"
											isClearable
											required
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Pekerjaan
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'pekerjaan');
											}}
											defaultValue={this.state.pkrStat}
											value={this.state.pkrStat}
											name="Pekerjaan"
											options={this.state.frmPkr}
											placeholder="Pilih Pekerjaan"
											isClearable
											required
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nama Lengkap
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama"
											type="text"
											placeholder="Nama Lengkap"
											defaultValue={this.state.dt.nama}
											required
											onFocus={() => this.setActiveInput('nmLengkap_nik')}
											value={this.state.input['nmLengkap_nik'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											onClick={() => this.showKeyboard2()}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Alamat
									</Label>
									<Col sm="9">
										<textarea
											className="form-control"
											id="alamat"
											placeholder="Alamat"
											defaultValue={this.state.dt.alamat}
											required
											onFocus={() => this.setActiveInput('alamat_nik')}
											value={this.state.input['alamat_nik'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											onClick={() => this.showKeyboard2()}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										JK
									</Label>
									<Col sm="9">
										<Select
											required
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'jk');
											}}
											defaultValue={this.state.jkStat}
											value={this.state.jkStat}
											name="Jenis Kelamin"
											options={this.state.frmJk}
											placeholder="Pilih Jenis Kelamin"
											isClearable
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tempat_lahir"
											type="text"
											placeholder="Tempat Lahir"
											defaultValue={this.state.dt.tempat_lahir}
											required
											onFocus={() => this.setActiveInput('tl_nik')}
											value={this.state.input['tl_nik'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											onClick={() => this.showKeyboard2()}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tanggal Lahir
									</Label>
									<Col sm="9">
										{/* #next */}
										<Datetime
											locale="id-ID"
											input={true}
											timeFormat={false}
											closeOnTab={true}
											closeOnSelect={true}
											dateFormat="DD/MM/yyyy"
											initialValue={this.state.tanggal_lahirnik}
											value={this.state.tanggal_lahirnik}
											onChange={(e) => {
												this.gantiTgl(e, 'tgl_lahir_nik');
											}}
											inputProps={{
												required: true,
												placeholder: 'Tanggal Lahir'
											}}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Status Perkawinan
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'status-perkawinan');
											}}
											defaultValue={this.state.spkStat}
											value={this.state.spkStat}
											name="Status Perkawinan"
											options={this.state.frmSpk}
											placeholder="Pilih Status Perkawinan"
											isClearable
											required
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nama Ibu Kandung
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_ibu_kandung"
											type="text"
											placeholder="Nama Ibu Kandung"
											defaultValue={this.state.dt.nama_ibu_kandung}
											required
											onFocus={() => this.setActiveInput('nmibu_nik')}
											value={this.state.input['nmibu_nik'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											onClick={() => this.showKeyboard2()}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nama Ayah Kandung
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_ayah_kandung"
											type="text"
											placeholder="Nama Ayah Kandung"
											defaultValue={this.state.dt.nama_ayah_kandung}
											required
											onFocus={() => this.setActiveInput('nmbpk_nik')}
											value={this.state.input['nmbpk_nik'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											onClick={() => this.showKeyboard2()}
										/>
									</Col>
								</FormGroup>
							</div>
							<div
								className="modal-footer"
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									marginBottom: '200px'
								}}
							>
								<button
									type="button"
									className="btn btn-warning"
									disabled={this.state.status.btnForm}
									onClick={this.tutupForm}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
									Simpan
								</button>
							</div>
						</div>
					</Form>

					{/* {this.state.regis ? (
						<div style={{ position: 'fixed', left: 0, bottom: 0, width: '100%', zIndex: 4 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
						<div />
					)} */}
				</Modal>

				{/* END MODAL REGISTRASI NIK PENDUDUK  */}
			</div>
		);
	}
}

export default LoginMasyarakat;
