import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Get, JENIS_DUSUN, Post } from '../../function/Koneksi';
import { Tanggal } from '../../function/Format';

const ModalSuratTidakMampu = ({ data, optionData, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData }) => {
	const dateFormat = require('dateformat');

	const frmDex = {
		uuid: '',
		id_jenis: '',
		nik_pelapor: '',
		nik: '',
		kk: '',
		nama_pemohon: '',
		tempat_lahir: '',
		tanggal_lahir: '',
		jenis_kelamin: '',
		pekerjaan: '',
		pendidikan_terakhir: '',
		kewarganegaraan: '',
		agama: '',
		alamat: '',
		status_perkawinan: '',
		tujuan_pembuatan: '',
		detail: []
	}

	const initFormData = {
		frmNik: optionData.frmNik,
		frmShdk: optionData.frmShdk,
		frmNik3: optionData.frmNik3,
		nikStat: null,
		nik3Stat: null,
		shdk_aw: null,
		detail: [],
		status: {
			form: false,
			btnForm: false,
			btnAksi: [],
			dataPenduduk: "none",
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [dt, setDt] = useState(data ? data : frmDex)

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}
		console.log(data);
		console.log(optionData);

		setDt(data ? data : frmDex)
		setFormData(data ? {
			...initFormData,
			nikStat: optionData.nikStat,
			status: optionData.status,
		} : initFormData)
	}, [isOpen])

	const handleInputChangeNIK = (e, fld) => {
		if (e) {
			let nikStat = { value: e, label: e };

			Post("penduduk", null, { nik: e }, datanik => {
				let frmNik = [];
				datanik.data.results.data.forEach(dt => {
					frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
				});

				switch (fld) {
					case "nik":
						setFormData({
							...formData,
							frmNik,
							nikStat
						})
						break;

					default:
						break;
				}

			});

		}
	};

	const changeInputText = (e, fld) => {
		switch (fld) {
			case "penghasilan":
				setDt({
					...dt,
					penghasilan: e.target.value,
				})
				break;
			default:
				// no default
				break;
		}
	};

	const getPenduduk = (e, sel, jns) => {
		if (e) {
			// this.changeSelectValue(e, sel);
			setAlert({
				loadingPreview: true
			})

			Get("penduduk/find", e.value, data => {
				console.log(data)
				let alamat = '';
				if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
					alamat =
						"RT. " +
						data.results.data_kk.data_rt.nama +
						"/RW. " +
						data.results.data_kk.data_rt.data_rw.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_keldes.nama +
						", Kec. " +
						data.results.data_kk.data_rt.data_rw.data_keldes
							.data_kecamatan.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_keldes
							.data_kecamatan.data_kabkota.nama;
				} else {
					if (data.results.data_kk.id_rt === '0') {
						alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama +
							", Kec. " + data.results.data_keldes.data_kecamatan.nama +
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama;
					} else {
						alamat =
							" RT. " +
							data.results.data_kk.data_rt.nama +
							"/RW. " +
							data.results.data_kk.data_rt.data_rw.nama +
							", " + JENIS_DUSUN + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.nama +
							", " + localStorage.getItem('JENIS_DESA') + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
							", Kec. " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
								.data_kecamatan.nama +
							", " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
								.data_kecamatan.data_kabkota.nama;
					}
				}

				if (jns === "pelapor") {
					Get("penduduk/kk/"+data.results.id_kk, null, dtkk => {
						let frmNik3 = [];
						dtkk.results.forEach(val => {
							frmNik3.push({ value: val.id, label: val.id + ' (' + val.nama + ')' });
						});

						setFormData({
							...formData,
							nikStat: e,
							frmNik3,
							status: {
								...formData.status,
								dataPenduduk: "block",
							},
						})
						setDt({
							...dt,
							nik: e.value,
							nik_pelapor: e.value,
							kk: data.results.id_kk,
							nama_pemohon: data.results.nama,
							tempat_lahir: data.results.tempat_lahir,
							tanggal_lahir: new Date(
								data.results.tanggal_lahir
							),
							pekerjaan: data.results.data_pekerjaan.nama,
							pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
							agama: data.results.data_agama.nama,
							status_perkawinan: data.results.status_perkawinan,
							jenis_kelamin: data.results.jk,
							alamat: alamat,
							kewarganegaraan: 'WNI',
							detail: []
						})
						setAlert({
							loadingPreview: false
						})
	
						// For testing
						// if (props.selectOnChange2) {
						// 	props.selectOnChange2(dt)
						// }

					});

				} else if (jns === "pengikut"){
					let detail = dt.detail
					detail.push({
						nik: e.value,
						nama: data.results.nama,
						jenis_kelamin: data.results.jk,
						tanggal_lahir: data.results.tanggal_lahir,
						tempat_lahir: data.results.tempat_lahir,
						pekerjaan: data.results.data_pekerjaan.nama,
						pendidikan: data.results.data_pendidikan_terakhir.nama,
						shdk: formData.frmShdk[data.results.shdk],
						keterangan: "",
					})

					setDt({
						...dt,
						detail
					})

					setAlert({
						loadingPreview: false
					})

					// For testing
					// if (this.props.getListNIK3) {
					// 	this.props.getListNIK3(frmNik3)
					// }
				}
			});
		} else {
			switch (jns) {
				case "pelapor":
					setDt({
						...dt,
						nama_pemohon: '',
						tempat_lahir: '',
						tanggal_lahir: null,
						pekerjaan: '',
						pendidikan_terakhir: '',
						agama: '',
						status_perkawinan: '',
						jenis_kelamin: '',
						alamat: '',
						detail: []
					})
					setFormData({
						...formData,
						status: {
							...formData.status,
							dataPenduduk: "none"
						},
						nikStat: null,
						frmNik3: []
					})
					break;
				case "pengikut":
					setFormData({
						...formData,
						nik3Stat: null
					})
					break;
				default:
					break;
			}
		}
	};

	const hapusDetail = (i) => {
		console.log(i);
		// let currDetail = dt.detail
		let newDetail = dt.detail.splice(i,1)

		// console.log(currDetail);
		console.log(newDetail);
		console.log(dt.detail);

		setDt({
			...dt,
			detail: dt.detail
		})
  };

	const simpan = (e) => {
		e.preventDefault();
		console.log('tesdfs');
		console.log(formData);

		setAlert({
			loadingPreview: true
		})

		let bodyRaw = {
			...dt,
			tanggal_lahir: dateFormat(new Date(dt.tanggal_lahir), 'yyyy-mm-dd'),
			tujuan_pembuatan: document.getElementById('tujuan_pembuatan').value,
		}
		if (dt.uuid === '') {
			bodyRaw.id_jenis = props.location.state.uuid
		}
		console.log(bodyRaw);

		let validasiPelapor = formData.nikStat;

		if (validasiPelapor) {
			console.log(bodyRaw);

			let psn = "";
			let resstat = 204;
			let metode = "create";
			if (bodyRaw.uuid === "") {
				psn = "Tambah";
				resstat = 201;
				bodyRaw.uuid = null;
			} else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
			Post(
				"surat-keterangan/tidak-mampu/" + metode,
				bodyRaw.uuid,
				bodyRaw,
				res => {

					// For testing
					if (props.resSubmit) {
						props.resSubmit(res.status)
					}

					// this.tutupForm();
					setFormData({
						...formData,
						status: {
							...formData.status,
							btnForm: false,
							form: false,
						}
					})
					setIsOpen(false)
					setAlert({
						loadingPreview: false
					})
					if (res.status === resstat) {
						const alertData = {
							show: true,
							basicType: "success",
							basicTitle: "Data Surat Keterangan Tidak Mampu",
							pesanAlert: "Berhasil " + psn + " Data",
						}
						setAlert(alertData)
					} else {
						const alertData = {
							show: true,
							basicType: "danger",
							basicTitle: "Data Surat Keterangan Tidak Mampu",
							pesanAlert: "Gagal " + psn + " Data"
						}
						setAlert(alertData)
					}

					// Refresh list
					setStatusRefresh(true)
				}
			);
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			setAlert(alertData)
		}
	};

	return (
		<Modal
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			closeOnEsc={false}
			closeOnOverlayClick={true}
			style={{
				maxwidth: 'unset'
			}}
		>
			<Form
				id="modalForm"
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan}
			>
				<div className="modal-header">
					<h5 className="modal-title">{dt.uuid == '' ? 'Tambah' : 'Edit'} Surat Keterangan Tidak Mampu</h5>
				</div>
				<div className="modal-body">
					<p>
						Data identitas akan terisi secara otomatis saat NIK dipilih.
						Jika ada ketidak sesuaian data, silakan ubah di data master
						penduduk.
					</p>
					<input
						className="form-control"
						id="uuid"
						type="hidden"
						placeholder=""
						defaultValue={dt.uuid}
					/>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik"
						>
							NIK
						</Label>
						<Col sm="9">
							<div data-testid="selectNIK">
								<Select
									inputId="nik"
									name="nik"
									classNamePrefix="select"
									onChange={e => {
										getPenduduk(e, "nik", "pelapor");
									}}
									defaultValue={formData.nikStat}
									value={formData.nikStat}
									options={formData.frmNik}
									placeholder="Pilih NIK"
									onInputChange={e => handleInputChangeNIK(e, "nik")}
									isClearable
								/>
							</div>
						</Col>
					</FormGroup>
					<div style={{ display: formData.status.dataPenduduk }}>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Nama Pemohon
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="nama"
									type="text"
									placeholder="Nama Pemohon"
									value={dt.nama_pemohon}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tempat Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tempat_lahir"
									type="text"
									placeholder="Tempat Lahir"
									value={dt.tempat_lahir}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tanggal Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tanggal_lahir"
									type="text"
									placeholder="Tanggal Lahir"
									value={new Date(dt.tanggal_lahir).toLocaleDateString("id-ID")}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
					</div>
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
							Keperluan Untuk
						</Label>
						<Col sm="9">
							<input
								data-testid="tujuan pembuatan"
								name="tujuan_pembuatan"
								className="form-control"
								id="tujuan_pembuatan"
								type="text"
								placeholder="Keperluan Untuk"
								defaultValue={dt.tujuan_pembuatan}
								required
							/>
						</Col>
					</FormGroup>

					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label">
							Tambah Keluarga Yang Ikut (Jika ada)
						</Label>
						<Col sm="9">
							<div data-testid="selectNIK3">
								<Select
									inputId="nik3"
									classNamePrefix="select"
									onChange={e => {
										// this.changeSelectValue(e, "nik3");
										getPenduduk(e, "nik", "pengikut");
									}}
									defaultValue={formData.nik3Stat}
									value={formData.nik3Stat}
									name="NIK3"
									options={formData.frmNik3}
									placeholder="Pilih Anggota Keluarga"
									// onInputChange={e => this.handleInputChangeNIK(e, "nik3")}
									isClearable
								/>
							</div>
						</Col>
						{dt.detail.length > 0 ? (
							<Col sm="12">
								<table className="table table-bordered table-stripes table-hovered">
									<thead>
										<tr>
											<th>No</th>
											<th>Nama</th>
											<th>JK</th>
											<th>Tempat/Tanggal Lahir</th>
											<th>Pekerjaan</th>
											<th>Pendidikan</th>
											<th>Hubungan Keluarga</th>
											<th>
												<i className="fa fa-trash"></i>
											</th>
										</tr>
									</thead>
									<tbody>
										{dt.detail.map((val, i) => {
											let jk = "L";
											if (val.jenis_kelamin === "Perempuan") {
												jk = "P";
											}
											return (
												<tr key={i}>
													<td>{i+1}</td>
													<td>{val.nama}</td>
													<td>{jk}</td>
													<td>
														{val.tempat_lahir}, {Tanggal(val.tanggal_lahir)}
													</td>
													<td>{val.pekerjaan}</td>
													<td>{val.pendidikan}</td>
													<td>{val.shdk}</td>
													<td>
														<Button
															size="xs"
															onClick={() => hapusDetail(i)}
															color="danger"
															className="btn-icon"
														>
															<i className="fa fa-trash"></i>
														</Button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</Col>
						) : ''}

					</FormGroup>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							// Tutup modal
							setFormData({
								...formData,
								status: {
									...formData.status,
									form: false
								}
							})
							setIsOpen(false)
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						disabled={formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalSuratTidakMampu;
