import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datepicker from "react-datepicker";
import { Link, Redirect } from "react-router-dom";
import Datetime from "react-datetime";

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, Nama_DESA, KECAMATAN, ALAMAT_KANTOR_DESA, NAMA_KABUPATEN, KABUPATEN, PROVINSI,  JENIS_DUSUN } from "../function/Koneksi";
import { Tanggal, HariTanggal,  } from "../function/Format";
import { logo } from "./asset/base64img";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ModalSuratAhliWaris from "./modal/ahli-waris";
var HtmlToReactParser = require("html-to-react").Parser;

//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  id_jenis: "",
  nomor: "",
  tanggal_surat: new Date(),

  nik: "",
  nama: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  jenis_kelamin: "",
  pekerjaan: "",
  kewarganegaraan: "",
  agama: "",
  alamat: "",
  status_perkawinan: "",
  waktu_meninggal: new Date(),
  tempat_meninggal: "",
  sebab_meninggal: "",

  nik2: "",
  nama2: "",
  tempat_lahir2: "",
  tanggal_lahir2: "",
  jenis_kelamin2: "",
  pekerjaan2: "",
  kewarganegaraan2: "",
  agama2: "",
  alamat2: "",
  status_perkawinan2: "",
  waktu_meninggal2: new Date(),
  tempat_meninggal2: "",
  sebab_meninggal2: "",

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",
  status_ttd2: "",
  jabatan_ttd2: "",
  jabatan_pengganti_ttd2: "",
  nama_ttd2: "",
  nip_ttd2: ""
};

const frmDex = {
  uuid: '',
  id_jenis: '',
  nik_pelapor: '',
  nik: '',
  kk: '',
  nama: '',
  tempat_lahir: '',
  tanggal_lahir: '',
  jenis_kelamin: '',
  pekerjaan: '',
  pendidikan_terakhir: '',
  kewarganegaraan: '',
  agama: '',
  alamat: '',
  status_perkawinan: '',
  waktu_meninggal: '',
  tempat_meninggal: '',
  sebab_meninggal: '',
  
  nik2: '',
  nama2: '',
  tempat_lahir2: '',
  tanggal_lahir2: '',
  jenis_kelamin2: '',
  pekerjaan2: '',
  pendidikan_terakhir2: '',
  kewarganegaraan2: '',
  agama2: '',
  alamat2: '',
  status_perkawinan2: '',
  waktu_meninggal2: '',
  tempat_meninggal2: '',
  sebab_meninggal2: '',
  detail: []
}

let detail = [];
let aw = {
  nik: "",
  nama: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  jenis_kelamin: "",
  pekerjaan: ""
};
class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: true,
        btnAksi: [],
        dataPenduduk: "none",
        dataPenduduk2: "none"
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDex,
      loading: false,
      loadingPreview: false,
      judul: "Tambah Surat Ahli Waris",
      shdk: "",
      detail: [],
      // ============ Select 2 ==============
      frmJam: [],
      jamStat: [],
      frmPkr: [],
      pkrStat: [],
      frmAgm: [],
      agmStat: [],
      frmNik: [],
      nikStat: null,
      frmNik2: [],
      nik2Stat: null,
      frmNik3: [],
      nik3Stat: null,
      frmShdk: [],
      // ===================================
      meninggal2: "none",
      shdk_aw: null,
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,
      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      tanggal_lahir: new Date(),
      waktu_meninggal: new Date(),
      waktu_meninggal2: new Date(),
      // ===================================
      // ===================================
      // =============== acc ===============
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      datDisposisi: [],
      riwayatDisposisi: []
    };
  }

  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "waktu_meninggal":
        // this.setState({ waktu_meninggal: date });
        if (typeof date._isValid !== 'undefined') {
          this.setState({ waktu_meninggal: date.format("YYYY-MM-DD HH:mm:ss") });
        }
        break;
      case "waktu_meninggal2":
        // this.setState({ waktu_meninggal2: date });
        if (typeof date._isValid !== 'undefined') {
          this.setState({ waktu_meninggal2: date.format("YYYY-MM-DD HH:mm:ss") });
        }
        break;
      default:
        // no default
        break;
      // case 'tanggal_lahir': this.setState({tanggal_lahir:date});break;
    }
  };

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("surat-keterangan/ahli-waris" + link, null, dtkat => {
      if(dtkat.results){
        this.setState({ data: dtkat.results.data, pagination: dtkat.results });
        this.pagination(dtkat.results);

        // For testing
        if (this.props.getListSuratAhliWaris) {
          this.props.getListSuratAhliWaris(dtkat.results.data)
        }
      }
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let dataSuket = {
        q: e.target.value
      };
      Post("surat-keterangan/ahli-waris", null, dataSuket, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results
        });
      });
    }
  };
  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    //console.log(this.state.nikStat);
    if (this.state.nikStat && this.state.detail.length > 0) {
      this.state.status.btnForm = true;
      this.forceUpdate();
      let tgl_lhr = this.state.dt.tanggal_lahir.split("/");
      let tanggal_lahir = tgl_lhr[2] + "-" + tgl_lhr[1] + "-" + tgl_lhr[0];
      let tanggal_lahir2 = "";
      if (this.state.dt.tanggal_lahir2) {
        let tgl_lhr_pl = this.state.dt.tanggal_lahir2.split("/");
        tanggal_lahir2 =
          tgl_lhr_pl[2] + "-" + tgl_lhr_pl[1] + "-" + tgl_lhr_pl[0];
      }
      let nik = this.state.nikStat.value;
      let nik_pelapor = this.state.nikStat.value;
      let nama = this.state.dt.nama;
      let tempat_lahir = this.state.dt.tempat_lahir;
      let jenis_kelamin = this.state.dt.jenis_kelamin;
      let pekerjaan = this.state.dt.pekerjaan;
      let kewarganegaraan = this.state.dt.kewarganegaraan;
      let agama = this.state.dt.agama;
      let alamat = this.state.dt.alamat;
      let waktu_meninggal =
        new Date(this.state.waktu_meninggal).toISOString().slice(0, 10) +
        " " +
        new Date(this.state.waktu_meninggal)
          .toLocaleTimeString("id-ID")
          .replace(/\./g, ":");
      let tempat_meninggal = document.getElementById("tempat_meninggal").value;
      let sebab_meninggal = document.getElementById("sebab_meninggal").value;
      if (this.state.nik2Stat && this.state.nik2Stat !== null) {
        if (this.state.waktu_meninggal2) {
          waktu_meninggal +=
            "," +
            new Date(this.state.waktu_meninggal2).toISOString().slice(0, 10) +
            " " +
            new Date(this.state.waktu_meninggal2)
              .toLocaleTimeString("id-ID")
              .replace(/\./g, ":");
        }
        nik += "," + this.state.nik2Stat.value;
        if (this.state.dt.nama2) {
          nama += "," + this.state.dt.nama2;
        }
        if (this.state.dt.jenis_kelamin) {
          jenis_kelamin = this.state.dt.jenis_kelamin;
        }
        if (this.state.dt.tempat_lahir2) {
          tempat_lahir += "," + this.state.dt.tempat_lahir2;
        }
        if (this.state.dt.tanggal_lahir2) {
          tanggal_lahir += "," + tanggal_lahir2;
        }
        if (this.state.dt.jenis_kelamin2) {
          jenis_kelamin += "," + this.state.dt.jenis_kelamin2;
        }
        if (this.state.dt.pekerjaan2) {
          pekerjaan += "," + this.state.dt.pekerjaan2;
        }
        if (this.state.dt.kewarganegaraan2) {
          kewarganegaraan += "," + this.state.dt.kewarganegaraan2;
        }
        if (this.state.dt.agama2) {
          agama += "," + this.state.dt.agama2;
        }
        if (this.state.dt.alamat2) {
          alamat += "|" + this.state.dt.alamat2;
        }

        if (document.getElementById("tempat_meninggal2").value) {
          tempat_meninggal +=
            "," + document.getElementById("tempat_meninggal2").value;
        }
        if (document.getElementById("sebab_meninggal2").value) {
          sebab_meninggal +=
            "," + document.getElementById("sebab_meninggal2").value;
        }
      }
      let addSuratKeteranganAhliWaris = {
        uuid: document.getElementById("uuid").value,
        id_jenis: this.props.location.state.uuid,
        // nomor : document.getElementById("nomor").value,

        nik_pelapor: nik_pelapor,
        nik: nik,
        nama: nama,
        tempat_lahir: tempat_lahir,
        tanggal_lahir: tanggal_lahir,
        jenis_kelamin: jenis_kelamin,
        pekerjaan: pekerjaan,
        kewarganegaraan: kewarganegaraan,
        agama: agama,
        alamat: alamat,
        waktu_meninggal: waktu_meninggal,
        tempat_meninggal: tempat_meninggal,
        sebab_meninggal: sebab_meninggal,

        detail: this.state.detail
      };
      
      //console.log("Data Simpan Ahli Waris")
      //console.log(addSuratKeteranganAhliWaris)

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (addSuratKeteranganAhliWaris.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        addSuratKeteranganAhliWaris.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      Post(
        "surat-keterangan/ahli-waris/" + metode,
        addSuratKeteranganAhliWaris.uuid,
        addSuratKeteranganAhliWaris,
        res => {

          // For testing
          if (this.props.resSubmit) {
            this.props.resSubmit(res.status)
          }

          this.tutupForm();
          this.state.status.btnForm = false;
          this.state.status.form = false;
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Surat Keterangan Ahli Waris",
              pesanAlert: "Berhasil " + psn + " Data",
              successAlert: true
            });
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Surat Keterangan Ahli Waris",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        }
      );
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Belum Lengkap",
        pesanAlert: "Mohon Lengkapi Data Ahli Waris"
      });
    }
  };
  // ========================================================================
  // list disposisi

  listDisposisi = data => {
    //console.log(data);
    Get("alur-disposisi/" + data.id_jenis, null, dtkat => {
      //console.log(dtkat);
      if (dtkat) {
        //console.log(dtkat.results);

        this.setState({
          idSurat: data.uuid,
          datDisposisi: dtkat.results,
          formDisposisi: true,
          riwayatDisposisi: data.data_status_disposisi
        });
      }
    });
  };
  //disposisi
  disposisi = (label, id) => {
    //console.log(label);
    Get("disposisi/tidak-mampu/" + label + "/" + id, null, dtkat => {
      if (dtkat.code === 201) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Disposisi",
          pesanAlert: "Berhasil  Disposisi Data"
        });
        this.componentDidMount();
        this.tutupForm();
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Disposisi",
          pesanAlert: "Disposisi telah dilakukan"
        });
        this.tutupForm();
      }
    });
    //console.log(id);
  };
  // ============================== Ubah Data ===============================

  ubahData = data => {
    this.setState({ loadingPreview: true })
    this.state.judul = "Ubah Surat Ahli Waris";
    this.forceUpdate();
    // #continuethis
    // Set data update modal

    console.log(data);

    Get("penduduk/kk/" + data.data_pelapor.id_kk, null, dtkk => {
      let frmNik3 = [];
      dtkk.results.forEach(val => {
        frmNik3.push({ value: val.id, label: val.id + ' (' + val.nama + ')' });
      });

      this.setState({ 
        frmNik2: frmNik3,
        frmNik3,
      })
      console.log(frmNik3);

      let cekMultiple = data.nik.split(",")
  
      // Jika tidak ada data meninggal 2
      if (cekMultiple.length < 2) {
        this.setState({
          dt: {
            // ...this.state.dt,
            ...frmDex,
            uuid: data.uuid,
            id_jenis: data.id_jenis,
            nik_pelapor: data.nik_pelapor,
            nik: data.nik,
            kk: data.kk,
            nama: data.nama,
            tempat_lahir: data.tempat_lahir,
            tanggal_lahir: data.tanggal_lahir,
            jenis_kelamin: data.jenis_kelamin,
            pekerjaan: data.pekerjaan,
            kewarganegaraan: data.kewarganegaraan,
            // agama: data.agama,
            alamat: data.alamat,
            status_perkawinan: data.status_perkawinan,
            waktu_meninggal: new Date(data.waktu_meninggal),
            tempat_meninggal: data.tempat_meninggal,
            sebab_meninggal: data.sebab_meninggal,
            detail: data.detail_ahli_waris
          },
          meninggal2: "none",
          status: {
            ...this.state.status,
            dataPenduduk: "block",
            dataPenduduk2: "none",
            form: true,
          },
          // tanggal_surat: new Date(data.results.tanggal_surat),
          nikStat: {
            value: data.nik_pelapor,
            label: data.nik_pelapor + ' (' + data.nama + ')'
          },
          nik2Stat: null,
          loadingPreview: false
        })
      } else { // Ada data meninggal2
        this.setState({
          dt: {
            // ...this.state.dt,
            ...frmDex,
            uuid: data.uuid,
            id_jenis: data.id_jenis,
            kk: data.kk,
            nik_pelapor: data.nik_pelapor,
            nik: data.nik.split(',')[0],
            nik2: data.nik.split(',')[1],
            nama: data.nama.split(',')[0],
            nama2: data.nama.split(',')[1],
            tempat_lahir: data.tempat_lahir.split(',')[0],
            tempat_lahir2: data.tempat_lahir.split(',')[1],
            tanggal_lahir: new Date(data.tanggal_lahir.split(',')[0]),
            tanggal_lahir2: new Date(data.tanggal_lahir.split(',')[1]),
            jenis_kelamin: data.jenis_kelamin.split(',')[0],
            jenis_kelamin2: data.jenis_kelamin.split(',')[1],
            pekerjaan: data.pekerjaan.split(',')[0],
            pekerjaan2: data.pekerjaan.split(',')[1],
            kewarganegaraan: data.kewarganegaraan.split(',')[0],
            kewarganegaraan2: data.kewarganegaraan.split(',')[1],
            // agama: data.agama.split(',')[0],
            // agama2: data.agama.split(',')[1],
  
            alamat: data.alamat.split('|')[0],
            alamat2: data.alamat.split('|')[1],
   
            // status_perkawinan: data.status_perkawinan.split(',')[0],
            // status_perkawinan2: data.status_perkawinan.split(',')[1],
            waktu_meninggal: new Date(data.waktu_meninggal.split(',')[0]),
            waktu_meninggal2: new Date(data.waktu_meninggal.split(',')[1]),
            tempat_meninggal: data.tempat_meninggal.split(',')[0],
            tempat_meninggal2: data.tempat_meninggal.split(',')[1],
            sebab_meninggal: data.sebab_meninggal.split(',')[0],
            sebab_meninggal2: data.sebab_meninggal.split(',')[1],
            detail: data.detail_ahli_waris
          },
          meninggal2: "block",
          status: {
            ...this.state.status,
            dataPenduduk: "block",
            dataPenduduk2: "block",
            form: true,
          },
          nikStat: {
            value: data.nik_pelapor,
            label: data.nik_pelapor + ' (' + data.nama.split(',')[0] + ')'
          },
          nik2Stat: {
            value: data.nik.split(',')[1],
            label: data.nik.split(',')[1] + ' (' + data.nama.split(',')[1] + ')'
          },
          loadingPreview: false
        })
      }
    })


  };
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapus = i => {
    this.state.detail.splice(i, 1);
    this.forceUpdate();
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/ahli-waris/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Keterangan Ahli Waris",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Keterangan Ahli Waris",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.nikStat = null;
    this.state.dt.nama = "";
    this.state.dt.tanggal_lahir = "";
    this.state.dt.tempat_lahir = "";
    this.state.dt.agama = "";
    this.state.dt.pekerjaan = "";
    this.state.dt.kewarganegaraan = "";
    this.state.dt.alamat = "";
    this.state.dt.jenis_kelamin = "";
    this.state.dt.status_perkawinan = "";
    this.state.dt.tempat_meninggal = "";
    this.state.dt.sebab_meninggal = "";
    this.state.status.dataPenduduk = "none";
    this.state.nik2Stat = null;
    this.state.dt.nama2 = "";
    this.state.dt.tanggal_lahir2 = "";
    this.state.dt.tempat_lahir2 = "";
    this.state.dt.agama2 = "";
    this.state.dt.pekerjaan2 = "";
    this.state.dt.kewarganegaraan2 = "";
    this.state.dt.alamat2 = "";
    this.state.dt.jenis_kelamin2 = "";
    this.state.dt.status_perkawinan2 = "";
    this.state.dt.tempat_meninggal2 = "";
    this.state.dt.sebab_meninggal2 = "";
    this.state.status.dataPenduduk2 = "none";
    this.state.meninggal2 = "none";
    this.state.status.btnForm = false;
    this.state.detail = [];

    this.state.accStat = null;
    this.state.tableAcc = "none";
    this.state.formAcc = false;
    this.state.status.form = false;
    this.state.formDisposisi = false;

    this.forceUpdate();
  };

  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get(
      "surat-keterangan/ahli-waris/persetujuan/" + jawaban + "/" + id,
      null,
      res => {
        if (res.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  tombolAksi = (cell, row) => {
    // let btnApproval = false;
    // let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.forEach(dt => {
      status = dt.status;
    });
    if (status === "Diajukan") {
      // btnCetak = true;
      btnAksi = true;
      // btnApproval = true;
    } else {
      btnAksi = true;
      // btnApproval = true;
      if (status === "Ditolak") {
        // btnCetak = true;
      } else if (status === "Disetujui") {
        btnAksi = false;
        // btnCetak = true;

        // btnApproval = false;
      } else if ((status === "Menunggu Penerbitan") | (status === "Selesai")) {
        // btnAksi = false;
        // btnCetak = false;
        // btnApproval = false;
      } else {
        // btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={() => this.konfirmApprove(row.uuid)}
          disabled={btnApproval}
        >
          <i className="fa fa-check"></i>
        </Button>{" "}
        &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        {/* <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "} */}
        {/* 
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.cetakSurat(e, row)}
          disabled={btnCetak}
        >
          <i className="fa fa-print"></i>
        </Button>
        &nbsp;&nbsp; */}
        {/* //list */}
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.listDisposisi(row)}
        >
          <i className="fa fa-list"></i>
        </Button> */}
      </>
    );
  };

  tambahMeninggal = e => {
    // e.preventDefault();
    if (this.state.meninggal2 === "none") {
      this.setState({ meninggal2: "inherit" });
    } else {
      this.state.dt.nama2 = "";
      this.state.dt.tanggal_lahir2 = "";
      this.state.dt.tempat_lahir2 = "";
      this.state.dt.agama2 = "";
      this.state.dt.pekerjaan2 = "";
      this.state.dt.kewarganegaraan2 = "";
      this.state.dt.alamat2 = "";
      this.state.dt.jenis_kelamin2 = "";
      this.state.dt.status_perkawinan2 = "";
      this.state.status.dataPenduduk2 = "none";
      this.setState({
        meninggal2: "none",
        nik2Stat: null,
      });
      this.forceUpdate();
    }
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.fetch({ page: null, where: [] });
      let frmJam = [];
      let jamnya = "";
      for (let i = 1; i <= 24; i++) {
        jamnya = i;
        if (i < 10) {
          jamnya = "0" + i;
        }
        jamnya += ":00";
        frmJam.push({ value: jamnya, label: jamnya });
      }
      this.setState({ frmJam });
      Get('shdk/list', null, (data) => {
				let frmShdk = {};
				data.results.forEach((dt) => {
          frmShdk[dt.uuid] = dt.nama
				});
				this.setState({ frmShdk });
			});
      Get("pekerjaan/list", null, data => {
        let frmPkr = [];
        data.results.forEach(dt => {
          frmPkr.push({ value: dt.uuid, label: dt.nama });
        });
        this.setState({ frmPkr });
      });
      Get("agama/list", null, data => {
        let frmAgm = [];
        data.results.forEach(dt => {
          frmAgm.push({ value: dt.uuid, label: dt.nama });
        });
        this.setState({ frmAgm });
      });
      Get("penduduk", null, data => {
        let frmNik = [];
        // let frmNik2 = [];
        // let frmNik3 = [];
        data.results.data.forEach(dt => {
          frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          // frmNik2.push({ value: dt.id, label: dt.id });
          // frmNik3.push({ value: dt.id, label: dt.id });
        });
        this.setState({ 
          frmNik, 
          frmNik2 : frmNik, 
          frmNik3 : frmNik 
        });

        // For testing
        if (this.props.getListNIK) {
          this.props.getListNIK(frmNik)
        }
      });
      Get("tanda-tangan/list", null, data => {
        data.results.forEach((dt, i) => {
          if (dt.aktif === 1) {
            let temp = this.state.accButton.forEach(x => 1);
            temp[i] = 0;
            this.state.accButton = temp;
            this.state.accuuid = dt.uuid;
          }
        });
        this.forceUpdate();
        this.setState({ frmTTD: data.results });
      });
    } else {
      this.setState({ redirect: true });
    }
  }

  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        let nikStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik = [];
          datanik.data.results.data.forEach(dt => {
            frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmNik, nikStat });
        });
      } else if (fld === "nik2") {
        let nik2Stat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik2 = [];
          datanik.data.results.data.forEach(dt => {
            frmNik2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmNik2, nik2Stat });
        });
      } else {
        let nik3Stat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik3 = [];
          datanik.data.results.data.forEach(dt => {
            frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmNik3, nik3Stat });
        });
      }
    }
  };

  getPenduduk = (e, sel) => {
    this.state.status.btnForm = true;
    this.forceUpdate();
    if (e) {
      this.changeSelectValue(e, sel);
      Get("penduduk/find", e.value, data => {
        let alamat = '';
        if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
          alamat =
            " RT. " +
            data.results.data_kk.data_rt.nama +
            "/RW. " +
            data.results.data_kk.data_rt.data_rw.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_keldes.nama +
            ", Kec. " +
            data.results.data_kk.data_rt.data_rw.data_keldes
              .data_kecamatan.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_keldes
              .data_kecamatan.data_kabkota.nama;
        } else {
          if (data.results.data_kk.id_rt === '0') {
            alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama +
              ", Kec. " + data.results.data_keldes.data_kecamatan.nama +
              ", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
              ", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama;
          } else {
            alamat =
              " RT. " +
              data.results.data_kk.data_rt.nama +
              "/RW. " +
              data.results.data_kk.data_rt.data_rw.nama +
              ", " + JENIS_DUSUN + " " +
              data.results.data_kk.data_rt.data_rw.data_dusun.nama +
              ", " + localStorage.getItem('JENIS_DESA') + " " +
              data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
              ", Kec. " +
              data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
                .data_kecamatan.nama +
              ", " +
              data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
                .data_kecamatan.data_kabkota.nama;
          }
        }

        if (sel === "nik2") {
          this.state.tanggal_lahir2 = data.results.tanggal_lahir;
          this.state.status.btnForm = false;

          this.state.dt.nama2 = data.results.nama;
          this.state.dt.tanggal_lahir2 = new Date(
            data.results.tanggal_lahir
          ).toLocaleDateString("id-ID");
          this.state.dt.tempat_lahir2 = data.results.tempat_lahir;
          this.state.dt.jenis_kelamin2 = data.results.jk;
          this.state.dt.status_perkawinan2 = data.results.status_perkawinan;
          this.state.dt.agama2 = data.results.data_agama.nama;
          this.state.dt.pekerjaan2 = data.results.data_pekerjaan.nama;
          this.state.dt.kewarganegaraan2 = "Indonesia";
          this.state.dt.alamat2 = alamat;
          this.state.status.dataPenduduk2 = "block";

          // For testing
          if (this.props.selectOnChange2) {
            this.props.selectOnChange2(this.state.dt)
          }
        } else {
          if (sel === "nik3") {
            aw.nik = e.value;
            aw.nama = data.results.nama;
            aw.jenis_kelamin = data.results.jenis_kelamin;
            aw.tanggal_lahir = data.results.tanggal_lahir;
            aw.tempat_lahir = data.results.tempat_lahir;
            aw.pekerjaan = data.results.data_pekerjaan.nama;
          } else {
            Get("catatan-penduduk/nik", data.results.id, dpddk => {
              // let pctt = 0;
              // let catatan = "";
              if (dpddk.results.data) {
                dpddk.results.data.forEach(dtp => {
                  if (dtp.status === 0 && dtp.penalti === 1) {
                    // pctt = 1;
                    // catatan = dtp.catatan;
                  }
                });
              }
              this.state.tanggal_lahir = data.results.tanggal_lahir;
              this.state.dt.nama = data.results.nama;
              this.state.dt.tanggal_lahir = new Date(
                data.results.tanggal_lahir
              ).toLocaleDateString("id-ID");
              this.state.dt.tempat_lahir = data.results.tempat_lahir;
              this.state.dt.jenis_kelamin = data.results.jk;
              this.state.dt.status_perkawinan =
                data.results.status_perkawinan;
              this.state.dt.agama = data.results.data_agama.nama;
              this.state.dt.pekerjaan = data.results.data_pekerjaan.nama;
              this.state.dt.kewarganegaraan = "Indonesia";
              this.state.dt.alamat = alamat;
              this.state.status.dataPenduduk = "block";
              this.state.status.btnForm = false;
              this.forceUpdate();
              // if (pctt === 0) {
              // } else {
              //   this.state.nikStat = null;
              //   // this.state.frmNik = [];
              //   this.state.dt.nama = "";
              //   this.state.dt.tanggal_lahir = "";
              //   this.state.dt.tempat_lahir = "";
              //   this.state.dt.agama = "";
              //   this.state.dt.pekerjaan = "";
              //   this.state.dt.kewarganegaraan = "";
              //   this.state.dt.alamat = "";
              //   this.state.dt.jenis_kelamin = "";
              //   this.state.dt.status_perkawinan = "";
              //   this.state.dt.tempat_meninggal = "";
              //   this.state.dt.sebab_meninggal = "";
              //   this.state.status.dataPenduduk = "none";
              //   this.state.status.btnForm = true;
              //   this.forceUpdate();
              //   this.setState({
              //     show: true,
              //     basicType: "danger",
              //     basicTitle: "Peringatan Catatan Warga",
              //     pesanAlert:
              //       "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
              //       catatan
              //   });
              // }
              
              // For testing
              if (this.props.selectOnChange) {
                this.props.selectOnChange(this.state.dt)
              }
            });
          }
        }
        this.forceUpdate();
      });
    } else {
      if (sel === "nik2") {
        this.state.nik2Stat = null;
        this.state.dt.nama2 = "";
        this.state.dt.tanggal_lahir2 = "";
        this.state.dt.tempat_lahir2 = "";
        this.state.dt.agama2 = "";
        this.state.dt.pekerjaan2 = "";
        this.state.dt.kewarganegaraan2 = "";
        this.state.dt.alamat2 = "";
        this.state.dt.jenis_kelamin2 = "";
        this.state.dt.status_perkawinan2 = "";
        this.state.dt.tempat_meninggal2 = "";
        this.state.dt.sebab_meninggal2 = "";
        this.state.status.dataPenduduk2 = "none";
        this.state.status.btnForm = false;
      } else {
        this.state.nikStat = null;
        this.state.dt.nama = "";
        this.state.dt.tanggal_lahir = "";
        this.state.dt.tempat_lahir = "";
        this.state.dt.agama = "";
        this.state.dt.pekerjaan = "";
        this.state.dt.kewarganegaraan = "";
        this.state.dt.alamat = "";
        this.state.dt.jenis_kelamin = "";
        this.state.dt.status_perkawinan = "";
        this.state.dt.tempat_meninggal = "";
        this.state.dt.sebab_meninggal = "";
        this.state.status.dataPenduduk = "none";
      }
      this.forceUpdate();
    }
  };

  changeInputValue = (e, sel) => {
    switch (sel) {
      case "shdk":
        aw.shdk = e.target.value;
        this.forceUpdate();
        break;
      default:
        // no default
        break;
    }
  };

  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jam":
          this.state.dt.jam = e.value;
          this.setState({ jamStat: e });
          break;
        case "pekerjaan":
          this.state.dt.pekerjaan = e.label;
          this.setState({ pkrStat: e });
          break;
        case "agama":
          this.state.dt.agama = e.label;
          this.setState({ agmStat: e });
          break;
        case "nik":
          this.state.dt.nik = e.value;
          this.setState({ nikStat: e });
          break;
        case "nik2":
          this.state.dt.nik2 = e.value;
          this.setState({ nik2Stat: e });
          break;
        case "nik3":
          this.state.dt.nik3 = e.value;
          this.setState({ nik3Stat: e });
          
          // For testing
          if (this.props.selectOnChange3) {
            this.props.selectOnChange3(e)
          }
          break;
        case 'shdk_aw':
          this.state.shdk_aw = e.value;
          this.setState({ shdk_aw: e });

           
          // For testing
          if (this.props.selectOnChangeShdk) {
            this.props.selectOnChangeShdk(e.value)
          }
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "jam":
          this.state.dt.jam = 0;
          this.setState({ jamStat: e });
          break;
        case "pekerjaan":
          this.state.dt.pekerjaan = "";
          this.setState({ pkrStat: e });
          break;
        case "agama":
          this.state.dt.agama = "";
          this.setState({ agmStat: e });
          break;
        case "nik":
          this.state.dt.nik = 0;
          this.setState({ nikStat: e });
          break;
        case "nik2":
          this.state.dt.nik2 = 0;
          this.setState({ nik2Stat: e });
          break;
        case "nik3":
          this.state.dt.nik3 = 0;
          this.setState({ nik3Stat: e });
          break;
        case 'shdk_aw':
          this.state.shdk_aw = 0;
          this.setState({ shdk_aw: e });
          break;
        default:
          // no default
          break;
      }
    }

    this.forceUpdate();
  };

  tambahAw = e => {
    if (this.state.nik3Stat !== null && this.state.shdk_aw) {
      detail = this.state.detail;
      Get("penduduk/find", this.state.nik3Stat.value, data => {
        if (data.results) {
          detail.push({
            nik: this.state.nik3Stat.value,
            nama: data.results.nama,
            jenis_kelamin: data.results.jk,
            tanggal_lahir: data.results.tanggal_lahir,
            tempat_lahir: data.results.tempat_lahir,
            pekerjaan: data.results.data_pekerjaan.nama,
            shdk: this.state.shdk_aw.value,
            keterangan: ""
          });
          this.setState({ detail });
          this.state.nik3Stat = null
          this.state.shdk_aw = null
          // this.state.frmNik3 = []
          this.componentDidMount();
          this.forceUpdate();

          
          // For testing
          if (this.props.getDataKeluarga) {
            this.props.getDataKeluarga(detail)
          }
        }
      });
    } else {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Surat Keterangan Ahli Waris",
        pesanAlert: "Pilih NIK dan hubungan keluarga terlebih dahulu"
      });
    }
  };

  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  //change select
  changeAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.state.accStat = e;
          break;
        default:
          // no default
          break;
      }
      if (e.value === "Disetujui") {
        this.state.tableAcc = "";
        this.state.btnFormAcc = true;
        if (this.state.accButton.length > 0) {
          this.state.btnFormAcc = false;
        }
      } else {
        this.state.tableAcc = "none";
        this.state.btnFormAcc = false;
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.state.accStat = e;
          break;
        default:
          // no default
          break;
      }
      this.state.tableAcc = "none";
      this.state.btnFormAcc = true;
    }
    this.state.status.select = false;
    this.forceUpdate();
  };

  simpanAcc = e => {
    e.preventDefault();
    this.state.btnFormAcc = true;

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),
      uuid: this.state.accuuid
    };
    Post(
      "surat-keterangan/ahli-waris/persetujuan/Selesai/" +
      this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            btnFormAcc: false,
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
    this.tutupForm();
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.forEach(x => 1);
    temp[id] = 0;
    this.state.accButton = temp;
    this.state.accuuid = uuid;
    if (this.state.accStat) {
      this.state.btnFormAcc = false;
    }
    this.forceUpdate();
  };

  render() {
    //console.log("Data Render")
    //console.log("Data Ahli Waris")
    //console.log("====================")
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;

    const shdk = [
      { value: 'Suami', label: 'Suami' },
      { value: 'Isteri', label: 'Isteri' },
      { value: 'Orang Tua', label: 'Orang Tua' },
      { value: 'Anak', label: 'Anak' },
      { value: 'Kakak', label: 'Kakak' },
      { value: 'Adik', label: 'Adik' },
      { value: 'Mertua', label: 'Mertua' },
      { value: 'Menantu', label: 'Menantu' },
      { value: 'Cucu', label: 'Cucu' },
      { value: 'Pembantu', label: 'Pembantu' },
      { value: 'Famili Lain', label: 'Famili Lain' }
    ];

    const frmDef = {
      uuid: "",
      id_jenis: "",
      nomor: "",
      tanggal_surat: new Date(),

      nik: "",
      nama: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      jenis_kelamin: "",
      pekerjaan: "",
      kewarganegaraan: "",
      agama: "",
      alamat: "",
      status_perkawinan: "",
      waktu_meninggal: new Date(),
      tempat_meninggal: "",
      sebab_meninggal: "",

      nik2: "",
      nama2: "",
      tempat_lahir2: "",
      tanggal_lahir2: "",
      jenis_kelamin2: "",
      pekerjaan2: "",
      kewarganegaraan2: "",
      agama2: "",
      alamat2: "",
      status_perkawinan2: "",
      waktu_meninggal2: new Date(),
      tempat_meninggal2: "",
      sebab_meninggal2: "",

      status_ttd: "",
      jabatan_ttd: "",
      jabatan_pengganti_ttd: "",
      nama_ttd: "",
      nip_ttd: "",
      status_ttd2: "",
      jabatan_ttd2: "",
      jabatan_pengganti_ttd2: "",
      nama_ttd2: "",
      nip_ttd2: ""
    };
    let no = 0;
    const ahliwaris = this.state.detail.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.nama}</td>
          <td>
            {dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
          </td>
          <td>{dt.pekerjaan}</td>
          <td>{dt.shdk}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.hapus(i)}
              color="danger"
              className="btn-icon"
            >
              <i className="fa fa-trash"></i>
            </Button>
          </td>
        </tr>
      );
    });

    // const { SearchBar } = Search;
    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          headerAlign: 'center',
				  align: 'center',
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.pagination.current_page;
              let total_records_per_page = this.state.pagination.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
        dataField: "tanggal_surat",
        headerAlign: 'center',
				align: 'center',
        text: "Tanggal",
        formatter: (cell, obj) => {
          let plainTime = '-'
					if (obj.created_at) {
						let date = new Date(obj.created_at)
						date = date.toLocaleString('id-ID')
						
						const datetime = date.split(' ')
						
	
						date = datetime[0]
						let time = datetime[1]
	
						const splitDate = date.split('/')
						time = time.replaceAll('.', ':')
	
						console.log(date)
						const list_bulan = [
							'Januari',
							'Februari',
							'Maret',
							'April',
							'Mei',
							'Juni',
							'Juli',
							'Agustus',
							'September',
							'Oktober',
							'November',
							'Desember'
						];

						plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}  ${time}`;
					}

					return plainTime
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        headerAlign: 'center',
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor === "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "nik",
        headerAlign: 'center',
        text: "NIK",
        isDummyField: true,
        formatter: (cell, data) => {
          let niks = data.nik.split(",");
          let nik = `<ol>`;
          niks.forEach(dt => {
            nik += `<li> ${dt} </li>`
          })
          nik += `</ol>`
          var htmlToReactParser = new HtmlToReactParser();
          var reactElement = htmlToReactParser.parse(nik);

          // let nik = data.nik;
          // if (niks[1] === "undefined") {
          //   nik = niks[0];
          // }
          return reactElement;
        },
        sort: true
      },
      {
        dataField: "nama",
        headerAlign: 'center',
        text: "Nama",
        align: "right",
        isDummyField: true,
        formatter: (cell, obj) => {
          // return obj.nama.replace(",", " & ");
          let namas = obj.nama.split(",");
          let nama = `<ol>`;
          namas.forEach(dt => {
            nama += `<li> ${dt} </li>`
          })
          nama += `</ol>`
          var htmlToReactParser = new HtmlToReactParser();
          var reactElement = htmlToReactParser.parse(nama);

          // let nik = data.nik;
          // if (niks[1] === "undefined") {
          //   nik = niks[0];
          // }
          return reactElement;
        },
        sort: true
      },
      {
        dataField: "status",
        headerAlign: 'center',
				align: 'center',
        text: "Status",
        isDummyField: true,
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.forEach(dt => {
            status = dt.status;
          });
          return status;
        },
        sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-primary-custom";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href="#" onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };
    no = 0;
    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] === 0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] === 0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <div data-testid="page-surat keterangan ahli waris">
        {/* <Breadcrumb title="Data Surat Keterangan Ahli Waris" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                // className="btn btn-primary-custom"
                onClick={() => {
                  this.closeAlert();
                  this.bukaForm();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                // color="info"
                className="btn btn-secondary-custom"
                // onClick={() => {
                //   this.closeAlert();
                // }}
              >
                <Link
                  data-testid="btn-eksekusi_surat"
                  onClick={() => {
                    // For testing
                    if (this.props.clickEksekusiSurat) {
                      this.props.clickEksekusiSurat()
                    }
                  }}
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
              ""
            )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Keterangan Ahli Waris</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        data-testid="tblCreateSuratAhliWaris"
                        size="sm"
                        color="success"
                        // className="btn-square"
                        // className="btn btn-primary-custom"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Surat Keterangan Ahli Waris",
                            dt: null,
                            nikStat: null,
                            nik2Stat: null,
                            meninggal2: "none",
                            detail: [],
                            status: {
                              ...this.state.status,
                              dataPenduduk: "none",
                              dataPenduduk2: "none",
                              form: true
                            }
                          });
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        {/* Loading preview */}
        <SweetAlert
					show={this.state.loadingPreview}
					showCancel = {false}
					showConfirm={false}
				>
					<div align="center">
						<h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
						<br/>
						<img 
							alt="loading"
							src={require("../assets/images/loading-preview.gif")} 
							style={{
								// width: "100%",
								borderRadius: "10px",
								width: '150px'
							}}
						/> 
						<br/><br/>
					</div>
				</SweetAlert>

        <ModalSuratAhliWaris
          data={this.state.dt}
          isOpen={this.state.status.form}
          propsData={this.props}
          optionData={{
            frmNik: this.state.frmNik,
            frmNik2: this.state.frmNik2,
            frmNik3: this.state.frmNik3,
            frmShdk: this.state.frmShdk,
            nikStat: this.state.nikStat,
            nik2Stat: this.state.nik2Stat,
            status: this.state.status,
            meninggal2: this.state.meninggal2
          }}
          setAlert={(e) => {
            this.setState(e)
          }}
          setStatusRefresh={(e) => {
            if (e) {
              this.fetch({ page: null, where: [] });
            }
          }}
          setIsOpen={(e) => {
            if (!e) {
              this.setState({ dt: null })
            }
            this.setState({
              status: {
                ...this.state.status,
                form: e
              }
            })
          }}
        />

        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              {/* <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              /> */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  />
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary-custom">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            {/* <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Jabatan</th>
                    <th>Level</th>
                    <th>TTD</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.datDisposisi.map((dt,i)=>{
                 return(
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>{dt.data_jabatan.nama}</td>
                    <td>{dt.level}</td>
                    <td>

                    <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => {
                      this.disposisi(dt.uuid,this.state.idSurat)}
                    }
                    disabled={this.state.status.btnForm}
                  >
                    Disposisi
                  </button>
                    </td>
                  </tr>
                   )})}
                </tbody>
              </table>
            </div>
             */}
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.riwayatDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status === 0
                            ? "Menunggu Disposisi"
                            : dt.status === 1
                              ? "Telah di disposisi"
                              : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default BasicTable;
