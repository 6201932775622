import React, { Component } from "react";

import SweetAlert from "react-bootstrap-sweetalert";

// koneksi
import { Post, Get, Delete, ID_DESA, GetEksternal} from "./../../../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Link } from "react-router-dom";

// koneksi
import { LAYANAN_CAPIL } from "../../../function/Koneksi";

let LIST_LAYANAN = JSON.parse(LAYANAN_CAPIL)
//json file
// var data = require('../assets/json/company');

const frmDef = {
  id: 0,
  idbaru: "",
  id_keldes: "",
  nama: ""
};


class DafarPelayananDisdukcapil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Data table
      data: [],

      status: {
        form: false,
        formTambahVerifikasi: false,
        formKeterangan: false,
        formDetailGambar: false,
        btnForm: false,
        btnAksi: false,
        btnAksiTidakGelar: false,
        select: false,
      },
      
      // =================================
      // =========== Alert ============
      alert: null,
      show: false,
      showCetakSurat: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // ==============================
    
      // Layanan Capil
      layananCapil: [],
      loading: true,
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      showCetakSurat: false,
      alert: null
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  componentDidMount() {

    Get("keldes/find/"+ID_DESA, null, lihat => {
      console.log('Wilayah')
      console.log(lihat)
      console.log(lihat.results.data_kecamatan.id_kabkota)
      if(lihat.results){
        GetEksternal("pelayanan-instansi/desa/Capil/"+ lihat.results.data_kecamatan.id_kabkota + '/Pencatatan Sipil', null, dtkat => {
          console.log('List Layanan Capil')
          console.log(dtkat)
          if (dtkat.results) {
            this.setState({ layananCapil: dtkat.results, loading: false })
          }
          else{
            this.setState({ loading: false })
          }
        });
      }
    });

    // Tampilan Lama
    // LIST_LAYANAN = JSON.parse(localStorage.getItem('LAYANAN_CAPIL'))
    // Tampilan Lama
 
    this.forceUpdate();
  }

  render() {
    const tampilLayananCapil = (
      <>
				{this.state.layananCapil.map((val, i) => {
					let link = "";
          let title = "";
          console.log('data list capil')
          console.log(val)
					switch (val.data_pelayanan.nama.toLowerCase()) {
						case 'akta kelahiran':
							link = "/admin/layanan/capil/kelahiran";
              title = "Penerbitan Akta Kelahiran";
							break;
						case 'akta kematian':
              link = "/admin/layanan/capil/kematian";
              title = "Penerbitan Akta Kematian";
							break;
						case 'pindah datang':
              link = "/admin/layanan/capil/pindah-datang";
              title = "Penerbitan Pindah/Datang Keluar Daerah";
							break;
						case 'kartu keluarga':
              link = "/admin/layanan/capil/kartu-keluarga";
              title = "Penerbitan Kartu Keluarga";
							break;
						case 'penambahan anak':
              link = "/admin/layanan/capil/kartu-keluarga";
              title = "Penambahan Anak";
							break;
						case 'perubahan data':
              link = "/admin/layanan/capil/kartu-keluarga";
              title = "Perubahan Data";
							break;
						case 'pecah kk':
              link = "/admin/layanan/capil/kartu-keluarga";
              title = "Pecah KK";
							break;
						case 'akta perkawinan':
              link = "/admin/layanan/coming_soon";
              title = "Penerbitan Akta Perkawinan";
							break;
						case 'akta perceraian':
              link = "/admin/layanan/coming_soon";
              title = "Penerbitan Akta Perceraian";
							break;
						case 'kartu tanda penduduk':
              link = "/admin/layanan/coming_soon";
              title = "Penerbitan KTP";
							break;
						case 'kartu identitas anak':
              link = "/admin/layanan/coming_soon";
              title = "Kartu Identitas Anak";
							break;
            default:
              // no default
              break;
					}

          if (link !== '') {
            return (
              <div className="col-sm-3 mb-4">
                <Link to={{ pathname: link, state: val }}>
                  <div className="card o-hidden mb-0">
                    <div
                      className="card-body p-3 mr-0 text-white"
                      style={{ backgroundColor: '#2e99b0' }}
                    >
                      <div className="media static-top-widget">
                        <div
                          className="media-body p-0"
                          style={{
                            lineHeight: '1.5em',
                            minHeight: '6.5em',
                            height: '100%',
                            fontWeight: 'bold',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <span
                            className="m-0 p-0"
                            style={{ fontSize: '18px' }}
                          >
                            {title}
                          </span>
                          <i className="icofont icofont-ebook icon-bg text-white" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )
          } else {
            return ''
          }

				})}
      </>
		);

    // Tampilan Lama
    // const tampilLayanan = Object.keys(LIST_LAYANAN).map((val, index) => {
    //   let link = "";
    //   let title = "";
    //   let condition = false;

    //   switch (val.toLowerCase()) {
    //     case "akta_kematian":
    //       link = "kematian";
    //       title = "Penerbitan Akta Kematian";

    //       if (LIST_LAYANAN.akta_kematian) {
		// 				condition = true
		// 			}
    //     break;
    //     case "kelahiran":
    //       link = "kelahiran";
    //       title = "Penerbitan Akta Kelahiran";

    //       if (LIST_LAYANAN.kelahiran) {
		// 				condition = true
		// 			}
    //     break;
    //     // case "pecah_kk":
    //     //   link = "kartu-keluarga";
    //     //   title = "Penerbitan Kartu Keluarga";

    //     //   if (LIST_LAYANAN.perubahan_data && LIST_LAYANAN.penambahan_anak && LIST_LAYANAN.pecah_kk) {
		// 		// 		condition = true
		// 		// 	}
    //     // break;
    //     // case "penambahan_anak":
    //     //   link = "kartu-keluarga";
    //     //   title = "Penerbitan Kartu Keluarga";

    //     //   if (LIST_LAYANAN.perubahan_data && LIST_LAYANAN.penambahan_anak && LIST_LAYANAN.pecah_kk) {
		// 		// 		condition = true
		// 		// 	}
    //     // break;
    //     // case "perubahan_data":
    //     //   link = "kartu-keluarga";
    //     //   title = "Penerbitan Kartu Keluarga";

    //     //   if (LIST_LAYANAN.perubahan_data && LIST_LAYANAN.penambahan_anak && LIST_LAYANAN.pecah_kk) {
		// 		// 		condition = true
		// 		// 	}
    //     // break;
    //     case "pindah_datang":
    //       link = "pindah-datang";
    //       title = "Penerbitan Pindah/Datang Keluar Daerah";

    //       if (LIST_LAYANAN.pindah_datang) {
		// 				condition = true
		// 			}
    //     break;
    //     default:
    //       break;
    //   }

    //   if (condition) {
    //     return (
    //       <div className="col-sm-3 mb-4">
    //         <Link to={{ pathname: '/admin/layanan/capil/'+link }}>
    //           <div className="card o-hidden mb-0">
    //             <div
    //               className="card-body p-3 mr-0 text-white"
    //               style={{ backgroundColor: '#2e99b0' }}
    //             >
    //               <div className="media static-top-widget">
    //                 <div
    //                   className="media-body p-0"
    //                   style={{
    //                     lineHeight: '1.5em',
    //                     minHeight: '6.5em',
    //                     height: '100%',
    //                     fontWeight: 'bold',
    //                     paddingTop: '0px',
    //                     paddingBottom: '0px',
    //                     display: 'flex',
    //                     alignItems: 'center'
    //                   }}
    //                 >
    //                   <span
    //                     className="m-0 p-0"
    //                     style={{ fontSize: '18px' }}
    //                   >
    //                     {title}
    //                   </span>
    //                   <i className="icofont icofont-ebook icon-bg text-white" />
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </Link>
    //       </div>
    //     );
    //   } else {
		// 		return ''
		// 	}
    // });

    // End Tampilan Lama

  

    return (
      <div>
        {/* <Breadcrumb title="Disposisi Surat" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <br/>
                  {
                    this.state.loading?
                      <div className="row" data-testid="divLoadingDashboard">
                        <div className="col-sm-12" align="center">
                          <br/><br/><br/>
                          <img 
                          src={require("../../../assets/images/loading-bos.gif")} 
                          alt="loading"
                          style={{
                            // width: "100%",
                            borderRadius: "10px",
                            width: '95px'
                          }}/> 
                          <br/><br/><br/><br/>
                        </div>
                      </div>
                    :
                    <>
                      <div
                        className="row"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          justifyContent: 'start'
                        }}
                      >
                        {
                          this.state.layananCapil.length===0
                          ?
                          <>
                            <div className="card-body datatable-react">
                              <div className="col-12 text-right">
                                  <button onClick={this.props.history.goBack} color="info" className="btn btn-info"><i className="fa fa-arrow-left"></i> Kembali</button>
                              </div>
                              <h2 align="center">Layanan Tidak Ditemukan!</h2>
                              <h6><p align="center">Wah, layanan yang kamu inginkan akan segera hadir ! <br/> Tunggu update selanjutnya ya !</p></h6>
                              <br/>
                            </div>
                          </>
                          :
                          <>
                            {tampilLayananCapil}
                          </>
                        }

                          {/* {
                              LIST_LAYANAN.pecah_kk === true || LIST_LAYANAN.penambahan_anak === true || LIST_LAYANAN.perubahan_data === true
                              ?
                                <div className="col-sm-3 mb-4">
                                  <Link to={{ pathname: '/admin/layanan/capil/kartu-keluarga' }}>
                                    <div className="card o-hidden mb-0">
                                      <div
                                        className="card-body p-3 mr-0 text-white"
                                        style={{ backgroundColor: '#2e99b0' }}
                                      >
                                        <div className="media static-top-widget">
                                          <div
                                            className="media-body p-0"
                                            style={{
                                              lineHeight: '1.5em',
                                              minHeight: '6.5em',
                                              height: '100%',
                                              fontWeight: 'bold',
                                              paddingTop: '0px',
                                              paddingBottom: '0px',
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <span
                                              className="m-0 p-0"
                                              style={{ fontSize: '18px' }}
                                            >
                                              Penerbitan Kartu Keluarga
                                            </span>
                                            <i className="icofont icofont-ebook icon-bg text-white" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              :
                              ""
                            } */}
                    
                        {/* <div className="col-sm-3 mb-4">
                          <Link to={{ pathname: '/admin/layanan/capil/kelahiran' }}>
                            <div className="card o-hidden mb-0">
                              <div
                                className="card-body p-3 mr-0 text-white"
                                style={{ backgroundColor: '#2e99b0' }}
                              >
                                <div className="media static-top-widget">
                                  <div
                                    className="media-body p-0"
                                    style={{
                                      lineHeight: '1.5em',
                                      minHeight: '6.5em',
                                      height: '100%',
                                      fontWeight: 'bold',
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <span
                                      className="m-0 p-0"
                                      style={{ fontSize: '18px' }}
                                    >
                                      Penerbitan Akta Kelahiran
                                    </span>
                                    <i className="icofont icofont-ebook icon-bg text-white" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-sm-3 mb-4">
                          <Link to={{ pathname: '/admin/layanan/capil/pindah-datang' }}>
                            <div className="card o-hidden mb-0">
                              <div
                                className="card-body p-3 mr-0 text-white"
                                style={{ backgroundColor: '#2e99b0' }}
                              >
                                <div className="media static-top-widget">
                                  <div
                                    className="media-body p-0"
                                    style={{
                                      lineHeight: '1.5em',
                                      minHeight: '6.5em',
                                      height: '100%',
                                      fontWeight: 'bold',
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <span style={{ fontSize: '18px' }}>
                                      Penerbitan Pindah / Datang Keluar Daerah
                                    </span>
                                    <i className="icofont icofont-ebook icon-bg text-white" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-sm-3 mb-4">
                          <Link to={{ pathname: '/admin/layanan/capil/kematian' }}>
                            <div className="card o-hidden mb-0">
                              <div
                                className="card-body p-3 mr-0 text-white"
                                style={{ backgroundColor: '#2e99b0' }}
                              >
                                <div className="media static-top-widget">
                                  <div
                                    className="media-body p-0"
                                    style={{
                                      lineHeight: '1.5em',
                                      minHeight: '6.5em',
                                      height: '100%',
                                      fontWeight: 'bold',
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <span
                                      className="m-0 p-0"
                                      style={{ fontSize: '18px' }}
                                    >
                                      Penerbitan Akta Kematian
                                    </span>
                                    <i className="icofont icofont-ebook icon-bg text-white" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>

                        <div className="col-sm-3 mb-4">
                          <Link to={{ pathname: '/admin/layanan/coming_soon' }}>
                            <div className="card o-hidden mb-0">
                              <div
                                className="card-body p-3 mr-0 text-white"
                                style={{ backgroundColor: '#2e99b0' }}
                              >
                                <div className="media static-top-widget">
                                  <div
                                    className="media-body p-0"
                                    style={{
                                      lineHeight: '1.5em',
                                      minHeight: '6.5em',
                                      height: '100%',
                                      fontWeight: 'bold',
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <span
                                      className="m-0 p-0"
                                      style={{ fontSize: '18px' }}
                                    >
                                      Penerbitan Akta Perkawinan
                                    </span>
                                    <i className="icofont icofont-ebook icon-bg text-white" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-sm-3 mb-4">
                          <Link to={{ pathname: '/admin/layanan/coming_soon' }}>
                            <div className="card o-hidden mb-0">
                              <div
                                className="card-body p-3 mr-0 text-white"
                                style={{ backgroundColor: '#2e99b0' }}
                              >
                                <div className="media static-top-widget">
                                  <div
                                    className="media-body p-0"
                                    style={{
                                      lineHeight: '1.5em',
                                      minHeight: '6.5em',
                                      height: '100%',
                                      fontWeight: 'bold',
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <span
                                      className="m-0 p-0"
                                      style={{ fontSize: '18px' }}
                                    >
                                      Penerbitan Akta Perceraian
                                    </span>
                                    <i className="icofont icofont-ebook icon-bg text-white" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-sm-3 mb-4">
                          <Link to={{ pathname: '/admin/layanan/capil/kartu-keluarga' }}>
                            <div className="card o-hidden mb-0">
                              <div
                                className="card-body p-3 mr-0 text-white"
                                style={{ backgroundColor: '#2e99b0' }}
                              >
                                <div className="media static-top-widget">
                                  <div
                                    className="media-body p-0"
                                    style={{
                                      lineHeight: '1.5em',
                                      minHeight: '6.5em',
                                      height: '100%',
                                      fontWeight: 'bold',
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <span
                                      className="m-0 p-0"
                                      style={{ fontSize: '18px' }}
                                    >
                                      Penerbitan Kartu Keluarga
                                    </span>
                                    <i className="icofont icofont-ebook icon-bg text-white" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>

                        <div className="col-sm-3 mb-4">
                          <Link to={{ pathname: '/admin/layanan/coming_soon' }}>
                            <div className="card o-hidden mb-0">
                              <div
                                className="card-body p-3 mr-0 text-white"
                                style={{ backgroundColor: '#2e99b0' }}
                              >
                                <div className="media static-top-widget">
                                  <div
                                    className="media-body p-0"
                                    style={{
                                      lineHeight: '1.5em',
                                      minHeight: '6.5em',
                                      height: '100%',
                                      fontWeight: 'bold',
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <span
                                      className="m-0 p-0"
                                      style={{ fontSize: '18px' }}
                                    >
                                      Penerbitan Kartu Tanda Penduduk
                                    </span>
                                    <i className="icofont icofont-ebook icon-bg text-white" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-sm-3 mb-4">
                          <Link to={{ pathname: '/admin/layanan/coming_soon' }}>
                            <div className="card o-hidden mb-0">
                              <div
                                className="card-body p-3 mr-0 text-white"
                                style={{ backgroundColor: '#2e99b0' }}
                              >
                                <div className="media static-top-widget">
                                  <div
                                    className="media-body p-0"
                                    style={{
                                      lineHeight: '1.5em',
                                      minHeight: '6.5em',
                                      height: '100%',
                                      fontWeight: 'bold',
                                      paddingTop: '0px',
                                      paddingBottom: '0px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <span
                                      className="m-0 p-0"
                                      style={{ fontSize: '18px' }}
                                    >
                                      Penerbitan Kartu Identitas Anak (KIA)
                                    </span>
                                    <i className="icofont icofont-ebook icon-bg text-white" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div> */}
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

      </div>
    );
  }
}

export default DafarPelayananDisdukcapil;