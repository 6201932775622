import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Form, } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// koneksi
import { Get, JENIS_DESA } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "react-datetime/css/react-datetime.css";

import Select from 'react-select';

const dateFormat = require('dateformat');

class RekapAbsensi extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: {
				tgl_awal: this.props.location.state.tgl_awal,
				tgl_akhir: this.props.location.state.tgl_akhir,
			},
			judul: 'Jam Kerja',
			// =================================
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',

			isKelurahan: false,
			frmJam: [],
			jamStat: null,
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = '';
		// let where = [];
		// if (params.where)
		// {
		//     where = params.where;
		// }
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '?page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '?page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '?page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '?page=1';
					break;
				default:
					link = '?page=' + params.page;
					break;
			}
		}
		let awal = ''
		let akhir = `&end=${this.props.location.state.data.tanggal}`
		let tipe_jam = ''
		if(link){
			awal = `&start=${this.props.location.state.data.tanggal}`
		}
		else{
			awal = `?start=${this.props.location.state.data.tanggal}`
		}

		if(this.state.jamStat){
			tipe_jam = `&tipe_jam=${this.state.jamStat.value}`
		}

		Get('absensi/absen' + link + awal + akhir + tipe_jam, null, (dtkat) => {
			// console.log('data fetch')
			// console.log(dtkat)
			// console.log(dtkat.results.data[0].absensi)
			if (dtkat.results) {
				this.setState({ data: dtkat.results.data[0].absensi});
			}
		});
	};

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		//console.log(data);
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'primary-custom';
				if (i === data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						href={() => false}
						onClick={() => this.fetch({ page: i })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						href={() => false}
						onClick={() => this.fetch({ page: p })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						href={() => false}
						onClick={() => this.fetch({ page: n })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					href={() => false}
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					href={() => false}
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	// ========================================================================

	tombolAksi = (cell, row) => {
		return (
			<div>
				<Link to={{ pathname: "/admin/rekap-absensi-detail", state: row }}>
					<Button
						size="md"
						color="info"
						className="btn-icon"
						onClick={() => this.ubahData(row)}
						disabled={this.state.status.btnAksi}
					>
						<i className="fa fa-eye" /> Detail Riwayat
					</Button>
				</Link>
			</div>
		);
	};

	componentDidMount() {
		this.fetch({ page: null, where: [] });

		Get('absensi/master-jam', null, (dtkat) => {
			let frmJam = [];
			dtkat.results.forEach(dt => {
				frmJam.push({ value: dt.uuid, label: dt.tipe+' - '+dt.jam_absen });
			});
			this.setState({ frmJam });
		});

		if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			this.setState({ isKelurahan: true });
		}
	}

	gantiTgl = (date, fld) => {
		switch (fld) {
			case 'tanggal_awal':
				if (typeof date._isValid !== 'undefined') {
					let tgl  = dateFormat(date, 'yyyy-mm-dd')
					this.setState({ 
						dt:{
							...this.state.dt,
							tgl_awal: tgl,
						},
						tgl_awal: new Date(date.format('YYYY-MM-DD')) 
					});
				}
				break;
			case 'tanggal_akhir':
				if (typeof date._isValid !== 'undefined') {
					let tgl  = dateFormat(date, 'yyyy-mm-dd')
					this.setState({ 
						dt:{
							...this.state.dt,
							tgl_akhir: tgl,
						},
						tgl_akhir: new Date(date.format('YYYY-MM-DD')) 
					});
					// this.setState({ tgl_akhir: new Date(date.format('YYYY-MM-DD')) });
				}

				break;
			default:
				// no default
				break;
		}
	};

	lihatRiwayat() {
		if(this.state.jamStat){
			this.fetch({ page: null, where: [] });
		}
		else{
			this.setState({
				jamStat:null,
			})
			this.fetch({ page: null, where: [] });
			// this.setState({ show: true, basicType:'danger', basicTitle:'Detail Riwayat Absensi', pesanAlert:"Jenis Jam Absen Wajib Diisi" });
		}
	}

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'jenis_jam':
					this.setState({ 
						jamStat: e, 
					});
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case 'jenis_jam':
					this.setState({ 
						jamStat: null, 
					});
					break;
				
				default:
					// no default
					break;
			}
		}

		this.forceUpdate();
	};

	render() {
		// console.log('Data Render detail')
		// console.log(this.props.location.state)
		// console.log(this.state.frmJam)
		var data = this.state.data;
		
		const columns = [
			{
				dataField: 'no',
				text: 'No',
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: (key, obj, index) => {
					return index + 1;

					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = index + 1;
					let serial_num = total_records_per_page * (current_pagenum - 1) + row_index;
					return serial_num;
				},
				headerStyle: (colum, colIndex) => {
                    return { width: '30px', };
                }
			},
			{
				dataField: "data_perangkat.data_penduduk.nama",
				text: "Nama Karyawan",
				formatter: (cell, obj) => {
				  	return obj.data_perangkat.data_penduduk.nama;
				},
				sort: true
			},
			{
				dataField: "data_jam.tipe",
				text: "Jenis Absen",
				formatter: (cell, obj) => {
				  	return obj.data_jam.tipe;
				},
				sort: true
			},
			{
				dataField: "updated_at",
				text: "Jam Absen",
				formatter: (cell, obj) => {
					// console.log('Jam Absen')
					// console.log(obj.updated_at)
					// console.log(dateFormat(obj.updated_at, 'HH:mm:ss'))
				  	return dateFormat(obj.updated_at, 'HH:mm:ss');
				},
				sort: true
			},
			{
				dataField: "keterlambatan",
				text: "Keterlambatan",
				formatter: (cell, obj) => {
					console.log(obj.keterlambatan);
					const hours = Math.floor(obj.keterlambatan / 60);  
					const minutes = obj.keterlambatan % 60;
					let hasil = '';
					if(hours === 0){
						// hasil = `${minutes} Menit`;
						hasil = `${hours} Jam ${minutes} Menit`;
					}
					else{
						hasil = <b><font color='red'>{hours} Jam {minutes} Menit</font></b>;
					}
					return hasil;

				  	// return obj.keterlambatan;
				},
				sort: true
			},
			// {
			// 	dataField: 'aksi',
			// 	text: 'Aksi',
			// 	isDummyField: true,
			// 	csvExport: false,
			// 	headerAlign: 'center',
			// 	align: 'center',
			// 	formatter: this.tombolAksi,
			// 	headerStyle: (colum, colIndex) => {
            //         return { width: '80px', };
            //     }
			// }
		];

		return (
			<div>
				{/* <Breadcrumb title="Data Jam Kerja" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
					style={{ maxWidth: '478px' }}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-12">
											<Form className="theme-form" onSubmit={(e) => this.simpan(e, 0)}>
											<div className="row">
                      							<div className="col-sm-6">
												  	<Select
														classNamePrefix="select"
														onChange={(e) => {
															this.changeSelectValue(e, 'jenis_jam');
														}}
														defaultValue={this.state.jamStat}
														value={this.state.jamStat}
														name="Jam Absen"
														options={this.state.frmJam}
														placeholder="Pilih Jenis Jam Absen"
														isClearable
													/>
												</div>
												<div className="col-sm-4 text-left">
													<Button
														size="sm"
														color="success"
														onClick={() => {
															this.lihatRiwayat();
														}}
													>
														Lihat Riwayat
													</Button>
												</div>
												<div className="col-sm-2 text-right">
													<Link to={{ pathname: "/admin/rekap-absensi"}}>
														<button color="info" className="btn btn-info"><i className="fa fa-arrow-left"></i> Kembali</button>
													</Link>
												</div>
											</div>
											</Form>
										</div>
									</div>
								</div>
								<div className="card-body datatable-react">
									<BootstrapTable keyField="id" data={data} columns={columns} />
									<div className="pull-right text-white">
										{this.state.awal}
										{this.state.sebelum}
										{this.state.hal.map((dt) => {
											return dt;
										})}
										{this.state.setelah}
										{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		);
	}
}

export default RekapAbsensi;
