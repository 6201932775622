import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Dropzone from "react-dropzone";
import Resizer from "react-image-file-resizer";
// import axios from "axios";

import Select from "react-select";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, JENIS_DESA } from "../function/Koneksi";
import {  DataToken } from "../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import CKEditors from "react-ckeditor-component";
var HtmlToReactParser = require("html-to-react").Parser;

//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  judul: "",
  konten: "",
  jenis: "",
  link_gambar: "",
  id_pengguna: "",
	dataDokumen: [{nama:'', link:'', ext:''}],
};

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        modalDetail: false,
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Agama",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      konten: false,
      content: "",
      token: DataToken(sessionStorage.getItem("access_token")),
      foto_desa: "",
      link_gambar: "",
      formDisposisi: false,
      stpStat: {label : '', value : ''},

      modalDetail: null,
      titleDetail: null
    };
    this.onChange = this.onChange.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.onChange = this.onChange.bind(this);
    this.afterPaste = this.afterPaste.bind(this);
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("info-desa" + link, null, dtkat => {
      this.setState({ data: dtkat.results.data, pagination: dtkat.results });
      this.pagination(dtkat.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let addInfo = {
        nama: e.target.value
      };
      Post("info-desa", null, addInfo, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results
        });
      });
    }
  };

  // ========================================================================

  // ubah select value
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jenis-info":
          if (e.value === "info") {
            this.state.konten = true;
          } else {
            this.state.konten = false;
          }
          this.setState({
            stpStat: e,
            dt: { ...this.state.dt, jenis: e.value }
          });
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "jenis-info":
          this.state.dt.status_penduduk = 0;
          this.setState({ stpStat: e });
          break;
        default:
          // no default
          break;
      }
    }

    this.state.status.select = false;
    this.forceUpdate();
  };
  /// upload file

  processGbr = d => {
    if (d.length === 1) {
      let type = d[0].type.split("/");
      if (
        type[1] === "jpg" ||
        type[1] === "jpeg" ||
        type[1] === "png" ||
        type[1] === "bmp" ||
        type[1] === "tiff" ||
        type[1] === "webp"
      ) {
        let bodyFormData = new FormData();
        bodyFormData.append("files", d[0]);

        Post("upload-file/berita", null, bodyFormData, data => {
          Resizer.imageFileResizer(
            d[0],
            300,
            300,
            type[1],
            100,
            0,
            uri => {
              this.setState({
                foto_desa: uri,
                foto_info: "",
                link_gambar: data.data.results
              });
            },
            "base64"
          );
        });

      } else {
        this.setState({
          foto_desa: "",
          foto_info: (
            <div className="text-danger font-size-10">
              Type file tidak valid
            </div>
          )
        });
      }
    } else {
      this.setState({
        foto_desa: "",
        foto_info: (
          <div className="text-danger font-size-10">
            Silahkan masukan satu gambar
          </div>
        )
      });
    }
  };

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();

    let judul = document.getElementById("judul").value;

    if (this.state.stpStat.value === '' || judul === '') {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Data Info " + JENIS_DESA,
        pesanAlert: "Silahkan lengkapi data"
      });
    } else {
      if (
        this.state.stpStat.value === "info" &&
        this.state.link_gambar === "" &&
        this.state.content === ""
      ) {
        this.setState({
          show: true,
          basicType: "warning",
          basicTitle: "Data Info " + JENIS_DESA,
          pesanAlert: "Silahkan lengkapi data!"
        });
      } else {
        this.state.status.btnForm = true;
        this.forceUpdate();
  
        let addInfo = {
          uuid: document.getElementById("uuid").value,
          judul: document.getElementById("judul").value,
          konten: this.state.content,
          jenis: this.state.stpStat.value,
          link_gambar: this.state.link_gambar,
          id_pengguna: this.state.token.sub.uuid,
          link_dokumen: JSON.stringify(this.state.dt.dataDokumen),
        };
  
        let psn = "";
        let resstat = 204;
        let metode = "create";
        if (addInfo.uuid === "") {
          psn = "Tambah";
          resstat = 201;
          addInfo.uuid = null;
        } else {
          psn = "Ubah";
          resstat = 200;
          metode = "update";
        }
  
        Post("info-desa/" + metode, addInfo.uuid, addInfo, res => {
          this.state.status.btnForm = false;
          this.state.status.form = false;
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Info " + JENIS_DESA,
              pesanAlert: "Berhasil " + psn + " Data",
              stpStat: {label : '', value : ''},
              konten:false,
            });
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Info " + JENIS_DESA,
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        });
      }
    }

  };
  // ========================================================================
  // list disposisi

  detailBerita = data => {
    let dataDokumen = JSON.parse(data.link_dokumen)

    this.setState({
      formDisposisi: true,
      content: data.konten,
      link_gambar: data.link_gambar,
      dt: {
        ...this.state.dt,
        judul: data.judul,
        dataDokumen: dataDokumen,
      }
    });
  };
  // ============================== Ubah Data ===============================
  ubahData = id => {
    this.state.status.btnAksi = true;
    this.state.judul = "Ubah Info " + JENIS_DESA;
    this.forceUpdate();
    Get("info-desa/find", id, data => {
      if(data.results){

        this.state.dt.uuid = data.results.uuid;
        this.state.dt.judul= data.results.judul;
        if( data.results.jenis==="info"){
          this.state.konten = true;
          this.state.stpStat={value:'info',label:'info'}
        } else {
          this.state.stpStat={value:'pengumuman',label:'pengumuman'}

        }
        this.state.content=data.results.konten===null?'':data.results.konten
        this.state.link_gambar=data.results.link_gambar===null?'':data.results.link_gambar
        this.state.status.btnAksi = false;
        // uuid: document.getElementById("uuid").value,
          // judul: document.getElementById("judul").value,
          // konten: this.state.content,
          // jenis: this.state.stpStat.value,
          // link_gambar: this.state.link_gambar,
          // id_pengguna: this.state.token.sub.uuid
          let dataDokumen = JSON.parse(data.results.link_dokumen)
          this.state.dt.dataDokumen = dataDokumen
        this.forceUpdate();
        this.bukaForm();
      }
    });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("info-desa/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Info " + JENIS_DESA,
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Info " + JENIS_DESA,
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      formDisposisi: false,
      status:{
        form: false,
      },
      dt: frmDef,
      konten: false,
      content: "",
      foto_desa: "",
      link_gambar: "",
      formDisposisi: false,
      stpStat: {label : '', value : ''},
      modalDetail: null,
    })

    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>
        &nbsp;&nbsp;
        {/* //list */}
        {row.jenis === "info" ? (
          <Button
            size="xs"
            color="info"
            className="btn-icon btn-secondary-custom"
            onClick={e => this.detailBerita(row)}
          >
            <i className="fa fa-eye"></i>
          </Button>
        ) : (
          <></>
        )}
      </>
    );
  };
  componentDidMount() {
    this.fetch({ page: null, where: [] });

    Get("select-enum/info_desa/jenis", null, data => {
      let frmStp = [];
      data.forEach(dt => {
        let label = dt == 'info' ? 'berita' : dt
        frmStp.push({ value: dt, label });
      });
      this.setState({ frmStp });
    });
  }

  //ck editor

  updateContent(newContent) {
    this.setState({
      content: newContent
    });
  }

  onChange(evt) {
    var newContent = evt.editor.getData();
    this.setState({
      content: newContent
    });
  }

  onBlur(evt) {
    // //console.log("onBlur event called with event info: ", evt);
  }

  afterPaste(evt) {
    // //console.log("afterPaste event called with event info: ", evt);
  }

  // ==================== Upload File =====================
  onFileChange = (event, i) => {
    const file = event.target.files[0];
    // const fileType = file.type.split('/')
    // const type = fileType[1]
    const fileType = file.name.split('.')
    const type = fileType[1]

    console.log('Upload File')
    console.log(file)

		// Disable tombol selesai sampai file berhasil diupload
		this.setState({ 
      status: {
        ...this.state.status, 
        btnForm: true
      } 
    })
    
    if (type === "pdf" || type === "jpg" || type === "jpeg" || type === "png" || type === "xls" || type === "xlsx" || type === "csv" || type === "doc" || type === "docx") {
      let bodyFormData = new FormData();
      bodyFormData.append("files", file);

      let folder = 'img'
			if (type === "pdf") {
				folder = "pdf"
			}
			else if (type === "xls" || type === "xlsx" || type === "csv") {
				folder = "excel"
			}
			else if (type === "doc" || type === "docx") {
				folder = "doc"
			}
  
      Post("upload-file/" + folder, null, bodyFormData, (response) => {
        if(response.status===200){

          const link = response.data.results

          let ext = type
          let dataDokumen = [...this.state.dt.dataDokumen]
          dataDokumen[i] = {...dataDokumen[i], link, ext}
      
          this.setState({ 
            dt: {...this.state.dt, 
                dataDokumen
            },
            status: {
              ...this.state.status, 
              btnForm: false
            }
          })
        }
        else{
          let link = ''
          let ext = ''
          let dataDokumen = [...this.state.dt.dataDokumen]
          dataDokumen[i] = {...dataDokumen[i], link, ext}
          
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Upload file",
            pesanAlert: "Gagal Upload File",
            dt: {...this.state.dt, 
              dataDokumen
            },
            status: {
              ...this.state.status, 
              btnForm: false
            } 
          });
        }
      });
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Upload file",
        pesanAlert: "Format file harus berupa jpg/jpeg/png/pdf/xls/csv/doc",
        status: {
          ...this.state.status, 
          btnForm: false
        } 
      });
      document.getElementById("file-upload").value = null
    }
  };
  render() {
    console.log('Data Render');
    console.log(this.state.dt.dataDokumen);
    
    var data = this.state.data;

    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          headerAlign: 'center',
				  align: 'center',
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.pagination.current_page;
              let total_records_per_page = this.state.pagination.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
        dataField: "judul",
        headerAlign: 'center',
				align: 'center',
        text: "Judul",
        sort: true
      },
      {
        dataField: "jenis",
        headerAlign: 'center',
				align: 'center',
        text: "Jenis Info",
        sort: true, 
        formatter: (key, obj, index) =>{
          let jenis_info = obj.jenis
          if (obj.jenis == 'info') {
            jenis_info = 'berita'
          }
          return jenis_info; 
      }
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    var htmlToReactParser = new HtmlToReactParser();

    return (
      <div>
        {/* <Breadcrumb title="Data Info Desa" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Info " + JENIS_DESA,
                            dt: frmDef,
                            link_gambar: "",
                            content: ""
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          styles={{ modal: { width: "80%" } }}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Agama"
                defaultValue={this.state.dt.uuid}
                value={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Jenis Info {JENIS_DESA}
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "jenis-info");
                    }}
                    // defaultValue={this.state.stpStat}
                    value={this.state.stpStat.value === '' ? null : this.state.stpStat}
                    name="jenisinfor"
                    options={this.state.frmStp}
                    placeholder={this.state.stpStat.value === '' ? "Pilih Jenis Info" : ''}
                    isClearable
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  {this.state.konten === true ? "Judul" : "Deskripsi"}{" "}
                </Label>
                <Col sm="9">
                  <textarea
                    className="form-control"
                    id="judul"
                    type="text"
                    placeholder={this.state.konten === true ? "Judul" : "Deskripsi"}
                    required
                    defaultValue={this.state.dt.judul}
                    // value={this.state.dt.judul}
                    // onChange={this.handleChange}
                  />
                </Col>
              </FormGroup>
              {this.state.konten === true ? (
                <>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Foto {JENIS_DESA}
                    </Label>
                    <Col sm="9">
                      <Dropzone
                        onDrop={acceptedFiles => this.processGbr(acceptedFiles)}
                        maxFiles={1}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              style={{
                                minHeight: "100px",
                                paddingTop: "40px",
                                padding: "5px 5px 5px 5px",
                                border: "1px solid #d2d2d2"
                              }}
                            >
                              <input {...getInputProps()} />
                              <div
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                <p className="text-center">
                                  {this.state.link_gambar ? (
                                    <img
                                      src={this.state.link_gambar}
                                      alt=""
                                      style={{
                                        minHeight: "50px",
                                        minWidth: "50px"
                                      }}
                                    />
                                  ) : (
                                    <Label
                                      className="col-form-label text-center"
                                      htmlFor="inputEmail3"
                                    >
                                      Drag 'n' drop some files here, or click to
                                      select files
                                    </Label>
                                  )}
                                  {this.state.foto_info
                                    ? this.state.foto_info
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Konten{" "}
                    </Label>
                    <Col sm="9">
                      <CKEditors
                        activeClass="p10"
                        content={this.state.content}
                        config={{'removePlugins':'elementspath'}}
                        events={{
                          blur: this.onBlur,
                          afterPaste: this.afterPaste,
                          change: this.onChange
                        }}
                        
                      
                      />
                    </Col>
                  </FormGroup>

                  <br/>
                  <FormGroup className="row">
                    <Col sm="12">
                      <h5><b>Dokumen Lampiran</b></h5>
                    </Col>
                  </FormGroup>

                  {this.state.dt.dataDokumen.length > 0 ? (
                    <>
                      {this.state.dt.dataDokumen.map((dti, i) => {
                        let nomorUrut = i+1
                        return <>
                          <div style={{ border: '1px solid black', borderRadius: '10px', padding: '10px', marginBottom: '15px' }}>
                            {/* Tombol hapus */}
                            {i > 0 ? (
                              <FormGroup className="row">
                                <Col lg="12" align='right'>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    title="Hapus Dokumen Ini"
                                    style={{ padding: '6px 15px' }}
                                    onClick={() => {
                                      let isi = this.state.dt.dataDokumen
                                      isi.splice(i, 1)

                                      let dataDokumen = [...this.state.dt.dataDokumen]
                                      dataDokumen[i] = {...dataDokumen[i], isi}

                                      this.setState({ 
                                        dt: {...this.state.dt, 
                                          isi
                                        }
                                      })
                                    }}
                                  >Batal</button>
                                </Col>
                              </FormGroup>
                            ) : ''}
                            {/* End Tombol hapus */}
                            <FormGroup className="row">
                              <Label
                                className="col-sm-3 col-form-label"
                                htmlFor="inputEmail3"
                              >
                                Nama Dokumen {nomorUrut}
                              </Label>
                              <Col sm="9">
                                <input
                                  className="form-control"
                                  id="nama"
                                  type="text"
                                  placeholder={"Nama Dokumen "+ nomorUrut}
                                  value={dti.nama}
                                  onChange={(event2) => {
                                    // let isi = [...this.state.dt.dataDokumen[i].nama]
                                    let nama = event2.target.value

                                    // isi[i] = {...isi[i], nama: get_data}

                                    let dataDokumen = [...this.state.dt.dataDokumen]
                                    dataDokumen[i] = {...dataDokumen[i], nama}
    
                                    this.setState({ 
                                        dt: {...this.state.dt, 
                                            dataDokumen
                                        }
                                    })
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="row">
                              <Label
                                className="col-sm-3 col-form-label"
                                htmlFor="inputEmail3"
                              >
                                File {i+1}
                              </Label>
                              <Col sm="9">
                                <input id="file-upload" 
                                  type="file" 
                                  onChange={(event) => {
                                    this.onFileChange(event, i)
                                  }} 
                                  accept="image/png, image/jpg, image/jpeg, application/pdf, application/xls, application/xlsx" 
                                />
                              </Col>
                            </FormGroup>

                            <FormGroup className="row">
                              <Col lg="3">
                                &nbsp;
                              </Col>
                              <Col lg="9">
                                {this.state.dt.dataDokumen[i].link !== "" ? (
                                  this.state.dt.dataDokumen[i].ext === "pdf" || this.state.dt.dataDokumen[i].ext === "xls" || this.state.dt.dataDokumen[i].ext === "xlsx" || this.state.dt.dataDokumen[i].ext === "csv" || this.state.dt.dataDokumen[i].ext === "doc" || this.state.dt.dataDokumen[i].ext === "docx" ? 
                                  <>
                                    <a href={this.state.dt.dataDokumen[i].link}>
                                      <button
                                        type="button"
                                        className="btn-info"
                                        title="Lihat Dokumen"
                                        // style={{ padding: '6px 15px' }}
                                        >
                                        Lihat file
                                      </button>
                                    </a>
                                    </> : <>
                                    <a 
                                      href='/'
                                      onClick={(e) => {
                                        e.preventDefault()
                                        let status = { ...this.state.status };
                                        status.modalDetail = true;
                                        this.setState({
                                          status,
                                          modalDetail: this.state.dt.dataDokumen[i].link,
                                          titleDetail: this.state.dt.dataDokumen[i].nama,
                                        });
                                      }}
                                      >
                                        <button
                                          type="button"
                                          className="btn-info"
                                          title="Lihat Dokumen"
                                          // style={{ padding: '6px 15px' }}
                                          >
                                          Lihat file
                                        </button>
                                    </a>
                                  </>

                                ) : (
                                  ""
                                )}
                              </Col>
                            </FormGroup>
                          </div>
                        </>
                      })}
                      {/* Tombol Tambah */}
                      <div className="row">
                        <div className="col-sm-12">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                              let isi = this.state.dt.dataDokumen
                              isi.push({nama:'', link:'', ext:''})
                              let dataDokumen = [...this.state.dt.dataDokumen]
                              dataDokumen = {...dataDokumen, isi}

                              this.setState({ 
                                dt: {...this.state.dt, 
                                  isi
                                }
                              })
                            }}
                          >
                            Tambah Dokumen
                          </button>
                        </div>
                      </div>
                      {/* End Tombol Tambah */}
                    </>
                  ) : ''}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Detail Info</h5>
            </div>
            <div className="modal-body ">
              <div align='center'>
                <h5><b>{this.state.dt.judul}</b></h5>
                <br/><br/>
                <img
                  src={this.state.link_gambar}
                  alt=""
                  className="center"
                  style={{
                    minHeight: "50px",
                    minWidth: "50px"
                  }}
                />
              </div>
              <br/><br/>
              {htmlToReactParser.parse(this.state.content)}
              <br/>
              {this.state.dt.dataDokumen.length > 0 ? (
                <>
                  <div align='center'>
                    <h6><b>Dokumen Lampiran</b></h6>
                  </div>
                  {this.state.dt.dataDokumen.map((dti, i) => {
                    let nomorUrut = i+1
                    return <>
                      <div style={{ border: '1px solid black', borderRadius: '10px', padding: '10px', marginBottom: '15px' }}>
                        <FormGroup className="row">
                          <Label
                            className="col-sm-3 col-form-label"
                            htmlFor="inputEmail3"
                          >
                            Nama Dokumen {nomorUrut}
                          </Label>
                          <Col sm="9">
                            <input
                              className="form-control"
                              id="nama"
                              type="text"
                              placeholder={"Nama Dokumen "+ nomorUrut}
                              value={dti.nama}
                              disabled
                              style={{ backgroundColor: "#c9d1d8" }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label
                            className="col-sm-3 col-form-label"
                            htmlFor="inputEmail3"
                          >
                            File {i+1}
                          </Label>
                          <Col sm="9">
                            {this.state.dt.dataDokumen[i].link !== "" ? (
                              this.state.dt.dataDokumen[i].ext === "pdf" || this.state.dt.dataDokumen[i].ext === "xls" || this.state.dt.dataDokumen[i].ext === "xlsx" || this.state.dt.dataDokumen[i].ext === "csv" || this.state.dt.dataDokumen[i].ext === "doc" || this.state.dt.dataDokumen[i].ext === "docx" ? 
                              <>
                                <a href={this.state.dt.dataDokumen[i].link}>
                                  <button
                                    type="button"
                                    className="btn-info"
                                    title="Lihat Dokumen"
                                    // style={{ padding: '6px 15px' }}
                                    >
                                    Lihat file
                                  </button>
                                </a>
                                </> : <>
                                <a 
                                  href='/'
                                  onClick={(e) => {
                                    e.preventDefault()
                                    let status = { ...this.state.status };
                                    status.modalDetail = true;
                                    this.setState({
                                      status,
                                      modalDetail: this.state.dt.dataDokumen[i].link,
                                      titleDetail: this.state.dt.dataDokumen[i].nama,
                                    });
                                  }}
                                  >
                                    <button
                                      type="button"
                                      className="btn-info"
                                      title="Lihat Dokumen"
                                      // style={{ padding: '6px 15px' }}
                                      >
                                      Lihat file
                                    </button>
                                </a>
                              </>

                            ) : (
                              ""
                            )}
                          </Col>
                        </FormGroup>
                      </div>
                    </>
                  })}
                </>
              ) : ''}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
            </div>
          </Form>
        </Modal>

         {/* Modal Detail */}
         <Modal
          open={this.state.status.modalDetail}
          styles={{
            modal: {
              width: '90%'
            }
          }}
          onClose={() => {
            let status = { ...this.state.status };
            status.modalDetail = false;
            this.setState({ status });
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title">{this.state.titleDetail}</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
              {this.state.modalDetail !== '' ? (
                <img
                alt="Dokumen"
                src={this.state.modalDetail}
                style={{ width: "100%" }}
              />
              ) : (
                <h6 style={{ textAlign: "center" }}>Tidak ada Foto</h6>
              )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                let status = { ...this.state.status };
                status.modalDetail = false;
                this.setState({ 
                  status, 
                  modalDetail: null, 
                });
              }}
              style={{ fontWeight: 600 }}
            >
              Tutup
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default BasicTable;
