import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Dropzone from "react-dropzone";
import Resizer from "react-image-file-resizer";

import Switch from "react-switch"; 
import Select from 'react-select';

// koneksi
import { Post, Get, JENIS_DESA, ID_DESA } from '../function/Koneksi';

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

//json file
// var data = require('../assets/json/company');

const frmDef = {
	nomor_wa_perangkat: [{isi:[""]}],
};

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
			dt: frmDef,
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false
      },
      foto_desa: "",
      foto_info: "",
      input: "none",
      view: "inherit",
      judul: "Konfigurasi Aplikasi",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      sidebar: false,
      response_pengajuan_layanan: "",
      riwayat_disposisi: "",
      notif_global: false,
      notif_wa: false,
      notif_pengajuan_global: false,
      nomor_wa_perangkat: "",
      get_all_nomor: null,

      // Konfigurasi Web Service
      alamat_foto_kop: 'logo/logo_kop.png',
      provinsi: "",
      jenis_kabkota: "",
      kabkota: "",
      kecamatan: "",
      jenis_desa: "",
      desa: "",
      jenis_dusun: "",

      jabatan_tertinggi: "",
      alamat_kantor_desa: "",
      kode_pos: "",

      url_bo_digidesa: "",
      url_marketplace: "https://ws-marketplace-desa-prod.digidesa.com",
      desa_one_signal: "",
      type_app: "prod",
      heading_notif: "Digidesa",
      style_nama_kabkota: "font-weight: bold;font-size: 24px; text-transform: uppercase; font-family:times new roman",
      style_kecamatan: "font-weight: bold;font-size: 22px; text-transform: uppercase;font-family:times new roman",
      style_desa: "font-weight: bold;font-style: calibri; font-size: 24px; text-transform: uppercase;font-family:times new roman",
      style_alamat: "font-weight: bold;font-size: 16px; text-transform: capitalize;font-family:times new roman",
      nama_kades: "",
      link_fb: "",
      link_ig: "",
      email: "",
      ukuran_kertas: "",
      foto_kades: "",
      selectedFile: null,
      frmKertas: [],
      selectedKertas: null,
      // End Konfigurasi Web Service

			// Loading
			loading: true,
      data_cek: '',

      link_gambar: "",
    };
		this.handleChange = this.handleChange.bind(this);
  }

  onFileChange = event => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };
  
  inputChange = (event) => {
		// //console.log(event.target.value);
		this.setState({ 
			dt: {...this.state.dt, 
				nomor_wa_perangkat: {...this.state.dt.nomor_wa_perangkat,
				}
			}
		})
		// //console.log(this.state.dt);
	}


  openCloseSidebar = (type) => {
    let data = null

    if (type === "sidebar") {
      data = this.state.sidebar
    } else if (type === "notif_global") {
      data = this.state.notif_global
    } else if (type === "notif_wa") {
      data = this.state.notif_wa
    } else if (type === "notif_pengajuan_global") {
      data = this.state.notif_pengajuan_global
    }

    // Jika data === true
    if (data) {
      if (type === "sidebar") {
        this.setState({ sidebar: false });
      } else if (type === "notif_global") {
        this.setState({ notif_global: false });
      } else if (type === "notif_wa") {
        this.setState({ notif_wa: false });
      } else if (type === "notif_pengajuan_global") {
        this.setState({ notif_pengajuan_global: false });
      }
    } else {
      if (type === "sidebar") {
        this.setState({ sidebar: true });
      } else if (type === "notif_global") {
        this.setState({ notif_global: true });
      } else if (type === "notif_wa") {
        this.setState({ notif_wa: true });
      } else if (type === "notif_pengajuan_global") {
        this.setState({ notif_pengajuan_global: true });
      }
    }
  };
  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  handleChange(checked, jenis_konfig) {
		//console.log("CHANGE JENIS Konfig")
		//console.log(jenis_konfig)
		switch (jenis_konfig) {
			// PPOB MOBILE
			case "sidebar":
				if(this.state.sidebar === true){
          this.setState({
            sidebar: checked
          })
				}
				else{
          this.setState({
            sidebar: checked
          })
				}
			break;
			case "notif_global":
				if(this.state.notif_global === true){
          this.setState({
            notif_global: checked
          })
				}
				else{
          this.setState({
            notif_global: checked
          })
				}
			break;
			case "notif_wa":
				if(this.state.notif_wa === true){
          this.setState({
            notif_wa: checked,
          })
				}
				else{
          this.setState({
            notif_wa: checked,
          })
				}
			break;
			case "notif_pengajuan_global":
				if(this.state.notif_pengajuan_global === true){
          this.setState({
            notif_pengajuan_global: checked,
          })
				}
				else{
          this.setState({
            notif_pengajuan_global: checked,
          })
				}
			break;
			default:
				// no default
				break;
		  }

		this.forceUpdate();
		this.setState({ checked });
	}

  // ================== Ambil data dari db untuk table ==================
  fetch = () => {
    Get("pengaturan-aplikasi", null, dtkat => {
      console.log('Data Detail');
      console.log(dtkat);
      this.setState({
        loading: false,
      })
      let data_cek = ""
      if (dtkat.results !== null) {
        let nomor_wa_perangkat = JSON.parse(dtkat.results.nomor_wa_perangkat)
        data_cek = "Tidak Kosong"

        let kode_pos = dtkat.results.alamat_kantor_desa.split(" Kode POS ");

        this.setState({
          loading: false,
          data: dtkat.results,
          response_pengajuan_layanan: dtkat.results.response_pengajuan_layanan,
          nomor_wa_perangkat: dtkat.results.nomor_wa_perangkat,
          get_all_nomor: nomor_wa_perangkat, 
          sidebar: dtkat.results.riwayat_disposisi === 0 ? false : true,
          notif_global: dtkat.results.notif_global_perangkat === 1 ? true : false,
          notif_wa: dtkat.results.notif_wa === 1 ? true : false,
          data_cek: data_cek,

           // Konfigurasi Tambahan Baru
          alamat_foto_kop: dtkat.results.alamat_foto_kop,
          notif_pengajuan_global: dtkat.results.notif_pengajuan_global === 1 ? true : false,
          jenis_kabkota: dtkat.results.jenis_kabkota,
          jenis_desa: dtkat.results.jenis_desa,
          jenis_dusun: dtkat.results.jenis_dusun,
          alamat_kantor_desa: kode_pos[0],
          kode_pos: kode_pos[1],
          provinsi: dtkat.results.provinsi,
          kabkota: dtkat.results.kabkota,
          nama_kabkota: dtkat.results.nama_kabkota,
          kecamatan: dtkat.results.kecamatan,
          desa: dtkat.results.desa,
          nama_desa: dtkat.results.nama_desa,
          jabatan_tertinggi: dtkat.results.jabatan_tertinggi,
          pimpinan_desa: dtkat.results.pimpinan_desa,
          jabatan_ttd: "Data tidak ditemukan",
          nama_ttd: "Data tidak ditemukan",
          nip_ttd: "Data tidak ditemukan",
          url_bo_digidesa: dtkat.results.url_bo_digidesa,
          url_marketplace: dtkat.results.url_marketplace,
          heading_notif: dtkat.results.heading_notif,
          type_app: dtkat.results.type_app,
          desa_one_signal: dtkat.results.desa_one_signal,
          style_nama_kabkota: dtkat.results.style_nama_kabkota,
          style_kecamatan: dtkat.results.style_kecamatan,
          style_desa: dtkat.results.style_desa,
          style_alamat: dtkat.results.style_alamat,
          nama_kades: dtkat.results.nama_kades,
          foto_kades: dtkat.results.foto_kades,
          link_fb: dtkat.results.link_fb,
          link_ig: dtkat.results.link_ig,
          email: dtkat.results.email_desa,
          ukuran_kertas: dtkat.results.ukuran_kertas,
        });
      }
      else{
        data_cek = "Kosong"
        this.setState({
          loading: false,
          data_cek: data_cek,
        });
      }
    });
  };

  // ========================================================================

  tutupForm = e => {
    this.setState({ 
      input: "none", 
      view: "inherit",
      status:{
        ...this.state.status,
        form: false,
      },
      selectedFile: null,
      link_gambar: '',
    });
    this.forceUpdate()
  };

  // Proses Upload Kop Surat
  processGbr = d => {
    if (d.length === 1) {
      let type = d[0].type.split("/");
      if (
        type[1] === "jpg" ||
        type[1] === "jpeg" ||
        type[1] === "png" ||
        type[1] === "bmp" ||
        type[1] === "tiff" ||
        type[1] === "webp"
      ) {
        let bodyFormData = new FormData();
        bodyFormData.append("files", d[0]);

        Post("upload-file/foto-kop", null, bodyFormData, data => {
          Resizer.imageFileResizer(
            d[0],
            300,
            300,
            type[1],
            100,
            0,
            uri => {
              this.setState({
                foto_desa: uri,
                foto_info: "",
                link_gambar: data.data.results
              });
            },
            "base64"
          );
        });

      } else {
        this.setState({
          foto_desa: "",
          foto_info: (
            <div className="text-danger font-size-10">
              Type file tidak valid
            </div>
          )
        });
      }
    } else {
      this.setState({
        foto_desa: "",
        foto_info: (
          <div className="text-danger font-size-10">
            Silahkan masukan satu gambar
          </div>
        )
      });
    }
  };

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    // Check validation
    let kode_pos = document.getElementById("kode_pos").value
    if (kode_pos.length > 5) {
      this.setState({
        show: true,
        basicType: 'warning',
        basicTitle: 'Konfigurasi Aplikasi',
        pesanAlert: 'Jumlah digit kode pos tidak boleh lebih dari 5 angka'
      });
    }
    else{

      this.setState({
        status:{
            ...this.state.status,
            btnForm: true,
        },
      })
      this.forceUpdate();
      // let uuid = null;

      let get_nomor_wa = JSON.stringify(this.state.dt.nomor_wa_perangkat);
      let pch = get_nomor_wa.split(':');
      let pch2 = pch[1].split('}');
      let nomor_wa = pch2[0];
      let set = JSON.parse(nomor_wa)

      const depArray = set;
      var newNomorWa = [];
      newNomorWa = depArray.filter(function(elem, pos) {
              return depArray.indexOf(elem) === pos;
      });

      let alamat_kantor_desa = document.getElementById("alamat_kantor_desa").value + ' Kode POS ' + document.getElementById("kode_pos").value

      if (this.state.link_gambar === "" || this.state.selectedKertas === null) {
        this.setState({
          show: true,
          basicType: "warning",
          basicTitle: "Pengaturan Aplikasi " + JENIS_DESA,
          pesanAlert: "Silahkan lengkapi data!"
        });
      }
      else{
        if (this.state.selectedFile) {
          let bodyFormData = new FormData();
          bodyFormData.append('files', this.state.selectedFile);
  
          Post('upload-file/foto-kades', null, bodyFormData, (dataGbr) => {
            // console.log(dataGbr);
            if (dataGbr.data.results) {
              const foto_kades = dataGbr.data.results
              this.setState({ foto_kades })
              this.state.foto_kades = foto_kades
              this.forceUpdate()
  
              let addProfile = {
                uuid: document.getElementById("uuid").value,
                response_pengajuan_layanan: document.getElementById("response_pengajuan_layanan").value,
                nomor_wa_perangkat: JSON.stringify(newNomorWa),
                riwayat_disposisi: this.state.sidebar ? 1 : 0,
                notif_global_perangkat: this.state.notif_global ? 1 : 0,
                notif_wa: this.state.notif_wa ? 1 : 0,
                // Konfigurasi Tambahan Baru
                alamat_foto_kop: this.state.link_gambar,
                notif_pengajuan_global: this.state.notif_pengajuan_global ? 1 : 0,
                jenis_kabkota: document.getElementById("jenis_kabkota").value,
                jenis_desa: document.getElementById("jenis_desa").value,
                jenis_dusun: document.getElementById("jenis_dusun").value,
                alamat_kantor_desa: alamat_kantor_desa,
                provinsi: document.getElementById("provinsi").value,
                kabkota: document.getElementById("kabkota").value,
                nama_kabkota: document.getElementById("jenis_kabkota").value + ' ' + document.getElementById("kabkota").value,
                kecamatan: document.getElementById("kecamatan").value,
                desa: document.getElementById("desa").value,
                nama_desa: document.getElementById("jenis_desa").value + ' ' + document.getElementById("desa").value,
                jabatan_tertinggi: document.getElementById("jabatan_tertinggi").value,
                pimpinan_desa: document.getElementById("jabatan_tertinggi").value,
                jabatan_ttd: "Data tidak ditemukan",
                nama_ttd: "Data tidak ditemukan",
                nip_ttd: "Data tidak ditemukan",
                url_bo_digidesa: document.getElementById("url_bo_digidesa").value,
                url_marketplace: document.getElementById("url_marketplace").value,
                heading_notif: document.getElementById("heading_notif").value,
                type_app: document.getElementById("type_app").value,
                desa_one_signal: document.getElementById("type_app").value+'_'+ID_DESA,
                style_nama_kabkota: document.getElementById("style_nama_kabkota").value,
                style_kecamatan: document.getElementById("style_kecamatan").value,
                style_desa: document.getElementById("style_desa").value,
                style_alamat: document.getElementById("style_alamat").value,
                nama_kades: document.getElementById("nama_kades").value,
                link_fb: document.getElementById("link_fb").value,
                link_ig: document.getElementById("link_ig").value,
                email_desa: document.getElementById("email").value,
                foto_kades,
                ukuran_kertas: this.state.selectedKertas.value,
              };
        
              // console.log(addProfile);
              
              let psn = "";
              let resstat = 204;
              let metode = "create";
              if (addProfile.uuid === "") {
                psn = "Tambah";
                resstat = 201;
                addProfile.uuid = null;
              } else {
                psn = "Ubah";
                resstat = 200;
                metode = "update";
              }
        
              // console.log('Data SImpan')
              // console.log(addProfile)
        
              Post("pengaturan-aplikasi/" + metode, addProfile.uuid, addProfile, res => {
                if (res.status === resstat) {
                  this.setState({
                    show: true,
                    basicType: "success",
                    basicTitle: "Data Pengaturan Aplikasi",
                    pesanAlert: "Berhasil " + psn + " Data"
                  });
                  this.tutupForm()
                } else {
                  this.setState({
                    show: true,
                    basicType: "danger",
                    basicTitle: "Data Pengaturan Aplikasi",
                    pesanAlert: "Gagal " + psn + " Data"
                  });
                }
                this.setState({ input: "none", view: "inherit" });
                this.fetch();
              });
            }
          });
        } else {
          let addProfile = {
            uuid: document.getElementById("uuid").value,
          //   foto_desa: this.state.foto_desa,
            response_pengajuan_layanan: document.getElementById("response_pengajuan_layanan").value,
            nomor_wa_perangkat: JSON.stringify(newNomorWa),
            riwayat_disposisi: this.state.sidebar ? 1 : 0,
            notif_global_perangkat: this.state.notif_global ? 1 : 0,
            notif_wa: this.state.notif_wa ? 1 : 0,
            // Konfigurasi Tambahan Baru
            alamat_foto_kop: this.state.link_gambar,
            notif_pengajuan_global: this.state.notif_pengajuan_global ? 1 : 0,
            jenis_kabkota: document.getElementById("jenis_kabkota").value,
            jenis_desa: document.getElementById("jenis_desa").value,
            jenis_dusun: document.getElementById("jenis_dusun").value,
            alamat_kantor_desa: alamat_kantor_desa,
            provinsi: document.getElementById("provinsi").value,
            kabkota: document.getElementById("kabkota").value,
            nama_kabkota: document.getElementById("jenis_kabkota").value + ' ' + document.getElementById("kabkota").value,
            kecamatan: document.getElementById("kecamatan").value,
            desa: document.getElementById("desa").value,
            nama_desa: document.getElementById("jenis_desa").value + ' ' + document.getElementById("desa").value,
            jabatan_tertinggi: document.getElementById("jabatan_tertinggi").value,
            pimpinan_desa: document.getElementById("jabatan_tertinggi").value,
            jabatan_ttd: "Data tidak ditemukan",
            nama_ttd: "Data tidak ditemukan",
            nip_ttd: "Data tidak ditemukan",
            url_bo_digidesa: document.getElementById("url_bo_digidesa").value,
            url_marketplace: document.getElementById("url_marketplace").value,
            heading_notif: document.getElementById("heading_notif").value,
            type_app: document.getElementById("type_app").value,
            desa_one_signal: document.getElementById("type_app").value+'_'+ID_DESA,
            style_nama_kabkota: document.getElementById("style_nama_kabkota").value,
            style_kecamatan: document.getElementById("style_kecamatan").value,
            style_desa: document.getElementById("style_desa").value,
            style_alamat: document.getElementById("style_alamat").value,
            nama_kades: document.getElementById("nama_kades").value,
            link_fb: document.getElementById("link_fb").value,
            link_ig: document.getElementById("link_ig").value,
            email_desa: document.getElementById("email").value,
            foto_kades: this.state.foto_kades,
            ukuran_kertas: this.state.selectedKertas.value,
          };
    
          // console.log(addProfile);
          
          let psn = "";
          let resstat = 204;
          let metode = "create";
          if (addProfile.uuid === "") {
            psn = "Tambah";
            resstat = 201;
            addProfile.uuid = null;
          } else {
            psn = "Ubah";
            resstat = 200;
            metode = "update";
          }
    
          // console.log('Data SImpan')
          // console.log(addProfile)
    
          Post("pengaturan-aplikasi/" + metode, addProfile.uuid, addProfile, res => {
            if (res.status === resstat) {
              this.setState({
                show: true,
                basicType: "success",
                basicTitle: "Data Pengaturan Aplikasi",
                pesanAlert: "Berhasil " + psn + " Data"
              });
              this.tutupForm()
            } else {
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Data Pengaturan Aplikasi",
                pesanAlert: "Gagal " + psn + " Data"
              });
            }
            this.setState({ input: "none", view: "inherit" });
            this.fetch();
          });
        }
      }
      
    }
  };
  // ========================================================================

  // ============================== Ubah Data ===============================

  ubahData = (status, data) => {
    this.setState({
      status:{
          ...this.state.status,
          form: true,
      },
    })
    if(status==="update"){
      let nomor_wa_perangkat = [
        { 
          isi:[""]
        }
      ]

      if (data.nomor_wa_perangkat !== '') {
        nomor_wa_perangkat = JSON.parse(data.nomor_wa_perangkat)
      }

      
      this.setState({
        data: data,
        link_gambar: data.alamat_foto_kop,
        selectedKertas: {value: data.ukuran_kertas, label: data.ukuran_kertas},
        dt:{
            ...this.state.dt,
            nomor_wa_perangkat: [
              { 
                isi:nomor_wa_perangkat
              }
            ],
        },
      })

      this.forceUpdate()
    }
    else{
      this.setState({
        data:{

        },
        judul: 'Ubah Pengaturan Aplikasi',
      })
    }
		// this.setState({ view: 'none', input: 'inherit' });
		this.forceUpdate();
	};
  // ========================================================================

  componentDidMount() {
    this.fetch({ page: null, where: [] });
    Get('select-enum/pengaturan_aplikasi/ukuran_kertas', null, (data) => {
      let frmKertas = [];
      data.forEach((dt) => {
        frmKertas.push({ value: dt, label: dt });
      });
      this.setState({ frmKertas });
    });
  }

  changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'ukuran_kertas':
					this.setState({ selectedKertas: e });
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case 'ukuran_kertas':
					this.setState({ selectedKertas: null });
					break;
				default:
					// no default
					break;
			}
		}
	};

  render() {

    return (
      <div>
        {/* <Breadcrumb title="Pengaturan Aplikasi Desa" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
              <div className="card-header">
									<div
										className="row"
										style={{ border: '3px outset black', borderRadius: '25px', padding: '20px' }}
									>
                    {this.state.loading ? (
                      <div className="col-sm-12" align="center">
                        <img 
                        alt="loading"
                        src={require("../assets/images/loading-bos.gif")} 
                        style={{
                          // width: "100%",
                          borderRadius: "10px",
                          width: '70px'
                        }}/> 
                      </div>
                    ) : 
                    <>
											<div className="col-md-12 text-right">
											{
												this.state.data.uuid
												?
													<Button  size="md" color="success" className="btn-icon" onClick={() => this.ubahData('update',this.state.data)} disabled={this.state.status.btnAksi}><i className="fa fa-edit"></i> Ubah Pengaturan</Button> 
												:
													<Button  size="md" color="success" className="btn-icon" onClick={() => this.ubahData('create','')} disabled={this.state.status.btnAksi}><i className="fa fa-plus"></i> Tambah Pengaturan</Button> 
											}
											</div>
											<div>
												<br/>&nbsp;<br/>
											</div>
                      {
                      this.state.data.uuid
                      ?
                        <>
                          <div className="col-md-12" align="center">
                            {/* <img src={this.state.data.foto_desa} style={{ width: '350px' }} /> */}
                            <br/><br/>
                          </div>

                          <div className="col-md-12" align='center'>
                            <h5><b>Konfigurasi Umum</b></h5>
                            <hr/>
                          </div>
                          <div className="col-4">
                            <div className="col-md-12">
                              <b>Response pengajuan layanan</b>
                            </div>
                            <div className="col-md-12">
                              {this.state.response_pengajuan_layanan}
                            </div>

                            <div className="col-md-12">
                              <b>&nbsp;</b>
                            </div>
                            <div className="col-md-12">
                              &nbsp;
                              </div>

                            <div className="col-md-12">
                              <b>Status Riwayat Disposisi</b>
                            </div>
                            <div className="col-md-12">
                              {this.state.sidebar === false ? "Tidak Aktif" : "Aktif"}
                            </div>

                            <div className="col-md-12">
                              <b>&nbsp;</b>
                            </div>
                            <div className="col-md-12">
                              &nbsp;
                            </div>
                          </div>

                          {/* ======================= */}
                          <div className="col-4">
                            <div className="col-md-12">
                              <b>Notifikasi Layanan Global</b>
                            </div>
                            <div className="col-md-12">
                              {this.state.notif_global === false ? "Tidak Aktif" : "Aktif"}
                            </div>

                            <div className="col-md-12">
                              <b>&nbsp;</b>
                            </div>
                            <div className="col-md-12">
                              &nbsp;
                            </div>

                            <div className="col-md-12">
                              <b>Notifikasi Pengajuan Global</b>
                            </div>
                            <div className="col-md-12">
                              {this.state.notif_pengajuan_global === false ? "Tidak Aktif" : "Aktif"}
                            </div>

                            <div className="col-md-12">
                              <b>&nbsp;</b>
                            </div>
                            <div className="col-md-12">
                              &nbsp;
                            </div>

                          </div>
                          {/* ======================= */}
                          <div className="col-4">
                            <div className="col-md-12">
                              <b>Notifikasi Whatsapp</b>
                            </div>
                            <div className="col-md-12">
                              {this.state.notif_wa === false ? "Tidak Aktif" : "Aktif"}
                            </div>

                            <div className="col-md-12">
                              <b>&nbsp;</b>
                            </div>
                            <div className="col-md-12">
                              &nbsp;
                            </div>

                            <div className="col-md-12">
                              <b>Nomor Whatsapp</b>
                            </div>
                            <div className="col-md-12">
                              {
                                this.state.get_all_nomor.map((dt, i) => {
                                  return (
                                    <tr>
                                      <td style={{ padding: '5px 5px 5px 5px' }}>{i + 1}.</td>
                                      <td style={{ padding: '5px 5px 5px 5px' }}>{dt}</td>
                                    </tr>
                                  );
                                })
                              }
                            </div>

                            <div className="col-md-12">
                              <b>&nbsp;</b>
                            </div>
                            <div className="col-md-12">
                              &nbsp;
                              </div>
                          </div>

                          {/* ===================================== */}
                          <div className="col-md-12" align='center'>
                            <hr/>
                            <h5><b>Konfigurasi Web Service</b></h5>
                            <hr/>
                          </div>

                            <div className="col-4">
                              <div className="col-md-12">
                                <b>Nama Provinsi</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.provinsi}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Jenis Kabupaten/Kota</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.jenis_kabkota}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Nama {this.state.jenis_kabkota}</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.kabkota}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Nama Kecamatan</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.kecamatan}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Jenis Kelurahan/Desa</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.jenis_desa}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Nama {this.state.jenis_desa}</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.desa}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Jenis Dusun</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.jenis_dusun}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Nama Lengkap Kepala {JENIS_DESA}</b>
                              </div>
                              <div className="col-md-12">
                                {
                                  this.state.nama_kades
                                }
                              </div>
                              
                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Foto Kepala {JENIS_DESA}</b>
                              </div>
                              <div className="col-md-12">
                                <img alt="foto_kades" src={this.state.foto_kades} style={{ width: '150px' }} />
                              </div>

                            </div>
                            {/* ======================= */}
                            <div className="col-4">
                              <div className="col-md-12">
                                <b>Jabatan Tertinggi {this.state.jenis_desa}</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.jabatan_tertinggi}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Alamat Kantor Desa</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.alamat_kantor_desa}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Kode Pos</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.kode_pos}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Url BO Digidesa</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.url_bo_digidesa}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Url Marketplace</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.url_marketplace}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Type App</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.type_app}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Heading Notif</b>
                              </div>
                              <div className="col-md-12">
                                {this.state.heading_notif}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>
                              
                              <div className="col-md-12">
                                <b>Link Facebook</b>
                              </div>
                              <div className="col-md-12">
                                {
                                  this.state.link_fb
                                }
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Email </b>
                              </div>
                              <div className="col-md-12">
                                {
                                  this.state.email
                                }
                              </div>


                            </div>
                            {/* ======================= */}
                            <div className="col-4">
                              <div className="col-md-12">
                                <b>Ukuran Kertas </b>
                              </div>
                              <div className="col-md-12">
                                {
                                  this.state.ukuran_kertas
                                }
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Style Nama Kabupaten/Kota</b>
                              </div>
                              <div className="col-md-12">
                                {/* {this.state.style_nama_kabkota ? "Tidak Aktif" : "Aktif"} */}
                                {this.state.style_nama_kabkota}
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Style Kecamatan</b>
                              </div>
                              <div className="col-md-12">
                                {
                                  this.state.style_kecamatan
                                }
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Style Desa</b>
                              </div>
                              <div className="col-md-12">
                                {
                                  this.state.style_desa
                                }
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Style Alamat</b>
                              </div>
                              <div className="col-md-12">
                                {
                                  this.state.style_alamat
                                }
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Link Instagram</b>
                              </div>
                              <div className="col-md-12">
                                {
                                  this.state.link_ig
                                }
                              </div>

                              <div className="col-md-12">
                                <b>&nbsp;</b>
                              </div>

                              <div className="col-md-12">
                                <b>Foto Kop</b>
                              </div>
                              <div className="col-md-12">
                                <img alt="foto_kop" src={this.state.alamat_foto_kop} style={{ width: '150px' }} />
                              </div>

                            </div>
                        </>
                      :
                      (
                        <div className="col-md-12 text-center">
                          <h4 align="center">Pengaturan Aplikasi Belum Ada</h4>
                        </div>
                      )
                      }
                    </>
                  }
									</div>
								</div>

                <Modal
									open={this.state.status.form}
									onClose={this.tutupForm}
									styles={{ modal: { width: '80%' } }}
									closeOnEsc={false}
									closeOnOverlayClick={false}
								>
									<Form className="theme-form" onSubmit={this.simpan}>
										<div className="modal-header">
											<h4 className="modal-title">{this.state.judul}</h4>
										</div>

										<div className="modal-body">
											<input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.data.uuid}/>

											<div className="row">
                        <div className="col-12" align='center'>
                          <h5>Konfigurasi Umum</h5>
                          <hr/>
                        </div>
                        <div className="col-6">
                          <FormGroup className="row">
                            <Label className="col-sm-9 col-form-label" htmlFor="inputEmail3"><b>Riwayat Disposisi Perangkat</b></Label>
                            <Col sm='3'>
                              <Switch 
                                onChange={(e) => {
                                this.handleChange(e,'sidebar');
                                }}
                                checked={this.state.sidebar} 
                              />
                            </Col>
                          </FormGroup>	
                        </div>

                        <div className="col-6">
                          <FormGroup className="row">
                            <Label className="col-sm-9 col-form-label" htmlFor="inputEmail3"><b>Notifikasi Layanan Global</b></Label>
                            <Col sm='3'>
                              <Switch 
                                onChange={(e) => {
                                this.handleChange(e,'notif_global');
                                }}
                                checked={this.state.notif_global} 
                              />
                            </Col>
                          </FormGroup>	
                        </div>

                        <div className="col-6">
                          <FormGroup className="row">
                            <Label className="col-sm-9 col-form-label" htmlFor="inputEmail3"><b>Notifikasi Pengajuan Global</b></Label>
                            <Col sm='3'>
                              <Switch 
                                onChange={(e) => {
                                this.handleChange(e,'notif_pengajuan_global');
                                }}
                                checked={this.state.notif_pengajuan_global} 
                              />
                            </Col>
                          </FormGroup>	
                        </div>

                        <div className="col-6">
                          <FormGroup className="row">
                            <Label className="col-sm-9 col-form-label" htmlFor="inputEmail3"><b>Notifikasi Whatsapp</b></Label>
                            <Col sm='3'>
                              <Switch 
                                onChange={(e) => {
                                this.handleChange(e,'notif_wa');
                                }}
                                checked={this.state.notif_wa} 
                              />
                            </Col>
                          </FormGroup>	
                        </div>

												{/* <div className="col-lg-6 pl-3">
													<FormGroup className="row">
                              <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                             <b> Riwayat disposisi perangkat </b>
														</Label>
                            <br/>
														<div className="media-body text-right switch-sm">
                              <span>
                                {this.state.sidebar === false
                                  ? "Tidak aktif"
                                  : "Aktif"}
                              </span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="sidebar-toggle"
                                  checked={this.state.sidebar}
                                  style={{width: '150px'}}
                                  onClick={() => this.openCloseSidebar("sidebar")}
                                />
                                <span className="switch-state"></span>
                              </label>
                            </div>
													</FormGroup>
												</div>
                        <div className="col-lg-6 pl-3">
													<FormGroup className="row">
                              <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                              <b>Notifikasi Layanan Global</b>
														</Label>
														<div className="media-body text-right switch-sm">
                              <span>
                                {this.state.notif_global === false
                                  ? "Tidak aktif"
                                  : "Aktif"}
                              </span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="notif-toggle"
                                  checked={this.state.notif_global}
                                  onClick={() => this.openCloseSidebar("notif_global")}
                                />
                                <span className="switch-state"></span>
                              </label>
                            </div>
													</FormGroup>
												</div>
                        <div className="col-lg-6 pl-3">
													<FormGroup className="row">
                              <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                              <b>Notifikasi Whatsapp</b>
														</Label>
														<div className="media-body text-right switch-sm">
                              <span>
                                {this.state.notif_wa === false
                                  ? "Tidak aktif"
                                  : "Aktif"}
                              </span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="notif-toggle"
                                  checked={this.state.notif_wa}
                                  onClick={() => this.openCloseSidebar("notif_wa")}
                                />
                                <span className="switch-state"></span>
                              </label>
                            </div>
													</FormGroup>
												</div> */}

                        <div className="col-lg-12 pr-3">
                          <FormGroup className="row">
                            <Label className="col-sm-4 col-form-label" htmlFor="inputEmail3">
                            <b>Response Pengajuan Layanan</b>
                            </Label>
                           <Col sm="8">
													{/*<FormGroup className="row">
                              <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                              Response Pengajuan Layanan
														</Label> */}
                              <input
                                className="form-control"
                                id="response_pengajuan_layanan"
                                type="text"
                                placeholder="response"
                                defaultValue={this.state.response_pengajuan_layanan}
                              />
                            </Col>
													</FormGroup>
												</div>

                        <div className="col-lg-12 pl-3">
                        {this.state.dt.nomor_wa_perangkat.length > 0 ? (
                          <>
                            <FormGroup className="row">
                              <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                                <b>Nomor HP Perangkat</b>
                              </Label>
                            </FormGroup>
                            {this.state.dt.nomor_wa_perangkat.map((dti, i) => {
                              return (
                                <div style={{ border: '1px solid black', borderRadius: '10px', padding: '10px', marginBottom: '15px' }}>
                                  {/* Tombol hapus petunjuk */}
                                  
                                  {dti.isi.map((dt_isi, j) => {
                                    return (
                                      <>
                                        <FormGroup className="row">
                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                            Nomor HP {j+1}
                                          </Label>
                                          <Col sm="8">
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder="Contoh: 62823xxxxx"
                                              required
                                              defaultValue={dt_isi}
                                              onChange={(event2) => {
                                                let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
                                                let get_data = event2.target.value
                                                let cek = get_data.substring(0, 2);
                                                let set_data = null
                                                if(cek === '08'){
                                                  set_data = get_data.replace("08", "628")
                                                }
                                                else{
                                                  set_data = get_data
                                                }
                                                isi[j] = set_data

                                                let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
                                                nomor_wa_perangkat[i] = {...nomor_wa_perangkat[i], isi}
                
                                                this.setState({ 
                                                  dt: {...this.state.dt, 
                                                    nomor_wa_perangkat
                                                  }
                                                })
                                              }}
                                            />
                                          </Col>
                                          {j > 0 ? (
                                            <Col lg="1">
                                              <button
                                                className="btn btn-danger"
                                                title="Hapus detail petunjuk ini"
                                                style={{ padding: '6px 15px' }}
                                                onClick={() => {
                                                  let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
                                                  isi.splice(j, 1)

                                                  let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
                                                  nomor_wa_perangkat[i] = {...nomor_wa_perangkat[i], isi}
                  
                                                  this.setState({ 
                                                    dt: {...this.state.dt, 
                                                      nomor_wa_perangkat
                                                    }
                                                  })
                                                }}
                                              >-</button>
                                            </Col>
                                          ) : ''}
                                        </FormGroup>
                                      </>
                                    )
                                  })}
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <button
                                        type="button"
                                        className="btn btn-info"
                                        // disabled={this.state.status.btnForm}
                                        onClick={() => {
                                          let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
                                          isi.push('')
                                          let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
                                          nomor_wa_perangkat[i].isi = isi
                                          this.setState({ 
                                            dt: {...this.state.dt,
                                              nomor_wa_perangkat
                                            }
                                          })
                                        }}
                                      >
                                        Tambah No HP Perangkat
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </>
                        ) : ''}
												</div>
												
                        <div className="col-12" align='center'>
                          <hr/>
                          <h5>Konfigurasi Web Services</h5>
                          <hr/>
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b> Provinsi</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="provinsi"
                                type="text"
                                placeholder="Contoh: Riau, Sumatera Barat, Dll."
                                defaultValue={this.state.provinsi}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Jenis Kabupaten</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="jenis_kabkota"
                                type="text"
                                placeholder="Contoh: Kabupaten, Kota, Dll."
                                defaultValue={this.state.jenis_kabkota}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b> Kabupaten/Kota</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="kabkota"
                                type="text"
                                placeholder="Contoh: Pekanbaru, Kampar, Dll."
                                defaultValue={this.state.kabkota}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b> Kecamatan</b></Label>
                            <Col sm="9">
                              <input
                                className="form-control"
                                id="kecamatan"
                                type="text"
                                placeholder="Contoh: Sukajadi, Kampar Kiri Hilir, Dll."
                                defaultValue={this.state.kecamatan}
                                required
                              />
                            </Col>
                          </FormGroup>
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Jenis Desa</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="jenis_desa"
                                type="text"
                                placeholder="Contoh: Desa, Kelurahan, Nagari, Dll."
                                defaultValue={this.state.jenis_desa}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                              <b>Kelurahan / {JENIS_DESA}</b>
                            </Label>
                            <Col sm="9">
                            <input
                                className="form-control"
                                id="desa"
                                type="text"
                                placeholder="Contoh: Kampung Tengah, Sungai Petai, dll."
                                defaultValue={this.state.desa}
                                required
                              />
                            </Col>
                          </FormGroup>
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Jenis Dusun</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="jenis_dusun"
                                type="text"
                                placeholder="Contoh: Dusun, Jorong, Dll."
                                defaultValue={this.state.jenis_dusun}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Jabatan Tertinggi {JENIS_DESA}</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="jabatan_tertinggi"
                                type="text"
                                placeholder="Contoh: Kepala Desa, dll."
                                defaultValue={this.state.jabatan_tertinggi}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Nama Lengkap Kepala {JENIS_DESA}</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="nama_kades"
                                type="text"
                                placeholder="Contoh: Bima Sakti, S.I.Kom."
                                defaultValue={this.state.nama_kades}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Foto Kepala {JENIS_DESA}</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="foto_kades"
                                type="file"
                                onChange={this.onFileChange}
                                placeholder={"Foto Kepala Kades untuk website " + JENIS_DESA}
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Link Facebook</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="link_fb"
                                type="text"
                                placeholder="Contoh: www.facebook.com/desasungaipetai"
                                defaultValue={this.state.link_fb}
                                // required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Link Instagram</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="link_ig"
                                type="text"
                                placeholder="Contoh: www.instagram.com/desasungaipetai"
                                defaultValue={this.state.link_ig}
                                // required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Email</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="email"
                                type="text"
                                placeholder="Contoh: sungaipetai@gmail.com"
                                defaultValue={this.state.email}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Alamat Kantor Desa</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="alamat_kantor_desa"
                                type="text"
                                placeholder="Contoh: Jl. Raya Sungai Pagar KM. 35"
                                defaultValue={this.state.alamat_kantor_desa}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Kode Pos</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="kode_pos"
                                type="number"
                                maxLength='5'
                                placeholder="Contoh: 28282"
                                defaultValue={this.state.kode_pos}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>URL BO Digidesa</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="url_bo_digidesa"
                                type="text"
                                placeholder="Contoh: https://demo.digidesa.com"
                                defaultValue={this.state.url_bo_digidesa}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>URL WS Marketplace</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="url_marketplace"
                                type="text"
                                placeholder="Contoh: https://ws-marketplace-desa-prod.digidesa.com"
                                defaultValue={this.state.url_marketplace}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Type App</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="type_app"
                                type="text"
                                placeholder="Contoh: dev, prod"
                                defaultValue={this.state.type_app}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Heading Notif</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="heading_notif"
                                type="text"
                                placeholder="Contoh: Digidesa"
                                defaultValue={this.state.heading_notif}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Ukuran Kertas</b></Label>
                            <Col sm='9'>
                              <Select
                                isDisabled={this.state.status.select}
                                classNamePrefix="select"
                                onChange={(e) => {
                                  this.changeSelectValue(e, 'ukuran_kertas');
                                }}
                                defaultValue={this.state.selectedKertas}
                                value={this.state.selectedKertas}
                                name="Jenis Kelamin"
                                options={this.state.frmKertas}
                                placeholder="Pilih Ukuran Kertas"
                                isClearable
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Style Nama Kab Kota</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="style_nama_kabkota"
                                type="text"
                                // placeholder="Contoh: Digidesa"
                                defaultValue={this.state.style_nama_kabkota}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Style Kecamatan</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="style_kecamatan"
                                type="text"
                                // placeholder="Contoh: Digidesa"
                                defaultValue={this.state.style_kecamatan}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Style Desa</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="style_desa"
                                type="text"
                                // placeholder="Contoh: Digidesa"
                                defaultValue={this.state.style_desa}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Style Alamat</b></Label>
                            <Col sm='9'>
                              <input
                                className="form-control"
                                id="style_alamat"
                                type="text"
                                // placeholder="Contoh: Digidesa"
                                defaultValue={this.state.style_alamat}
                                required
                              />
                            </Col>
                          </FormGroup>	
                        </div>
                        <div className="col-12">
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"><b>Alamat Foto Kop</b></Label>
                            <Col sm='9'>
                              <Dropzone
                                onDrop={acceptedFiles => this.processGbr(acceptedFiles)}
                                maxFiles={1}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div
                                      {...getRootProps()}
                                      style={{
                                        minHeight: "100px",
                                        paddingTop: "40px",
                                        padding: "5px 5px 5px 5px",
                                        border: "1px solid #d2d2d2"
                                      }}
                                    >
                                      <input {...getInputProps()} />
                                      <div
                                        style={{
                                          justifyContent: "center",
                                          alignItems: "center"
                                        }}
                                      >
                                        <p className="text-center">
                                          {this.state.link_gambar ? (
                                            <img
                                              src={this.state.link_gambar}
                                              alt=""
                                              style={{
                                                minHeight: "50px",
                                                minWidth: "50px"
                                              }}
                                            />
                                          ) : (
                                            <Label
                                              className="col-form-label text-center"
                                              htmlFor="inputEmail3"
                                            >
                                              Drag 'n' drop some files here, or click to
                                              select files
                                            </Label>
                                          )}
                                          {this.state.foto_info
                                            ? this.state.foto_info
                                            : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            </Col>
                          </FormGroup>	
                        </div>

											</div>
										</div>

										<div className="modal-footer">
											<button type="button" className="btn btn-warning" onClick={this.tutupForm}>
												Batal
											</button>
											<button
												type="submit"
												className="btn btn-success"
												// disabled={this.state.status.btnFormAcc}
											>
												Simpan
											</button>
										</div>
									</Form>
								</Modal>
								
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BasicTable;