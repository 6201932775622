import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Get, JENIS_DUSUN, Post } from '../../function/Koneksi';

const ModalSuratPenguburan = ({ data, optionData, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData }) => {
	const dateFormat = require('dateformat');

	const frmDex = {
		uuid: "",
		id_jenis: "",
		nik: "",
		kk: "",
		nama_pemohon: "",
		tempat_lahir: "",
		tanggal_lahir: "",
		pekerjaan: "",
		pendidikan_terakhir: "",
		agama: "",
		status_perkawinan: "",
		jenis_kelamin: "",
		alamat: "",
		kewarganegaraan: "",
		tujuan_pembuatan: "",
		nik_pelapor: "",
		detail_surat: {
			waktu_meninggal: null,
			tempat_meninggal: "",
			waktu_dikebumikan: null,
			tempat_dikebumikan: "",
			timezone: {
				label: 'WIB',
				value: 'WIB',
			}
		}
	}

	const initFormData = {
		frmNik2: optionData.frmNik,
		nik2Stat: null,
		status: {
			form: false,
			btnForm: false,
			btnAksi: [],
			dataPenduduk2: "none",
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [dt, setDt] = useState(data ? data : frmDex)

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}

		setDt(data ? data : frmDex)
		setFormData(data ? {
			...initFormData,
			nik2Stat: optionData.nik2Stat,
			status: optionData.status,
		} : initFormData)
	}, [isOpen])

	const handleInputChangeNIK = (e, fld) => {
		if (e) {
			let nik2Stat = { value: e, label: e };

			Post("penduduk", null, { nik: e }, datanik => {
				let frmNik2 = [];
				datanik.data.results.data.forEach(dt => {
					frmNik2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
				});

				setFormData({
					...formData,
					frmNik2,
					nik2Stat
				})
			});

		}
	};

	const changeInputText = (e, fld) => {
		switch (fld) {
			case "tempat_meninggal":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						tempat_meninggal: e.target.value,
					},
				})
				break;
			case "tempat_dikebumikan":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						tempat_dikebumikan: e.target.value,
					},
				})
				break;
			case "tujuan_pembuatan":
				setDt({
					...dt,
					tujuan_pembuatan: e.target.value,
				})
				break;
			default:
				// no default
				break;
		}
	};

	const gantiTgl = (date, fld) => {
		switch (fld) {
			case "waktu_meninggal":
				if (typeof date._isValid !== 'undefined') {
					let tgl = date.format('YYYY-MM-DD HH:mm');
					setDt({
						...dt,
						detail_surat: {
							...dt.detail_surat,
							waktu_meninggal: new Date(tgl),
						},
					})
				}
				break;
			case "waktu_dikebumikan":
				if (typeof date._isValid !== 'undefined') {
					let tgl = date.format('YYYY-MM-DD HH:mm');
					setDt({
						...dt,
						detail_surat: {
							...dt.detail_surat,
							waktu_dikebumikan: new Date(tgl),
						},
					})
				}
				break;
			default:
				// no default
				break;
		}
	};

	const getPenduduk = (e, sel, jns) => {
		if (e) {
			Get("penduduk/find", e.value, data => {
				console.log(data)
				let alamat = '';
				if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
					alamat =
						"RT. " +
						data.results.data_kk.data_rt.nama +
						"/RW. " +
						data.results.data_kk.data_rt.data_rw.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_keldes.nama +
						", Kec. " +
						data.results.data_kk.data_rt.data_rw.data_keldes
							.data_kecamatan.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_keldes
							.data_kecamatan.data_kabkota.nama;
				} else {
					if (data.results.data_kk.id_rt === '0') {
						alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama +
							", Kec. " + data.results.data_keldes.data_kecamatan.nama +
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama;
					} else {
						alamat =
							" RT. " +
							data.results.data_kk.data_rt.nama +
							"/RW. " +
							data.results.data_kk.data_rt.data_rw.nama +
							", " + JENIS_DUSUN + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.nama +
							", " + localStorage.getItem('JENIS_DESA') + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
							", Kec. " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
								.data_kecamatan.nama +
							", " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
								.data_kecamatan.data_kabkota.nama;
					}
				}

				setFormData({
					...formData,
					nik2Stat: e,
					status: {
						...formData.status,
						dataPenduduk2: "block",
					},
				})
				setDt({
					...dt,
					nik: e.value,
					nik_pelapor: e.value,
					kk: data.results.id_kk,
					nama_pemohon: data.results.nama,
					tempat_lahir: data.results.tempat_lahir,
					tanggal_lahir: new Date(
						data.results.tanggal_lahir
					),
					pekerjaan: data.results.data_pekerjaan.nama,
					pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
					agama: data.results.data_agama.nama,
					status_perkawinan: data.results.status_perkawinan,
					jenis_kelamin: data.results.jk,
					alamat: alamat,
					kewarganegaraan: 'WNI',
				})

				// For testing
				if (props.selectOnChange2) {
					props.selectOnChange2(dt)
				}
			});
		} else {
			setDt({
				...dt,
				nama_pemohon: '',
				tempat_lahir: '',
				tanggal_lahir: null,
				pekerjaan: '',
				pendidikan_terakhir: '',
				agama: '',
				status_perkawinan: '',
				jenis_kelamin: '',
				alamat: '',
			})
			setFormData({
				...formData,
				status: {
					...formData.status,
					dataPenduduk2: "none"
				},
				nik2Stat: null
			})
		}
	};

	const simpan = (e) => {
		e.preventDefault();
		console.log('tesdfs');
		console.log(formData);

		let bodyRaw = {
			...dt,
			tanggal_lahir: dateFormat(new Date(dt.tanggal_lahir), 'yyyy-mm-dd'),
			tujuan_pembuatan: document.getElementById('tujuan_pembuatan').value,
			detail_surat: {
				...dt.detail_surat,
				waktu_dikebumikan: dateFormat(dt.detail_surat.waktu_dikebumikan, 'yyyy-mm-dd HH:MM'),
				waktu_meninggal: dateFormat(dt.detail_surat.waktu_meninggal, 'yyyy-mm-dd HH:MM'),
				timezone: dt.detail_surat.timezone.value
			}
		}
		if (dt.uuid === '') {
			bodyRaw.id_jenis = props.location.state.uuid
		}
		console.log(bodyRaw);

		let validasiPelapor = formData.nik2Stat;

		if (validasiPelapor) {
			//console.log("Data Simpan Pindah Nikah")
			//console.log(bodyRaw)
			console.log(bodyRaw);

			let psn = "";
			let resstat = 204;
			let metode = "store";
			if (bodyRaw.uuid === "") {
				psn = "Tambah";
				resstat = 201;
				bodyRaw.uuid = null;
			} else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
			Post(
				"surat-keterangan/new-format/" + metode,
				bodyRaw.uuid,
				bodyRaw,
				res => {

					// For testing
					if (props.resSubmit) {
						props.resSubmit(res.status)
					}

					setFormData({
						...formData,
						status: {
							...formData.status,
							btnForm: false,
							form: false,
						}
					})
					setIsOpen(false)
					if (res.status === resstat) {
						const alertData = {
							show: true,
							basicType: "success",
							basicTitle: "Data Surat Keterangan Penguburan",
							pesanAlert: "Berhasil " + psn + " Data",
						}
						setAlert(alertData)
					} else {
						const alertData = {
							show: true,
							basicType: "danger",
							basicTitle: "Data Surat Keterangan Penguburan",
							pesanAlert: "Gagal " + psn + " Data"
						}
						setAlert(alertData)
					}

					// Refresh list
					setStatusRefresh(true)
				}
			);
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			setAlert(alertData)
		}
	};

	return (
		<Modal
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			// classNames="modal-custom"
			closeOnEsc={false}
			closeOnOverlayClick={true}
			style={{
				maxwidth: 'unset'
			}}
		>
			<Form
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan}
				id="modalForm"
			>
				<div className="modal-header">
					<h5 className="modal-title">{dt.uuid == '' ? 'Tambah' : 'Edit'} Surat Keterangan Penguburan</h5>
				</div>
				<div className="modal-body">
					<p>
						Data identitas akan terisi secara otomatis saat NIK dipilih.
						Jika ada ketidak sesuaian data, silakan ubah di data master
						penduduk.
					</p>
					<input
						className="form-control"
						id="uuid"
						type="hidden"
						placeholder=""
						defaultValue={dt.uuid}
					/>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik2"
						>
							NIK Penduduk
						</Label>
						<Col sm="9">
							<div data-testid="selectNIK2">
								<Select
									inputId="nik2"
									classNamePrefix="select"
									onChange={e => {
										getPenduduk(e, "nik2", "pelapor");
									}}
									defaultValue={formData.nik2Stat}
									value={formData.nik2Stat}
									name="NIK2"
									options={formData.frmNik2}
									placeholder="Pilih NIK Penduduk"
									onInputChange={e => handleInputChangeNIK(e, "nik2")}
									isClearable
								/>
							</div>
						</Col>
					</FormGroup>
					<div style={{ display: formData.status.dataPenduduk2 }}>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Nama Pelapor
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="nama_pelapor"
									type="text"
									placeholder="Nama Pelapor"
									value={dt.nama_pemohon}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tempat Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tempat_lahir_pelapor"
									type="text"
									placeholder="Tempat Lahir"
									value={dt.tempat_lahir}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tanggal Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tanggal_lahir_pelapor"
									type="text"
									placeholder="Tanggal Lahir"
									value={new Date(dt.tanggal_lahir).toLocaleDateString("id-ID")}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
					</div>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Tujuan Pembuatan
						</Label>
						<Col sm="9">
							<input
								data-testid="tujuan pembuatan"
								name="tujuan_pembuatan"
								className="form-control"
								id="tujuan_pembuatan"
								type="text"
								placeholder="Contoh : Beasiswa"
								defaultValue={dt.tujuan_pembuatan}
								value={dt.tujuan_pembuatan}
								onChange={e => changeInputText(e, "tujuan_pembuatan")}
								required
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Tempat Meninggal
						</Label>
						<Col sm="9">
							<input
								data-testid="tempat meninggal"
								name="tempat_meninggal"
								className="form-control"
								id="tempat_meninggal"
								type="text"
								placeholder="Contoh : Rumah Sakit Ania"
								defaultValue={dt.detail_surat.tempat_meninggal}
								value={dt.detail_surat.tempat_meninggal}
								onChange={e => changeInputText(e, "tempat_meninggal")}
								required
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Waktu Meninggal
						</Label>
						<Col sm="9">
							<Datetime
								inputProps={{ "data-testid": "waktu_meninggal", placeholder: 'Waktu Meninggal', required: true }}
								inputId="waktu_meninggal"
								name="waktu_meninggal"
								locale="id-ID"
								timeFormat={"HH:mm"}
								dateFormat="DD/MM/yyyy"
								input={false}
								closeOnSelect={false}
								initialValue={dt.detail_surat.waktu_meninggal}
								value={dt.detail_surat.waktu_meninggal}
								onChange={e => {
									gantiTgl(e, 'waktu_meninggal')
								}}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Tempat dikebumikan
						</Label>
						<Col sm="9">
							<input
								data-testid="tempat dikebumikan"
								name="tempat_dikebumikan"
								className="form-control"
								id="tempat_dikebumikan"
								type="text"
								placeholder="Contoh : Rumah Sakit Ania"
								defaultValue={dt.detail_surat.tempat_dikebumikan}
								value={dt.detail_surat.tempat_dikebumikan}
								onChange={e => changeInputText(e, "tempat_dikebumikan")}
								required
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Waktu dikebumikan
						</Label>
						<Col sm="9">
							<Datetime
								inputProps={{ "data-testid": "waktu_dikebumikan", placeholder: 'Waktu dikebumikan', required: true }}
								inputId="waktu_dikebumikan"
								name="waktu_dikebumikan"
								locale="id-ID"
								timeFormat={"HH:mm"}
								dateFormat="DD/MM/yyyy"
								input={false}
								closeOnSelect={false}
								initialValue={dt.detail_surat.waktu_dikebumikan}
								value={dt.detail_surat.waktu_dikebumikan}
								onChange={e => {
									gantiTgl(e, 'waktu_dikebumikan')
								}}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik2"
						>
							Zona Waktu
						</Label>
						<Col sm="9">
							<div data-testid="selectTimeZone">
								<Select
									inputId="timezone"
									classNamePrefix="select"
									onChange={e => {
										setDt({
											...dt,
											detail_surat: {
												...dt.detail_surat,
												timezone: e
											}
										})
									}}
									defaultValue={dt.detail_surat.timezone}
									value={dt.detail_surat.timezone}
									name="timezone"
									options={[
										{
											label: 'WIB',
											value: 'WIB',
										},
										{
											label: 'WITA',
											value: 'WITA',
										},
										{
											label: 'WIT',
											value: 'WIT',
										},
									]}
									placeholder="Pilih Zona Waktu"
									isClearable
								/>
							</div>
						</Col>
					</FormGroup>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							setFormData({
								...formData,
								status: {
									...formData.status,
									form: false
								}
							})
							setIsOpen(false)
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						disabled={formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalSuratPenguburan;
