import React, { Component } from "react";

// Import authService
import {
  // Post,
  Get,
  // Delete,
  // cekLogin,
  // API_URL
} from "../../../function/Koneksi";

// import ReactList from "react-list";
import { Link } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroller";
class Notificationbaru extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total: 0,
      pagination: {
        total: 0
      },
      pesan: "Memuat notifikasi...."
    };
  }

  componentDidMount() {
  }
  cekNotifikasi = dt => {
    Get("list-notif/update/" + dt.uuid, null, res => {
      // console.log('Hasil Update')
      // console.log(res)
      this.props.refreshNotif()
    });
  };
  cekAllNotifikasi = dt => {
    Get("list-notif/all", null, res => {
      // console.log(res);
      this.props.refreshNotif()
    });
  };
  fetchMoreData = () => {
    if (this.state.pagination.current_page < this.state.pagination.last_page) {
      if (this.state.data.length > 10) {
        let link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
        this.setState({ pesan: "Memuat notifikasi...." });
  
        Get("list-notif" + link, null, res => {
          // console.log('List Notif Page')
          // console.log(res)
          // let data = res.results.data.data;
          let data = res.results.data.data;
          data = data.filter(day => day.status === '0');
          // console.log('Data Hasil Filter Page')
          // console.log(data)
          this.setState({
            data: [...this.state.data, ...data],
            pagination: data
          });
        });
      }
    } else {
      this.setState({ pesan: "" });
    }
  };
  renderItem = (index, key) => {
    let data = this.state.data;
    return data.length !== 0 ? (
      <li key={key}>
        <div className="media">
          <div className="media-body">
            <h6 className="mt-0">{data[index].konten}</h6>
            <span>
              <i className="icofont icofont-clock-time p-r-5"></i>Just Now
            </span>
          </div>
        </div>
      </li>
    ) : null;
  };

  render() {
    return (
      <li className="onhover-dropdown">
        <a className="txt-dark" href="/" style={{ pointerEvents: 'none' }}>
          <img
            className="align-self-center pull-right"
            src={require("../../../assets/images/dashboard/notification.png").default}
            alt="header-notification"
          />
          <span className="badge badge-pill badge-primary notification">
            {/* {this.state.total} */}
            {this.props.totalNotif}
          </span>
        </a>
        <ul className="notification-dropdown onhover-show-div custom-scrollbar ">
          <li>
            Notifikasi{" "}
            <span className="badge badge-pill badge-secondary text-white text-uppercase pull-right">
              {/* {this.state.total} baru */}
              {this.props.totalNotif} baru
            </span>
          </li>
          <div
            style={{ overflow: "auto", maxHeight: 200 }}
            ref={ref => (this.scrollParentRef = ref)}
          >
            <InfiniteScroll
              initialLoad={false}
              // loadMore={this.fetchMoreData}
              loadMore={() => {
                console.log('more more more');
                console.log(this.props.dataNotif.length);
                console.log(this.props.totalNotif);
                if (this.props.dataNotif.length < this.props.totalNotif) {
                  this.props.moreNotif()
                }
              }}
              hasMore={true}
              useWindow={false}
              getScrollParent={() => this.scrollParentRef}
              loader={
                this.state.pesan !== "" ? (
                  <li key={0}>
                    <div className="media">
                      <div className="media-body">
                        {/* <h6 className="mt-0">{this.state.pesan}.</h6> */}
                        <h6 className="mt-0">{this.props.pesanNotif}.</h6>
                      </div>
                    </div>
                  </li>
                ) : (
                  <></>
                )
              }
            >
              {/* {this.state.data.map((dt, idx) => { */}
              {this.props.dataNotif.map((dt, idx) => {
                let cek =
                dt.jenis === 'Perubahan Data'?
                  {
                    pathname: "/admin/perubahan-data/pengajuan",
                    state: { tab: "1" }
                  }
                :
                  dt.konten.indexOf("setujui") !== -1
                    ? { pathname: "/admin/disposisi", state: { tab: "2" } }
                    : { pathname: "/admin/disposisi", state: { tab: "1" } };
                return (
                  <li key={idx}>
                    <Link to={cek} onClick={() => this.cekNotifikasi(dt)}>
                      <div className="media">
                        <div className="media-body">
                           <h6
                            className={
                              dt.status === '0'
                                ? "mt-0"
                                : "mt-0 txt-danger "
                            }
                          >
                            {dt.konten}
                          </h6>
                          <span>
                            <i className="icofont icofont-clock-time p-r-5"></i>
                            {new Date(dt.created_at).toLocaleDateString(
                              "id-ID"
                            )}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </InfiniteScroll>
          </div>
          {/* <div style={{overflow: 'auto', maxHeight: 400}}>
          <ReactList
            itemRenderer={this.renderItem}
            length={this.state.data.length}
            type="uniform"
            pageSize={3}
          />
          </div>
           */}
          <li className="text-center">
            Detail
            <Link to={{ pathname: "/admin/notifikasi" }} onClick={() => {
              console.log('tes cek');
              this.cekAllNotifikasi()
            }}> semua </Link>
            notifikasi{" "}
          </li>
        </ul>
      </li>
    );
  }
}

export default Notificationbaru;
