import React, { Component } from 'react';
import { Col, Label, FormGroup, Button, Form } from 'reactstrap';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { Tanggal } from '../function/Format';
// import Keyboard from 'react-simple-keyboard';

// koneksi
import { Post, Get, JENIS_DUSUN } from '../function/Koneksi';

const frmDef = {
	uuid: '',
	id_jenis: '',
	nomor: '',
	tanggal_surat: new Date(),

	nik: '',
	kk: '',
	nama: '',
	tempat_lahir: '',
	tanggal_lahir: '',
	jenis_kelamin: '',
	pekerjaan: '',
	pendidikan_terakhir: '',
	kewarganegaraan: '',
	agama: '',
	alamat: '',
	status_perkawinan: '',
	tujuan_pembuatan: '',

	status_ttd: '',
	jabatan_ttd: '',
	jabatan_pengganti_ttd: '',
	nama_ttd: '',
	nip_ttd: '',
	status_ttd2: '',
	jabatan_ttd2: '',
	jabatan_pengganti_ttd2: '',
	nama_ttd2: '',
	nip_ttd2: ''
};

let aw = {
	nik: '',
	nama: '',
	tempat_lahir: '',
	tanggal_lahir: '',
	jenis_kelamin: '',
	pekerjaan: ''
};

export default class SuratKeteranganDomisili extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: true,
				btnAksi: [],
				dataPenduduk: 'none',
				dataPenduduk2: 'none'
			},
			// keyboard
			layoutName: 'default',
			inputName: 'input1',
			inputName1: 'kk',
			input: {},
			inputPattern: /^\d+$/,
			display: 'block',
			focus: false,
			hasFocus: false,
			//
			Basic: false,
			longContent: false,
			Vertically: false,
			Tooltips: false,
			Grid: false,
			VaryingMdo: false,
			VaryingFat: false,
			VaryingBootstrap: false,
			sizeLarge: false,
			sizeSmall: false,

			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: frmDef,
			judul: 'Tambah Surat Domisili',
			shdk: '',
			detail: [],
			// ============ Select 2 ==============
			frmJam: [],
			jamStat: [],
			frmPkr: [],
			pkrStat: [],
			frmAgm: [],
			agmStat: [],
			frmNik: [],
			nikStat: null,
			frmNik2: [],
			nik2Stat: null,
			frmNik3: [],
			nik3Stat: null,

			shdk1: null,
			// ===================================
			// ============ Alert ==============
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			successAlert: false,
			// ===================================
			// ============ Tanggal ==============
			tanggal_surat: new Date(),
			tanggal_lahir: new Date(),
			waktu_meninggal: new Date(),
			waktu_meninggal2: new Date(),
			// ===================================

			// =============== acc ===============
			formAcc: false,
			formSelectAcc: [ { value: 'Disetujui', label: 'Disetujui' }, { value: 'Ditolak', label: 'Ditolak' } ],
			frmTTD: [],
			accButton: [],
			accuuid: '',
			btnFormAcc: true,
			tableAcc: 'none',
			//dispopsisi

			datDisposisi: [],
			riwayatDisposisi: [],
			
			// Pilih Data Keluarga
			frmNikKeluarga: [],
			nikStatTerlapor: null,
			dt_keluarga:[],
			dataKeluarga: "none",
		};
		this.showKeyboard = this.showKeyboard.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
	}

	// state = {
	// 	Basic: false,
	// 	longContent: false,
	// 	Vertically: false,
	// 	Tooltips: false,
	// 	Grid: false,
	// 	VaryingMdo: false,
	// 	VaryingFat: false,
	// 	VaryingBootstrap: false,
	// 	sizeLarge: false,
	// 	sizeSmall: false
	// };

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	onChangeAll = (inputObj) => {
		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputObj[this.state.inputName]
		};
		this.setState({
			// input: inputObj
			input: updatedInputObj
		});
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				//console.log('Active input', inputName);
			}
		);
	};

	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		// //console.log(inputVal);

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard !== 'undefined') {
					this.keyboard.setInput(inputVal);
				}
			}
		);
	};

	onKeyPress = (button) => {
		// //console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}
		this.forceUpdate();
	};

	onOpenModal = (key) => {
		//console.log(key);
		this.setState({ [key]: true });
	};

	onCloseModal = (key) => {
		this.setState({ 
			[key]: false, 
			dt_keluarga:{
				nik: '',
				kk: '',
				nama: '',
				agama: '',
				alamat: '',
				jenis_kelamin: '',
				pekerjaan: '',
				status_perkawinan: '',
				tanggal_lahir: '',
				tempat_lahir: '',
				pendidikan_terakhir: '',
			},
			dataKeluarga:'none',
			nikStatTerlapor:null,
		});
	};

	handleClick = (event) => {
		alert(event);
	};

	componentDidMount() {
		// Get list anggota keluarga from parent
		if (this.props.anggotaKeluarga) {
			this.setState({ frmNikKeluarga: this.props.anggotaKeluarga })
		}
		// Get("penduduk/kk/" + this.props.data.results.id_kk, null, data => {
		// 	console.log("Data Keluarga")
		// 	console.log(data)
		// 	if(data.results){
		// 	  let frmNikKeluarga = [];
		// 	  data.results.forEach(dt => {
		// 		frmNikKeluarga.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
		// 	  });
		// 	  this.setState({ frmNikKeluarga });
			  
		// 	  // For testing
		// 	  if (this.props.getListNIKTerlapor) {
		// 		this.props.getListNIKTerlapor(frmNikKeluarga)
		// 	  }
		// 	}
		// });
		if (this.props.data.results.data_kk === null) {
			let frmNik3 = [];
			this.props.data.results.data_kk_pend_temp.data_keluarga.forEach((dt) => {
				frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
			});

			// this.state.frmNik3 = frmNik3;
			// this.forceUpdate();
			this.setState({ frmNik3 })
		} else {
			// Get list anggota keluarga from parent
			if (this.props.anggotaKeluarga) {
				this.setState({ 
					frmNik: this.props.anggotaKeluarga,
					frmNik2: this.props.anggotaKeluarga,
					frmNik3: this.props.anggotaKeluarga,
				})
			}
			// Get('penduduk/kk/' + this.props.data.results.id_kk, null, (data) => {
			// 	let frmNik = [];
			// 	// let frmNik2 = [];
			// 	// let frmNik3 = [];
			// 	if(data.results){
			// 		data.results.forEach((dt) => {
			// 			frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
			// 			// frmNik2.push({ value: dt.id, label: dt.id });
			// 			// frmNik3.push({ value: dt.id, label: dt.id });
			// 		});
			// 		this.setState({ frmNik, frmNik2 : frmNik, frmNik3 : frmNik });
			// 	}
			// });
		}
	}

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'jam':
					this.setState({ 
						jamStat: e, 
						dt:{
							...this.state.dt,
							jam: e.value,
						}
					});
					break;
				case 'pekerjaan':
					this.setState({ 
						pkrStat: e, 
						dt:{
							...this.state.dt,
							pekerjaan: e.label,
						}
					});
					break;
				case 'agama':
					this.setState({ 
						agmStat: e, 
						dt:{
							...this.state.dt,
							agama: e.label,
						}
					});
					break;
				case 'nik':
					this.setState({ 
						nikStat: e, 
						dt:{
							...this.state.dt,
							nik: e.value,
						} 
					});
					break;
				case 'nik2':
					this.setState({ 
						nik2Stat: e,
						dt:{
							...this.state.dt,
							nik2: e.value,
						} 
					});
					break;
				case 'nik3':
					// this.setState({ nik3Stat: e });
					this.setState({ 
						nik3Stat: {value: e.value, label: e.value},
						dt:{
							...this.state.dt,
							nik3: e.value,
						} 
					});
					break;
				case 'shdk1':
					this.setState({ 
						shdk1: e, 
						dt:{
							...this.state.dt,
							shdk1: e.value,
						}
					});
					break;
				case 'shdk_aw':
					this.setState({ 
						shdk_aw: e, 
						dt:{
							...this.state.dt,
							shdk_aw: e.value,
						}
					});
					break;
				case "nikTerlapor":
					this.setState({ nikStatTerlapor: e });
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case 'jam':
					this.setState({ 
						jamStat: e, 
						dt:{
							...this.state.dt,
							jam: 0,
						}
					});
					break;
				case 'pekerjaan':
					this.setState({ 
						pkrStat: e, 
						dt:{
							...this.state.dt,
							pekerjaan: '',
						}
					});
					break;
				case 'agama':
					this.setState({ 
						agmStat: e, 
						dt:{
							...this.state.dt,
							agama: '',
						}
					});
					break;
				case 'nik':
					this.setState({ 
						nikStat: e, 
						dt:{
							...this.state.dt,
							nik: 0,
						}
					});
					break;
				case 'nik2':
					this.setState({ 
						nik2Stat: e, 
						dt:{
							...this.state.dt,
							nik2: 0,
						}
					});
					break;
				case 'nik3':
					this.setState({ 
						nik3Stat: e, 
						dt:{
							...this.state.dt,
							nik3: 0,
						}
					});
					break;
				case 'shdk1':
					this.setState({ 
						shdk1: e, 
						dt:{
							...this.state.dt,
							shdk1: 0,
						}
					});
					break;
				case 'shdk_aw':
					this.setState({ 
						shdk_aw: e, 
						dt:{
							...this.state.dt,
							shdk_aw: 0,
						}
					});
					break;
				case "nikTerlapor":
					this.setState({ nikStatTerlapor: e });
					break;
				default:
					// no default
					break;
			}
		}

		this.forceUpdate();
	};

	getPenduduk = (e, sel) => {
		this.setState({
			status:{
				...this.state.status,
				btnForm: true,
			}
		})
		this.forceUpdate();
		if (e) {
			this.changeSelectValue(e, sel);
			Get('penduduk/find', e.value, (data) => {
				// const data_rt = data.results.data_kk.data_rt;
				// const data_rw = data_rt.data_rw;
				// const data_dusun = data_rw.data_dusun;
				// const data_desa = data_dusun.data_keldes;
				// const data_kecamatan = data_desa.data_kecamatan;
				// const data_kabkota = data_kecamatan.data_kabkota;
				let alamat = '';
				if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
					alamat =
					" RT. " +
					data.results.data_kk.data_rt.nama +
					"/RW. " +
					data.results.data_kk.data_rt.data_rw.nama +
					", " +
					data.results.data_kk.data_rt.data_rw.data_keldes.nama +
					", Kec. " +
					data.results.data_kk.data_rt.data_rw.data_keldes
						.data_kecamatan.nama +
					", " +
					data.results.data_kk.data_rt.data_rw.data_keldes
						.data_kecamatan.data_kabkota.nama; 
				} else {
					if (data.results.data_kk.id_rt === '0') {
						alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
						", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
						", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
						", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
					} else {
						alamat =
						"RT. " + 
						data.results.data_kk.data_rt.nama +
						"/RW. " +
						data.results.data_kk.data_rt.data_rw.nama +
						", " + JENIS_DUSUN + " " +
						data.results.data_kk.data_rt.data_rw.data_dusun.nama +
						", " + localStorage.getItem('JENIS_DESA') + " " +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
						", Kec. " +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.data_kabkota.nama; 
					}
				}
				if (sel === 'nik2') {
					this.setState({
						tanggal_lahir2: data.results.tanggal_lahir,
						dt:{
							...this.state.dt,
							nama2: data.results.nama,
							tanggal_lahir2: new Date(data.results.tanggal_lahir).toLocaleDateString('id-ID'),
							tempat_lahir2: data.results.tempat_lahir,
							jenis_kelamin2: data.results.jk,
							status_perkawinan2: data.results.status_perkawinan,
							agama2: data.results.data_agama.nama,
							pekerjaan2: data.results.data_pekerjaan.nama,
							kewarganegaraan2: 'Indonesia',
							alamat2: alamat,
						},
						status:{
							...this.state.status,
							dataPenduduk2: 'block',
						}
					})
				} else {
					if (sel === "nikTerlapor") {
						this.setState({
							dt_keluarga:{
								nik: e.value,
								kk: data.results.id_kk,
								nama: data.results.nama,
								agama: data.results.data_agama.nama,
								alamat: alamat,
								jenis_kelamin: data.results.jk,
								pekerjaan: data.results.data_pekerjaan.nama,
								status_perkawinan: data.results.status_perkawinan,
								tanggal_lahir: data.results.tanggal_lahir,
								tempat_lahir: data.results.tempat_lahir,
								pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
							},
							dataKeluarga: 'block',
						})
					}
					else if (sel === 'nik3') {
						aw.nik = e.value;
						aw.nama = data.results.nama;
						aw.jenis_kelamin = data.results.jenis_kelamin;
						aw.tanggal_lahir = data.results.tanggal_lahir;
						aw.tempat_lahir = data.results.tempat_lahir;
						aw.pekerjaan = data.results.data_pekerjaan.nama;
					} else {
						Get('catatan-penduduk/nik', data.results.id, (dpddk) => {
							let pctt = 0;
							let catatan = '';
							if (dpddk.results.data) {
								dpddk.results.data.forEach((dtp) => {
									if (dtp.status === 0 && dtp.penalti === 1) {
										pctt = 1;
										catatan = dtp.catatan;
									}
								});
							}
							if (pctt === 0) {
								this.setState({
									tanggal_lahir: data.results.tanggal_lahir,
									dt:{
										...this.state.dt,
										kk: data.results.id_kk,
										nama: data.results.nama,
										tanggal_lahir: new Date(data.results.tanggal_lahir).toLocaleDateString(
											'id-ID'
										),
										tempat_lahir: data.results.tempat_lahir,
										jenis_kelamin: data.results.jk,
										status_perkawinan: data.results.status_perkawinan,
										agama: data.results.data_agama.nama,
										pekerjaan: data.results.data_pekerjaan.nama,
										pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
										kewarganegaraan: 'Indonesia',
										alamat: alamat,
									},
									status:{
										...this.state.status,
										dataPenduduk: 'block',
										btnForm: false,
									}
								})
								this.forceUpdate();
							} else {
								this.setState({
									nikStat: null,
									frmNik: [],
									dt:{
										...this.state.dt,
										nama: '',
										tanggal_lahir: '',
										tempat_lahir: '',
										agama: '',
										pekerjaan: '',
										pendidikan_terakhir: '',
										kewarganegaraan: '',
										alamat: '',
										jenis_kelamin: '',
										status_perkawinan: '',
									},
									status:{
										...this.state.status,
										dataPenduduk: 'none',
										btnForm: true,
									},
									show: true,
									basicType: 'danger',
									basicTitle: 'Peringatan Catatan Warga',
									pesanAlert:
										'Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r' +
										catatan
								});
								
								this.forceUpdate();
							}
						});
					}
				}
				this.forceUpdate();
			});
		} else {
			if (sel === 'nik2') {
				this.setState({
					nik2Stat: null,
					dt:{
						...this.state.dt,
						nama2: '',
						tanggal_lahir2: '',
						tempat_lahir2: '',
						agama2: '',
						pekerjaan2: '',
						kewarganegaraan2: '',
						alamat2: '',
						jenis_kelamin2: '',
						status_perkawinan2: '',
						tempat_meninggal2: '',
						sebab_meninggal2: '',
					},
					status:{
						...this.state.status,
						dataPenduduk2: 'none',
					}
				})
			}
			else if(sel === "nikTerlapor"){
				this.setState({
					dt_keluarga:{
						nik: '',
						kk: '',
						nama: '',
						agama: '',
						alamat: '',
						jenis_kelamin: '',
						pekerjaan: '',
						status_perkawinan: '',
						tanggal_lahir: '',
						tempat_lahir: '',
						pendidikan_terakhir: '',
					},
					dataKeluarga: 'none',
					nikStatTerlapor:null,
				})
			} 
			else {
				this.setState({
					nikStat: null,
					dt:{
						...this.state.dt,
						nama: '',
						tanggal_lahir: '',
						tempat_lahir: '',
						agama: '',
						pekerjaan: '',
						pendidikan_terakhir: '',
						kewarganegaraan: '',
						alamat: '',
						jenis_kelamin: '',
						status_perkawinan: '',
					},
					status:{
						...this.state.status,
						dataPenduduk: 'none',
					},
				})
			}
			this.forceUpdate();
		}
	};

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld === "nikTerlapor") {
				let newArray = this.state.frmNikKeluarga;
	
				newArray = newArray.filter(day => day !== e);

				this.setState({
					frmNikKeluarga:newArray,
				})
			}
			else{
				let newArray = this.state.frmNik3;
	
				newArray = newArray.filter(day => day !== e);

				this.setState({
					frmNik3:newArray,
				})
			}
		}
	  };

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		this.setState({ Basic: false })
		this.props.setLoadingPreview(true)
		// this.forceUpdate();
		// //console.log(this.props.data.results);
		
		if (this.state.nikStatTerlapor) {
			// let alamat = '';
			// if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			// 	alamat =
			// 		' RT. ' +
			// 		this.props.data.results.data_kk.data_rt.nama +
			// 		'/RW. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
			// 		', Kec. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
			// } else {
			// 	// alamat =
			// 	// 	' RT. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.nama +
			// 	// 	'/RW. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 	// 	', ' +
			// 	// 	JENIS_DUSUN +
			// 	// 	' ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 	// 	', ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 	// 	', Kec. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
			// 	// 	', ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

			// 		if (this.props.data.results.data_kk.id_rt === '0') {
			// 			alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
			// 			", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
			// 		} else {
			// 			alamat =
			// 			" RT. " + 
			// 			this.props.data.results.data_kk.data_rt.nama +
			// 			"/RW. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 			", " + JENIS_DUSUN + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 			", " + JENIS_DESA + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 			", Kec. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.nama +
			// 			", " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.data_kabkota.nama; 
			// 		}
			// }
			let addData = {
				id_jenis: this.props.id_jenis,
				nik_pelapor: this.props.data.results.id,
				nik: this.state.dt_keluarga.nik,
				kk: this.state.dt_keluarga.kk,
				nama_pemohon: this.state.dt_keluarga.nama,
				tempat_lahir: this.state.dt_keluarga.tempat_lahir,
				tanggal_lahir: this.state.dt_keluarga.tanggal_lahir,
				pekerjaan: this.state.dt_keluarga.pekerjaan,
				agama: this.state.dt_keluarga.agama,
				status_perkawinan: this.state.dt_keluarga.status_perkawinan,
				alamat: this.state.dt_keluarga.alamat,
				jenis_kelamin: this.state.dt_keluarga.jenis_kelamin,
				pendidikan_terakhir: this.state.dt_keluarga.pendidikan_terakhir,
				
				kewarganegaraan: 'Indonesia',
				tujuan_pembuatan: document.getElementById('tujuan_pembuatan').value,
				detail: this.state.detail
			};
			// let psn = '';
			let resstat = 201;
			let metode = 'create';

			Post('surat-keterangan/domisili/' + metode, null, addData, (res) => {
				this.tutupForm();
				this.onCloseModal('Basic');

				let dataAntrian = {
					nama: this.props.data.results.nama,
					nik: this.props.data.results.id,
					keterangan: '',
					uuid_surat:res.data.results,
				};

				if (res.status === resstat) {
					setTimeout(function () {
						Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
							//console.log("Hasil Update Status")
							//console.log(res2)
							if (res2.code === 201) {
								//console.log("Berhasil Ganti Status Menjadi Selesai")
	
								this.props.getAntrian('Surat Keterangan Domisili', 'berhasil', false, dataAntrian);
							}
							else{
								//console.log("Gagal Ganti Status Menjadi Selesai")
	
								this.props.getAntrian('Surat Keterangan Domisili', 'gagal');
							}
						});
					}.bind(this),1000)
					// this.props.getAntrian('Surat Keterangan Domisili', 'berhasil', false, dataAntrian);
				} else {
					this.props.getAntrian('Surat Keterangan Domisili', 'gagal');
				}
				this.componentDidMount();
				// reset cache
				document.getElementById('tujuan_pembuatan').value = '';
				this.setState({
					detail: [],
					nik3Stat: null,
					shdk1: null,
					input: {},
					display: 'none',
				})
				this.forceUpdate();
			});
		} else {
			this.props.getAntrian('Surat Keterangan Domisili', 'gagal', true);
		}
	};
	// ========================================================================

	hapus = (i) => {
		this.state.detail.splice(i, 1);
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			nikStat: null,
			nik3Stat: null,
			detail: [],
			accStat: null,
			tableAcc: 'none',
			formAcc: false,
			formDisposisi: false,
			shdk1: null,
			dt:{
				...this.state.dt,
				nama: '',
				tanggal_lahir: '',
				tempat_lahir: '',
				agama: '',
				pekerjaan: '',
				pendidikan_terakhir: '',
				kewarganegaraan: '',
				alamat: '',
				jenis_kelamin: '',
				status_perkawinan: '',
			},
			status:{
				...this.state.status,
				dataPenduduk: 'none',
				btnForm: true,
				form: false,
			}
		})
		
		
		this.forceUpdate();
	};

	tambahAw = (e) => {
		if (this.state.nik3Stat && this.state.shdk1) {
			let detail = this.state.detail;
			if (this.props.data.results.data_kk === null) {
				Get('temp/penduduk/find', this.state.nik3Stat.value, (data) => {
					//console.log(data.results)
					if (data.results) {
						detail.push({
							nik: this.state.nik3Stat.value,
							nama: data.results.nama,
							jenis_kelamin: data.results.jenis_kelamin,
							tanggal_lahir: data.results.tanggal_lahir,
							tempat_lahir: data.results.tempat_lahir,
							pekerjaan: data.results.data_pekerjaan.nama,
							pendidikan: data.results.data_pendidikan_terakhir.nama,
							shdk: this.state.shdk1.value,
							keterangan: ''
						});
						this.setState({ 
							detail, 
							// reset kolom data keluarga yang ikut
							nik3Stat: null,
							shdk1: null,
						});
						this.forceUpdate();
					}
				});
			} else {
				Get('penduduk/find', this.state.nik3Stat.value, (data) => {
					//console.log(data.results)
					if (data.results) {
						detail.push({
							nik: this.state.nik3Stat.value,
							nama: data.results.nama,
							jenis_kelamin: data.results.jenis_kelamin,
							tanggal_lahir: data.results.tanggal_lahir,
							tempat_lahir: data.results.tempat_lahir,
							pekerjaan: data.results.data_pekerjaan.nama,
							pendidikan: data.results.data_pendidikan_terakhir.nama,
							shdk: this.state.shdk1.value,
							keterangan: ''
						});
						this.setState({ 
							detail ,
							// reset kolom data keluarga yang ikut
							nik3Stat: null,
							shdk1: null,
						});
						this.forceUpdate();
					}
				});
			}
		} else {
			this.props.getAntrian('Surat Keterangan Domisili', 'cekDataPengikut');
		}

		// detail = this.state.detail;
		// let shdknya = this.state.shdk1.value;

		// // shdk belum ditambahkan
		// if (typeof shdknya === 'undefined' || typeof shdknya === null) {
		// 	this.props.isComplete(false);
		// } else if (shdknya === '') {
		// 	this.props.isComplete(false);
		// } else if (shdknya === null) {
		// 	this.props.isComplete(false);
		// } else if (this.state.nik3Stat !== null) {
		// 	// nik pengikut sudah dipilih
		// 	if (this.props.data.results.data_kk === null) {
		// 		Get('temp/penduduk/find', this.state.nik3Stat.value, (data) => {
		// 			if (data.results) {
		// 				detail.push({
		// 					nik: this.state.nik3Stat.value,
		// 					nama: data.results.nama,
		// 					jenis_kelamin: data.results.jenis_kelamin,
		// 					tanggal_lahir: data.results.tanggal_lahir,
		// 					tempat_lahir: data.results.tempat_lahir,
		// 					pekerjaan: data.results.data_pekerjaan.nama,
		// 					pendidikan: data.results.data_pendidikan_terakhir.nama,
		// 					shdk: this.state.shdk1.value,
		// 					keterangan: ''
		// 				});
		// 				this.setState({ detail });
		// 				// reset kolom data keluarga yang ikut
		// 				this.state.nik3Stat = null;
		// 				this.state.shdk1 = null;
		// 				this.forceUpdate();
		// 			}
		// 		});
		// 	} else {
		// 		Get('penduduk/find', this.state.nik3Stat.value, (data) => {
		// 			if (data.results) {
		// 				detail.push({
		// 					nik: this.state.nik3Stat.value,
		// 					nama: data.results.nama,
		// 					jenis_kelamin: data.results.jenis_kelamin,
		// 					tanggal_lahir: data.results.tanggal_lahir,
		// 					tempat_lahir: data.results.tempat_lahir,
		// 					pekerjaan: data.results.data_pekerjaan.nama,
		// 					pendidikan: data.results.data_pendidikan_terakhir.nama,
		// 					shdk: this.state.shdk1.value,
		// 					keterangan: ''
		// 				});
		// 				this.setState({ detail });
		// 				// reset kolom data keluarga yang ikut
		// 				this.state.nik3Stat = null;
		// 				this.state.shdk1 = null;
		// 				this.forceUpdate();
		// 			}
		// 		});
		// 	}
		// } else {
		// 	this.props.isComplete(false);
		// }
	};

	render() {
		const { Basic } = this.state;

		let no = 0;
		const domisili = this.state.detail.map((dt, i) => {
			no++;
			let jk = 'L';
			if (dt.jenis_kelamin === 'Perempuan') {
				jk = 'P';
			}
			return (
				<tr key={i}>
					<td>{no}</td>
					<td>{dt.nama}</td>
					<td>{jk}</td>
					<td>
						{dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
					</td>
					<td>{dt.pekerjaan}</td>
					<td>{dt.pendidikan}</td>
					<td>{dt.shdk}</td>
					<td>
						<Button size="xs" onClick={() => this.hapus(i)} color="danger" className="btn-icon">
							<i className="fa fa-trash" />
						</Button>
					</td>
				</tr>
			);
		});
		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const shdk = [
			{ value: 'Suami', label: 'Suami' },
			{ value: 'Isteri', label: 'Isteri' },
			{ value: 'Orang Tua', label: 'Orang Tua' },
			{ value: 'Anak', label: 'Anak' },
			{ value: 'Kakak', label: 'Kakak' },
			{ value: 'Adik', label: 'Adik' },
			{ value: 'Mertua', label: 'Mertua' },
			{ value: 'Menantu', label: 'Menantu' },
			{ value: 'Cucu', label: 'Cucu' },
			{ value: 'Pembantu', label: 'Pembantu' },
			{ value: 'Famili Lain', label: 'Famili Lain' }
		];

		return (
			<div>
				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div
							// style={{ marginBottom: this.state.display === 'block' ? '200px' : '0px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								// //console.log(e);
								this.setState({
									isDown: true,
									startY: e.pageY - slider.offsetTop,
									scrollUp: slider.scrollTop,
								})
								this.forceUpdate();
								// //console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								// //console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title">Surat Keterangan Domisili</h5>
							</div>

							<div className="modal-body">
								{/* <FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik"
											disabled
											type="text"
											value={this.props.data.results.id}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nama Lengkap Pemohon
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama"
											type="text"
											value={this.props.data.results.nama}
											disabled
										/>
									</Col>
								</FormGroup> */}
								
								<FormGroup className="row">
									<Label
									className="col-sm-3 col-form-label"
									htmlFor="nik"
									>
									NIK Terlapor
									</Label>
									<Col sm="9">
									<div data-testid="selectNIKTerlapor">
										<Select
										inputId="nikTerlapor"
										classNamePrefix="select"
										onChange={e => {
											this.getPenduduk(e, "nikTerlapor");
										}}
										defaultValue={this.state.nikStatTerlapor}
										value={this.state.nikStatTerlapor}
										name="NIK"
										options={this.state.frmNikKeluarga}
										placeholder="Pilih NIK Terlapor"
										onInputChange={e => this.handleInputChangeNIK(e, "nikTerlapor")}
										isClearable
										/>
									</div>
									</Col>
								</FormGroup>

								<div style={{ display: this.state.dataKeluarga }}>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Nama Terlapor
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Nama Terlapor"
										value={this.state.dt_keluarga.nama}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tempat_lahir"
										type="text"
										placeholder="Tempat Lahir"
										value={this.state.dt_keluarga.tempat_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tanggal Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tanggal_lahir"
										type="text"
										placeholder="Tanggal Lahir"
										value={this.state.dt_keluarga.tanggal_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
								</div>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Lama Tinggal
									</Label>
									<Col sm="9">
										<input
											onFocus={(e) => {
												this.setState({ display: 'block' });
												// //console.log(e.target);
												this.setActiveInput('tujuan_pembuatan');
											}}
											value={this.state.input['tujuan_pembuatan'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="tujuan_pembuatan"
											type="text"
											placeholder="Lama Tinggal. Contoh: 6 Bulan, atau 12 Tahun."
											required
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-12 col-form-label">Daftar Keluarga Yang Ikut</Label>
									<Col sm="5">
										<Select
											onFocus={(e) => {
												this.setState({ display: 'none' });
												// //console.log(e.target);
												// this.setActiveInput('nik3');
											}}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'nik3');
											}}
											defaultValue={this.state.nik3Stat}
											value={this.state.nik3Stat}
											name="NIK3"
											options={this.state.frmNik3}
											placeholder="Pilih NIK Keluarga"
											onInputChange={(e) => this.handleInputChangeNIK(e, 'nik3')}
											isClearable
										/>
									</Col>
									<Col sm="4">
										<Select
											onFocus={(e) => {
												this.setState({ display: 'none' });
												// //console.log(e.target);
												// this.setActiveInput('nik3');
											}}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'shdk1');
											}}
											value={this.state.shdk1}
											id="shdk"
											options={shdk}
											placeholder="Hubungan Keluarga"
										/>
									</Col>
									<Col sm="3">
										<button type="button" className="btn btn-success" onClick={this.tambahAw}>
											Tambah
										</button>
									</Col>
									<Col sm="12">
										<table className="table table-bordered table-stripes table-hovered">
											<thead>
												<tr>
													<th>No</th>
													<th>Nama</th>
													<th>JK</th>
													<th>Tempat/Tanggal Lahir</th>
													<th>Pekerjaan</th>
													<th>Pendidikan</th>
													<th>Hubungan Keluarga</th>
													<th>
														<i className="fa fa-trash" />
													</th>
												</tr>
											</thead>
											<tbody>{domisili}</tbody>
										</table>
									</Col>
								</FormGroup>
							</div>
							<div
								className="modal-footer"
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
							>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-success">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>
					{/* <div
						style={{
							position: 'fixed',
							bottom: 0,
							left: 0,
							width: '100%',
							zIndex: 4,
							display: this.state.display
						}}
					>
						<Keyboard
							style={{ marginTop: '40px', zIndex: 3 }}
							keyboardRef={(r) => (this.keyboard = r)}
							inputName={this.state.inputName}
							layoutName={this.state.layoutName}
							onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
							onKeyPress={(button) => this.onKeyPress(button)}
						/>
					</div> */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
						// background: 'linear-gradient(135deg, #a75c1d, #262aad)'
						// background: 'linear-gradient(135deg, #484848, #000000)'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/domisili.png')}
								height="65"
								alt="logo"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p className="mb-0" style={{ fontSize: '20px', fontWeight: '600', color: '#000000' }}>
								Keterangan Domisili
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
