import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// import Select from "react-select";
import Datepicker from "react-datepicker";
import { Link, Redirect } from "react-router-dom";

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, JENIS_DUSUN } from "../function/Koneksi";
// import { Tanggal, HariTanggal } from "../function/Format";
// import { logo } from "./asset/base64img";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// Import the library

import "react-datetime/css/react-datetime.css";
// import Datetime from "react-datetime";
// import NumberFormat from "react-number-format";
import ModalSuratPenguburan from "./modal/penguburan";
//json file
// var data = require('../assets/json/company');

const frmDex = {
  uuid: "",
  id_jenis: "",
  nik: "",
  kk: "",
  nama_pemohon: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  pendidikan_terakhir: "",
  agama: "",
  status_perkawinan: "",
  jenis_kelamin: "",
  alamat: "",
  kewarganegaraan: "",
  tujuan_pembuatan: "",
  nik_pelapor: "",
  detail_surat: {
    waktu_meninggal: "",
    tempat_meninggal: "",
    waktu_dikebumikan: "",
    tempat_dikebumikan: ""
  }
}

const dateFormat = require('dateformat');

class SuratKeteranganPenguburan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: [],
        dataPenduduk2: "none",
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDex,
      loading: false,
      loadingPreview: false,
      judul: "Tambah Surat Keterangan Penguburan",
      // ============ Select 2 ==============
      frmJam: [],
      jamStat: [],
      pkrStat: null,
      frmAgm: [],
      agmStat: [],
      frmNik: [],
      nikStat: null,
      frmNik2: [],
      nik2Stat: null,
      frmNik3: [],
      nik3Stat: null,
      shdk: null,
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,
      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      tanggal_lahir: new Date(),
      // ===================================
      // =============== acc ===============
      formAcc: false,
      frmTTD: [],
      listPekerjaan: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      //dispopsisi

      riwayatDisposisi: [],
      formIbu: "none",
      formAyah: "none"
    };
  }

  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "waktu_meninggal":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm');
          this.setState({
            dt: {
              ...this.state.dt,
              detail_surat: {
                ...this.state.dt.detail_surat,
                waktu_meninggal: new Date(tgl),
              },
            },
          })
        }
        break;
      case "waktu_dikebumikan":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm');
          this.setState({
            dt: {
              ...this.state.dt,
              detail_surat: {
                ...this.state.dt.detail_surat,
                waktu_dikebumikan: new Date(tgl),
              },
            },
          })
        }
        break;
      default:
        // no default
        break;
    }
  };

  // ==============================================

  // ================== change input text ==================
  changeInputText = (e, fld) => {
    switch (fld) {
      case "tempat_meninggal":
        this.setState({
          dt: {
            ...this.state.dt,
            detail_surat: {
              ...this.state.dt.detail_surat,
              tempat_meninggal: e.target.value,
            },
          },
        })
        this.forceUpdate();
        break;
      case "tempat_dikebumikan":
        this.setState({
          dt: {
            ...this.state.dt,
            detail_surat: {
              ...this.state.dt.detail_surat,
              tempat_dikebumikan: e.target.value,
            },
          },
        })
        this.forceUpdate();
        break;
      default:
        // no default
        break;
    }
  };

  // =======================================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    this.setState({ loading: true })
    // let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    // if (params.page) {
    //   switch (params.page) {
    //     case ">>":
    //       link =
    //         "?page=" +
    //         Math.ceil(
    //           this.state.pagination.total / this.state.pagination.per_page
    //         );
    //       break;
    //     case ">":
    //       link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
    //       break;
    //     case "<":
    //       link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
    //       break;
    //     case "<<":
    //       link = "?page=1";
    //       break;
    //     default:
    //       link = "?page=" + params.page;
    //       break;
    //   }
    // }
    Get("surat-keterangan/tidak-mampu/" + this.props.location.state.uuid, null, dtkat => {
      if (dtkat.results) {
        this.setState({ data: [] })
        this.setState({ data: dtkat.results.data, pagination: dtkat.results, loading: false });
        this.pagination(dtkat.results);

        // For testing
        if (this.props.getListSuratKelahiran) {
          this.props.getListSuratKelahiran(dtkat.results.data)
        }
      }
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let bodyRaw = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/kelahiran",
        null,
        bodyRaw,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
        }
      );
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    console.log('tesdfs');
    console.log(this.state.dt);

    let bodyRaw = {
      ...this.state.dt,
      id_jenis: this.props.location.state.uuid,
      // tanggal_lahir: dateFormat(new Date(this.state.dt.tanggal_lahir), 'yyyy-mm-dd HH:MM:ss'),
      tanggal_lahir: dateFormat(new Date(this.state.dt.tanggal_lahir), 'yyyy-mm-dd'),
      tujuan_pembuatan: document.getElementById('tujuan_pembuatan').value,
      detail_surat: {
        ...this.state.dt.detail_surat,
        waktu_dikebumikan: dateFormat(this.state.dt.detail_surat.waktu_dikebumikan, 'yyyy-mm-dd HH:MM'),
        waktu_meninggal: dateFormat(this.state.dt.detail_surat.waktu_meninggal, 'yyyy-mm-dd HH:MM'),
      }
    }
    console.log(bodyRaw);

    let validasiPelapor = this.state.nik2Stat;

    if (validasiPelapor) {
      //console.log("Data Simpan Pindah Nikah")
      //console.log(bodyRaw)
      console.log(bodyRaw);

      let psn = "";
      let resstat = 204;
      let metode = "store";
      if (bodyRaw.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        bodyRaw.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      Post(
        "surat-keterangan/new-format/" + metode,
        bodyRaw.uuid,
        bodyRaw,
        res => {

          // For testing
          if (this.props.resSubmit) {
            this.props.resSubmit(res.status)
          }

          this.tutupForm();
          this.setState({
            status: {
              ...this.state.status,
              btnForm: false,
              form: false,
            },
          })
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Surat Keterangan Penguburan",
              pesanAlert: "Berhasil " + psn + " Data",
              successAlert: true
            });
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Surat Keterangan Penguburan",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        }
      );
    } else {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Data Belum Lengkap",
        // pesanAlert: "Mohon pilih jenis kelamin anak, NIK ayah, NIK ibu, NIK Pelapor, dan hubungan pelapor"
        pesanAlert: "Mohon isi seluruh data dengan benar"
      });
    }
  };
  // ========================================================================

  // ============================== Ubah Data ===============================
  tambahData = e => {
    //console.log(this.props.location.state);

    this.setState({
      status: {
        ...this.state.status,
        dataPenduduk2: "none",
      },
      judul: "Tambah Surat Keterangan Penguburan",
      dt: null,
      nikStat: null,
      nik2Stat: null,
      nik3Stat: null,
    });
    this.forceUpdate();
    this.bukaForm();
  };
  // ========================================================================
  // ========================================================================

  //disposisi
  disposisi = (label, id) => {
    //console.log(label);
    Get("disposisi/kelahiran/" + label + "/" + id, null, dtkat => {
      if (dtkat.code === 201) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Disposisi",
          pesanAlert: "Berhasil  Disposisi Data"
        });
        this.componentDidMount();
        this.tutupForm();
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Disposisi",
          pesanAlert: "Disposisi telah dilakukan"
        });
        this.tutupForm();
      }
    });
    //console.log(id);
  };
  ubahData = data => {
    this.setState({ loadingPreview: true })
    this.setState({
      judul: "Ubah Surat Keterangan Penguburan",
    })

    let detail_surat = JSON.parse(data.detail_surat)

    detail_surat = {
      ...detail_surat,
      waktu_dikebumikan: new Date(detail_surat.waktu_dikebumikan),
      waktu_meninggal: new Date(detail_surat.waktu_meninggal),
    }

    this.setState({
      status: {
        ...this.state.status,
        dataPenduduk2: "block"
      }
    })

    this.setState({
      dt: {
        ...this.state.dt,
        uuid: data.uuid,
        id_jenis: data.id_jenis,
        nik: data.nik,
        kk: data.kk,
        nama_pemohon: data.nama_pemohon,
        tempat_lahir: data.tempat_lahir,
        tanggal_lahir: data.tanggal_lahir,
        pekerjaan: data.pekerjaan,
        pendidikan_terakhir: data.pendidikan_terakhir,
        agama: data.agama,
        status_perkawinan: data.status_perkawinan,
        jenis_kelamin: data.jenis_kelamin,
        alamat: data.alamat,
        kewarganegaraan: data.kewarganegaraan,
        tujuan_pembuatan: data.tujuan_pembuatan,
        nik_pelapor: data.nik_pelapor,
        detail_surat
      },
      status: {
        ...this.state.status,
        dataPenduduk2: "block",
        form: true
      },
      nik2Stat: {
        value: data.nik_pelapor,
        label: data.nik_pelapor + ' (' + data.nama_pemohon + ')'
      },
      loadingPreview: false
    })

  };
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/kelahiran/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Keterangan Penguburan",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Keterangan Penguburan",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.setState({
      status: {
        ...this.state.status,
        form: true,
      },
    })
    // this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      dt: {
        ...this.state.dt,
        nama_ayah: "",
        // this.state.dt.tanggal_lahir_ayah = "";
        tanggal_lahir_ayah: null,
        tempat_lahir_ayah: "",
        // this.state.dt.pekerjaan_ayah = "";
        pekerjaan_ayah: null,
        alamat_ayah: "",
        nama_pelapor: "",
        tanggal_lahir_pelapor: "",
        tempat_lahir_pelapor: "",
        agama_pelapor: "",
        pekerjaan_pelapor: "",
        alamat_pelapor: "",
        jk_pelapor: "",
        status_perkawinan_pelapor: "",
        nama_ibu: "",
        // this.state.dt.tanggal_lahir_ibu = "";
        tanggal_lahir_ibu: null,
        tempat_lahir_ibu: "",
        // this.state.dt.pekerjaan_ibu = "";
        pekerjaan_ibu: null,
        alamat_ibu: "",
        tempat_lahir_anak: "",
        nama_anak: "",
        jk_anak: "",
        nik_ayah: "",
        nik_ibu: "",
      },
      status: {
        ...this.state.status,
        dataPenduduk2: "none",
        form: false,
      },
      nikStat: null,
      nik2Stat: null,
      nik3Stat: null,
      shdk: null,
      formAyah: "none",
      formIbu: "none",
      accStat: null,
      tableAcc: "none",
      formDisposisi: false,
      formAcc: false,
    })

    this.forceUpdate();
  };

  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get(
      "surat-keterangan/kelahiran/persetujuan/" + jawaban + "/" + id,
      null,
      res => {
        if (res.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  tombolAksi = (cell, row) => {
    // let btnApproval = false;
    // let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.forEach(dt => {
      status = dt.status;
    });
    if (status === "Diajukan") {
      // btnCetak = true;
      btnAksi = true;
      // btnApproval = true;
    } else {
      btnAksi = true;
      // btnApproval = true;
      if (status === "Ditolak") {
        // btnCetak = true;
      } else if (status === "Disetujui") {
        // btnCetak = true;
        btnAksi = false;
        // btnApproval = false;
      } else if ((status === "Menunggu Penerbitan") | (status === "Selesai")) {
        // btnAksi = false;
        // btnCetak = false;
        // btnApproval = false;
      } else {
        // btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button size="xs" color="info" className="btn-icon" onClick={() => this.konfirmApprove(row.uuid)} disabled={btnApproval}><i className="fa fa-check"></i></Button> &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        {/* <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "} */}
        {/* &nbsp;&nbsp; */}
        {/* <Button size="xs" color="info" className="btn-icon" onClick={(e) => this.cetakSurat(e, row)} disabled={btnCetak}><i className="fa fa-print"></i></Button> */}
        {/* &nbsp;&nbsp; */}
        {/* //list */}
      </>
    );
  };

  componentDidMount() {
    //console.log(this.props.location.state);

    if (this.props.location.state) {
      this.fetch({ page: null, where: [] });
      let frmJam = [];
      let jamnya = "";
      for (let i = 1; i <= 24; i++) {
        jamnya = i;
        if (i < 10) {
          jamnya = "0" + i;
        }
        jamnya += ":00";
        frmJam.push({ value: jamnya, label: jamnya });
      }
      this.setState({ frmJam });
      Get("agama/list", null, data => {
        let frmAgm = [];
        data.results.forEach(dt => {
          frmAgm.push({ value: dt.uuid, label: dt.nama });
        });
        this.setState({ frmAgm });
      });
      Get("penduduk", null, data => {
        let frmNik = [];
        data.results.data.forEach(dt => {
          frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
        });
        this.setState({ frmNik, frmNik2: frmNik, frmNik3: frmNik });

        // For testing
        if (this.props.getListNIK) {
          this.props.getListNIK(frmNik)
        }
      });
      Get("pekerjaan/list", null, data => {
        let listPekerjaan = []
        data.results.forEach((dt, i) => {
          listPekerjaan.push({ value: dt.uuid, label: dt.nama })
        });
        this.setState({ listPekerjaan });

        // For testing
        if (this.props.getListPekerjaan) {
          this.props.getListPekerjaan(listPekerjaan)
        }
      });
      Get("tanda-tangan/list", null, data => {
        data.results.forEach((dt, i) => {
          if (dt.aktif === 1) {
            let temp = this.state.accButton.map(x => 1);
            temp[i] = 0;
            this.setState({
              accButton: temp,
              accuuid: dt.uuid,
            })
          }
        });
        this.forceUpdate();
        this.setState({ frmTTD: data.results });
      });
    } else {
      // this.setState({ redirect: true });
    }
  }

  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        let nikStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik = [];
          datanik.data.results.data.forEach(dt => {
            frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmNik, nikStat });
        });
      } else {
        if (fld === "nik2") {
          let nik2Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            let frmNik2 = [];
            datanik.data.results.data.forEach(dt => {
              frmNik2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
            });
            this.setState({ frmNik2, nik2Stat });
          });
        } else {
          let nik3Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            let frmNik3 = [];
            datanik.data.results.data.forEach(dt => {
              frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
            });
            this.setState({ frmNik3, nik3Stat });
          });
        }
      }
    }
  };

  getPenduduk = (e, sel, jns) => {
    // this.state.status.btnForm = true;
    this.forceUpdate();
    if (e) {
      this.changeSelectValue(e, sel);
      Get("penduduk/find", e.value, data => {
        console.log(data)
        let alamat = '';
        if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
          alamat =
            " RT. " +
            data.results.data_kk.data_rt.nama +
            "/RW. " +
            data.results.data_kk.data_rt.data_rw.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_keldes.nama +
            ", Kec. " +
            data.results.data_kk.data_rt.data_rw.data_keldes
              .data_kecamatan.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_keldes
              .data_kecamatan.data_kabkota.nama;
        } else {
          if (data.results.data_kk.id_rt === '0') {
            alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama +
              ", Kec. " + data.results.data_keldes.data_kecamatan.nama +
              ", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
              ", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama;
          } else {
            alamat =
              " RT. " +
              data.results.data_kk.data_rt.nama +
              "/RW. " +
              data.results.data_kk.data_rt.data_rw.nama +
              ", " + JENIS_DUSUN + " " +
              data.results.data_kk.data_rt.data_rw.data_dusun.nama +
              ", " + localStorage.getItem('JENIS_DESA') + " " +
              data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
              ", Kec. " +
              data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
                .data_kecamatan.nama +
              ", " +
              data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
                .data_kecamatan.data_kabkota.nama;
          }
        }

        if (jns === "pelapor") {
          this.setState({
            dt: {
              ...this.state.dt,
              nik: e.value,
              nik_pelapor: e.value,
              kk: data.results.id_kk,
              nama_pemohon: data.results.nama,
              tempat_lahir: data.results.tempat_lahir,
              tanggal_lahir: new Date(
                data.results.tanggal_lahir
              ),
              pekerjaan: data.results.data_pekerjaan.nama,
              pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
              agama: data.results.data_agama.nama,
              status_perkawinan: data.results.status_perkawinan,
              jenis_kelamin: data.results.jk,
              alamat: alamat,
              kewarganegaraan: 'WNI',
            },
            status: {
              ...this.state.status,
              dataPenduduk2: "block",
            },
            tanggal_lahir: data.results.tanggal_lahir,
          })

          // For testing
          if (this.props.selectOnChange2) {
            this.props.selectOnChange2(this.state.dt)
          }
        } else {
          if (jns === "ibu") {
            this.setState({
              dt: {
                ...this.state.dt,
                detail_surat: {
                  ...this.state.dt.detail_surat,
                  nama_ibu: data.results.nama,
                  tempat_lahir_ibu: data.results.tempat_lahir,
                  tanggal_lahir_ibu: new Date(
                    data.results.tanggal_lahir
                  ),
                  pekerjaan_ibu: {
                    value: data.results.data_pekerjaan.nama,
                    label: data.results.data_pekerjaan.nama,
                  },
                  alamat_ibu: alamat,
                },
              },
              status: {
                ...this.state.status,
                dataPenduduk3: "block",
              },
              tanggal_lahir_ibu: data.results.tanggal_lahir,
            })

            // For testing
            if (this.props.selectOnChange3) {
              this.props.selectOnChange3(this.state.dt)
            }
          } else {
            // TANPA PENGECEKAN CATATAN PENDUDUK
            this.setState({
              dt: {
                ...this.state.dt,
                detail_surat: {
                  ...this.state.dt.detail_surat,
                  nama_ayah: data.results.nama,
                  tempat_lahir_ayah: data.results.tempat_lahir,
                  tanggal_lahir_ayah: new Date(
                    data.results.tanggal_lahir
                  ),
                  pekerjaan_ayah: {
                    value: data.results.data_pekerjaan.nama,
                    label: data.results.data_pekerjaan.nama,
                  },
                  alamat_ayah: alamat,
                },
              },
              status: {
                ...this.state.status,
                dataPenduduk: "block",
              },
              tanggal_lahir_ayah: data.results.tanggal_lahir,
            })

            // JIKA ADA PENGECEKAN CATATAN PENDUDUK
            // Get("catatan-penduduk/nik", data.results.id, dpddk => {
            //   let pctt = 0;
            //   let catatan = "";
            //   if (dpddk.results.data) {
            //     dpddk.results.data.forEach(dtp => {
            //       if (dtp.status === 0 && dtp.penalti === 1) {
            //         pctt = 1;
            //         catatan = dtp.catatan;
            //       }
            //     });
            //   }
            //   if (pctt === 0) {
            //     this.setState({
            //       dt: {
            //         ...this.state.dt,
            //         nama_ayah: data.results.nama,
            //         nik_ayah: data.results.id,
            //         tanggal_lahir_ayah: new Date(
            //           data.results.tanggal_lahir
            //         ),
            //         // .toLocaleDateString("id-ID");
            //         tempat_lahir_ayah: data.results.tempat_lahir,
            //         // this.state.dt.pekerjaan_ayah = data.results.data_pekerjaan.nama;
            //         pekerjaan_ayah: {
            //           value: data.results.data_pekerjaan.nama,
            //           label: data.results.data_pekerjaan.nama,
            //         },
            //         alamat_ayah: alamat,
            //       },
            //       status: {
            //         ...this.state.status,
            //         dataPenduduk: "block",
            //       },
            //       tanggal_lahir_ayah: data.results.tanggal_lahir,
            //     })
            //     // this.state.status.btnForm = false;
            //     this.forceUpdate();
            //   } else {
            //     // this.state.status.btnForm = true;
            //     this.setState({
            //       nikStat: null,
            //       dt: {
            //         ...this.state.dt,
            //         // this.state.frmNik = [];
            //         nama_ayah: "",
            //         // this.state.dt.tanggal_lahir_ayah = "";
            //         tanggal_lahir_ayah: null,
            //         tempat_lahir_ayah: "",
            //         pekerjaan_ayah: "",
            //         alamat_ayah: "",
            //       },
            //       status: {
            //         ...this.state.status,
            //         dataPenduduk: "none",
            //       },
            //       show: true,
            //       basicType: "danger",
            //       basicTitle: "Peringatan Catatan Warga",
            //       pesanAlert:
            //         "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
            //         catatan
            //     });
            //     this.forceUpdate();
            //   }

            //   // For testing
            //   if (this.props.selectOnChange) {
            //     this.props.selectOnChange(this.state.dt)
            //   }
            // });
          }
        }
        this.forceUpdate();
      });
    } else {
      if (jns === "pelapor") {
        this.setState({
          dt: {
            ...this.state.dt,
            nama_pemohon: '',
            tempat_lahir: '',
            tanggal_lahir: null,
            pekerjaan: '',
            pendidikan_terakhir: '',
            agama: '',
            status_perkawinan: '',
            jenis_kelamin: '',
            alamat: '',
          },
          status: {
            ...this.state.status,
            dataPenduduk2: "none",
          },
          nik2Stat: null,
        })
      } else {
        if (jns === "ibu") {
          this.setState({
            dt: {
              ...this.state.dt,
              detail_surat: {
                ...this.state.dt.detail_surat,
                nama_ibu: '',
                tempat_lahir_ibu: '',
                tanggal_lahir_ibu: null,
                pekerjaan_ibu: null,
                alamat_ibu: '',
              },
            },
            status: {
              ...this.state.status,
              dataPenduduk3: "none",
            },
            nik3Stat: null,
          })
        } else {
          this.setState({
            dt: {
              ...this.state.dt,
              detail_surat: {
                ...this.state.dt.detail_surat,
                nama_ayah: '',
                tempat_lahir_ayah: '',
                tanggal_lahir_ayah: null,
                pekerjaan_ayah: null,
                alamat_ayah: '',
              },
              // nama_ayah: "",
              // tanggal_lahir_ayah: null,
              // tempat_lahir_ayah: "",
              // pekerjaan_ayah: null,
              // alamat_ayah: "",
            },
            status: {
              ...this.state.status,
              dataPenduduk: "none",
            },
            nikStat: null,
          })
        }
      }

      this.forceUpdate();
    }
  };
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jam":
          this.setState({
            jamStat: e,
            dt: {
              ...this.state.dt,
              jam: e.value,
            },
          });
          break;
        case "pekerjaan":
          this.setState({
            pkrStat: e,
            dt: {
              ...this.state.dt,
              pekerjaan: e.label,
            },
          });
          break;
        case "agama":
          this.setState({
            agmStat: e,
            dt: {
              ...this.state.dt,
              agama: e.label,
            },
          });
          break;
        case "nik":
          this.setState({
            nikStat: e,
            dt: {
              ...this.state.dt,
              nik_ayah: e.value,
            },
          });
          break;
        case "nik2":
          this.setState({
            nik2Stat: e,
            // dt: {
            //   ...this.state.dt,
            //   nik: e.value,
            // },
          });
          break;
        case "nik3":
          this.setState({
            nik3Stat: e,
            dt: {
              ...this.state.dt,
              nik_ibu: e.value,
            },
          });
          break;
        case "shdk":
          this.setState({ shdk: e });

          // For testing
          if (this.props.selectOnChangeShdk) {
            this.props.selectOnChangeShdk(e)
          }
          break;
        case "pekerjaan_ayah":
          this.setState({ dt: { ...this.state.dt, pekerjaan_ayah: e } })
          // For testing
          if (this.props.selectOnChangePekerjaanAyah) {
            this.props.selectOnChangePekerjaanAyah(e)
          }
          break;
        case "pekerjaan_ibu":
          this.setState({ dt: { ...this.state.dt, pekerjaan_ibu: e } })
          // For testing
          if (this.props.selectOnChangePekerjaanIbu) {
            this.props.selectOnChangePekerjaanIbu(e)
          }
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "jam":
          this.setState({
            jamStat: e,
            dt: {
              ...this.state.dt,
              jam: 0,
            },
          });
          break;
        case "pekerjaan":
          this.setState({
            pkrStat: e,
            dt: {
              ...this.state.dt,
              pekerjaan: "",
            },
          });
          break;
        case "agama":
          this.setState({
            agmStat: e,
            dt: {
              ...this.state.dt,
              agama: "",
            },
          });
          break;
        case "nik":
          this.setState({
            nikStat: e,
            dt: {
              ...this.state.dt,
              nik_ayah: 0,
            },
          });
          break;
        case "nik2":
          this.setState({
            nik2Stat: e,
            dt: {
              ...this.state.dt,
              nik_pelapor: 0,
            },
          });
          break;
        case "nik3":
          this.setState({
            nik3Stat: e,
            dt: {
              ...this.state.dt,
              nik_ibu: 0,
            },
          });
          break;
        case "shdk":
          this.setState({ shdk: null });
          break;
        case "pekerjaan_ayah":
          this.setState({ dt: { ...this.state.dt, pekerjaan_ayah: null } })
          break;
        case "pekerjaan_ibu":
          this.setState({ dt: { ...this.state.dt, pekerjaan_ibu: null } })
          break;
        default:
          // no default
          break;
      }
    }

    this.forceUpdate();
  };

  ubahJk = e => {
    this.setState({
      dt: {
        ...this.state.dt,
        jk_anak: document.getElementById("jk_anak").value,
      },
    })
    // For testing
    if (this.props.selectOnChangeJenisKelaminAnak) {
      this.props.selectOnChangeJenisKelaminAnak(e)
    }
    this.forceUpdate();
  };

  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  //change select
  changeAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      if (e.value === "Disetujui") {
        this.setState({
          tableAcc: "",
          btnFormAcc: true,
        })
        if (this.state.accButton.length > 0) {
          this.setState({
            btnFormAcc: false,
          })
        }
      } else {
        this.setState({
          tableAcc: "none",
          btnFormAcc: false,
        })
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      this.setState({
        tableAcc: "none",
        btnFormAcc: true,
      })
    }
    this.setState({
      status: {
        ...this.state.status,
        select: false,
      },
    })
    this.forceUpdate();
  };

  simpanAcc = e => {
    e.preventDefault();
    this.setState({
      btnFormAcc: true,
    })

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),
      uuid: this.state.accuuid
    };
    Post(
      "surat-keterangan/kelahiran/persetujuan/Selesai/" + this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            btnFormAcc: false,
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );

    this.tutupForm();
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.map(x => 1);
    temp[id] = 0;
    this.setState({
      accButton: temp,
      accuuid: uuid,
    })
    if (this.state.accStat) {
      this.setState({
        btnFormAcc: false,
      })
    }
    this.forceUpdate();
  };

  render() {
    var data = this.state.data;

    // const { SearchBar } = Search;
    const columns = [
      {
        dataField: "no",
        text: "No",
        isDummyField: true,
        csvExport: false,
        headerAlign: 'center',
        align: 'center',
        formatter: (key, obj, index) => {
          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: "tanggal_surat",
        headerAlign: 'center',
        align: 'center',
        text: "Tanggal",
        formatter: (cell, obj) => {
          let plainTime = '-'
          if (obj.created_at) {
            let date = new Date(obj.created_at)
            date = date.toLocaleString('id-ID')

            const datetime = date.split(' ')


            date = datetime[0]
            let time = datetime[1]

            const splitDate = date.split('/')
            time = time.replaceAll('.', ':')

            console.log(date)
            const list_bulan = [
              'Januari',
              'Februari',
              'Maret',
              'April',
              'Mei',
              'Juni',
              'Juli',
              'Agustus',
              'September',
              'Oktober',
              'November',
              'Desember'
            ];

            plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}  ${time}`;
          }

          return plainTime
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        headerAlign: 'center',
        align: 'center',
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor === "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "nama_pemohon",
        headerAlign: 'center',
        align: 'center',
        text: "Nama Pemohon",
        sort: true
      },
      {
        dataField: "status",
        headerAlign: 'center',
        align: 'center',
        text: "Status",
        isDummyField: true,
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.forEach(dt => {
            status = dt.status;
          });
          return status;
        },
        sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
        align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    let no = 0;
    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] === 0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] === 0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <div data-testid="page-surat Keterangan Penguburan">
        {this.renderRedirect()}
        {/* <Breadcrumb title="Data Surat Keterangan Penguburan" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  // this.bukaForm();
                  this.tambahData();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                // color="info"
                className="btn btn-secondary-custom"
              // onClick={() => {
              //   this.closeAlert();
              // }}
              >
                <Link
                  data-testid="btn-eksekusi_surat"
                  onClick={() => {
                    // For testing
                    if (this.props.clickEksekusiSurat) {
                      this.props.clickEksekusiSurat()
                    }
                  }}
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Keterangan Penguburan</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        data-testid="tblCreateSuratKelahiran"
                        size="sm"
                        color="success"
                        // className="btn-square"
                        onClick={this.tambahData}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  {this.state.loading ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img
                          alt="loading"
                          src={require("../assets/images/loading2.gif")}
                          style={{
                            borderRadius: "10px",
                            width: '331px'
                          }} />
                      </div>
                    </div>
                  ) : (
                    <>
                      <BootstrapTable keyField="id" data={data} columns={columns} />
                      <div className="pull-right text-white">
                        {this.state.awal}
                        {this.state.sebelum}
                        {this.state.hal.map(dt => {
                          return dt;
                        })}
                        {this.state.setelah}
                        {this.state.akhir}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        {/* Loading preview */}
        <SweetAlert
          show={this.state.loadingPreview}
          showCancel={false}
          showConfirm={false}
        >
          <div align="center">
            <h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
            <br />
            <img
              alt="loading"
              src={require("../assets/images/loading-preview.gif")}
              style={{
                // width: "100%",
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <br /><br />
          </div>
        </SweetAlert>

        <ModalSuratPenguburan
          data={this.state.dt}
          isOpen={this.state.status.form}
          propsData={this.props}
          optionData={{
            frmNik: this.state.frmNik,
            nik2Stat: this.state.nik2Stat,
            status: this.state.status,
          }}
          setAlert={(e) => {
            this.setState(e)
          }}
          setStatusRefresh={(e) => {
            if (e) {
              this.fetch({ page: null, where: [] });
            }
          }}
          setIsOpen={(e) => {
            this.setState({
              status: {
                ...this.state.status,
                form: e
              }
            })
          }}
        />
        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              {/* <input
                className="form-control"
                // id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              /> */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  />
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary-custom">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            {/* <div className="modal-body">
							<table className="table table-bordered table-stripes table-hovered">
								<thead>
									<tr>
										<th>No</th>
										<th>Jabatan</th>
										<th>Level</th>
										<th>TTD</th>
									</tr>
								</thead>
								<tbody>
									{this.state.datDisposisi.map((dt, i) => {
										return (
											<tr key={i}>
												<td>{i + 1}</td>
												<td>{dt.data_jabatan.nama}</td>
												<td>{dt.level}</td>
												<td>
													<button
														type="button"
														className="btn btn-info"
														onClick={() => {
															this.disposisi(dt.uuid, this.state.idSurat);
														}}
														disabled={this.state.status.btnForm}
													>
														Disposisi
                          </button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
						 */}
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.riwayatDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status === 0
                            ? "Menunggu Disposisi"
                            : dt.status === 1
                              ? "Telah di disposisi"
                              : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>

      </div>
    );
  }
}

export default SuratKeteranganPenguburan;