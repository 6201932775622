import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Form, } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// koneksi
import { Get, JENIS_DESA } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "react-datetime/css/react-datetime.css";

import Datetime from "react-datetime";

const dateFormat = require('dateformat');

const frmDef = {
	tgl_awal: null,
	tgl_akhir: null,
};
class RekapAbsensi extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: frmDef,
			judul: 'Jam Kerja',
			// =================================
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',

			isKelurahan: false,
			tgl_awal: null,
			tgl_akhir: null,
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = '';
		// let where = [];
		// if (params.where)
		// {
		//     where = params.where;
		// }
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '?page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '?page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '?page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '?page=1';
					break;
				default:
					link = '?page=' + params.page;
					break;
			}
		}
		let awal = ''
		let akhir =''
		if(this.state.dt.tgl_awal){
			if(link){
				awal = `&start=${this.state.dt.tgl_awal}`
			}
			else{
				awal = `?start=${this.state.dt.tgl_awal}`
			}
		}
		if(this.state.dt.tgl_akhir){
			akhir = `&end=${this.state.dt.tgl_akhir}`
		}

		Get('absensi/absen' + link + awal + akhir, null, (dtkat) => {
			if (dtkat.results) {
				this.setState({ data: dtkat.results.data, pagination: dtkat.results });
				this.pagination(dtkat.results);
			}
		});
	};

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		//console.log(data);
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'primary-custom';
				if (i === data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						href={() => false}
						onClick={() => this.fetch({ page: i })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						href={() => false}
						onClick={() => this.fetch({ page: p })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						href={() => false}
						onClick={() => this.fetch({ page: n })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					href={() => false}
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					href={() => false}
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	// ========================================================================

	tombolAksi = (cell, row) => {
		return (
			<div>
				<Link to={{ pathname: "/admin/rekap-absensi-detail", state: {data:row, tgl_awal: this.state.dt.tgl_awal, tgl_akhir: this.state.dt.tgl_akhir} }}>
					<Button
						size="md"
						color="info"
						className="btn-icon"
						// onClick={() => this.ubahData(row)}
						// disabled={this.state.status.btnAksi}
					>
						<i className="fa fa-eye" /> Detail Riwayat
					</Button>
				</Link>
			</div>
		);
	};

	componentDidMount() {
		this.fetch({ page: null, where: [] });

		if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			this.setState({ isKelurahan: true });
		}
	}

	gantiTgl = (date, fld) => {
		switch (fld) {
			case 'tanggal_awal':
				if (typeof date._isValid !== 'undefined') {
					let tgl  = dateFormat(date, 'yyyy-mm-dd')
					this.setState({ 
						dt:{
							...this.state.dt,
							tgl_awal: tgl,
						},
						tgl_awal: new Date(date.format('YYYY-MM-DD')) 
					});
				}
				break;
			case 'tanggal_akhir':
				if (typeof date._isValid !== 'undefined') {
					let tgl  = dateFormat(date, 'yyyy-mm-dd')
					this.setState({ 
						dt:{
							...this.state.dt,
							tgl_akhir: tgl,
						},
						tgl_akhir: new Date(date.format('YYYY-MM-DD')) 
					});
					// this.setState({ tgl_akhir: new Date(date.format('YYYY-MM-DD')) });
				}

				break;
			default:
				// no default
				break;
		}
	};

	lihatRiwayat() {
		if(this.state.dt.tgl_awal && this.state.dt.tgl_akhir){
			this.fetch({ page: null, where: [] });
		}
		else{
			this.setState({ show: true, basicType:'danger', basicTitle:'Riwayat Absensi', pesanAlert:"Tanggal Awal dan Akhir Tidak Boleh Kosong" });
		}
	}

	render() {
		var data = this.state.data;
		
		const columns = [
			{
				dataField: 'no',
				text: 'No',
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: (key, obj, index) => {
					return index + 1;

					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = index + 1;
					let serial_num = total_records_per_page * (current_pagenum - 1) + row_index;
					return serial_num;
				}
			},
			{
				dataField: 'tanggal',
				text: 'Tanggal',
				sort: true,
				headerAlign: 'center',
				align: 'center',
				formatter: (cell, obj) => {
          			let plainTime = '-'
					if (obj.tanggal) {
						let date = new Date(obj.tanggal)
						date = date.toLocaleString('id-ID')
						
						const datetime = date.split(' ')
	
						date = datetime[0]
						// let time = datetime[1]
	
						const splitDate = date.split('/')
						// time = time.replaceAll('.', ':')
	
						const list_bulan = [
							'Januari',
							'Februari',
							'Maret',
							'April',
							'Mei',
							'Juni',
							'Juli',
							'Agustus',
							'September',
							'Oktober',
							'November',
							'Desember'
						];

						plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}`;
					}

					return plainTime
        		},
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: this.tombolAksi,
				headerStyle: (colum, colIndex) => {
                    return { width: '80px', };
                }
			}
		];

		return (
			<div>
				{/* <Breadcrumb title="Data Jam Kerja" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
					style={{ maxWidth: '478px' }}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-12">
											<Form className="theme-form" onSubmit={(e) => this.simpan(e, 0)}>
											<div className="row">
                      							<div className="col-sm-6">
													<Datetime
														locale="id-ID"
														input={true}								timeFormat={false}
														closeOnTab={true}
														initialValue={this.state.tgl_awal}
														value={this.state.tgl_awal}
														onChange={(e) => {
															this.gantiTgl(e, 'tanggal_awal');
														}}
														inputProps={{
															required: true,
															placeholder: 'Pilih Tanggal Awal'
														}}
													/>
												</div>
                      							<div className="col-sm-6">
													<Datetime
														locale="id-ID"
														input={true}
														timeFormat={false}
														closeOnTab={true}
														initialValue={this.state.tgl_akhir}
														value={this.state.tgl_akhir}
														onChange={(e) => {
															this.gantiTgl(e, 'tanggal_akhir');
														}}
														inputProps={{
															placeholder: 'Pilih Tanggal Akhir'
														}}
													/>
												</div>
											</div>
											</Form>
										</div>
										<br/>
										<br/>
										<div className="col-sm-12 text-right">
											<Button
												size="sm"
												color="success"
												onClick={() => {
													this.lihatRiwayat();
												}}
											>
												Lihat Riwayat
											</Button>
										</div>
									</div>
								</div>
								<div className="card-body datatable-react">
									<BootstrapTable keyField="id" data={data} columns={columns} />
									<div className="pull-right text-white">
										{this.state.awal}
										{this.state.sebelum}
										{this.state.hal.map((dt) => {
											return dt;
										})}
										{this.state.setelah}
										{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		);
	}
}

export default RekapAbsensi;
